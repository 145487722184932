import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';

import { Elements } from '../../components';

import { ReactComponent as ArrowLeft } from '../../assets/images/icons/arrow-left.svg';
import { alert, router } from '../../helpers';
import { connect, ConnectedProps } from 'react-redux';
import { authActions } from '../../actions';
import { RootState } from '../../store';
import {
  ForgotPasswordProps,
  ForgotPasswordStates,
} from '../../@types/pages/Auth';
import AuthBackground from './AuthBackground';
import Modal from '../../components/Modal/Modal';
import { AuthLogo } from '../../components/Auth';
import { Helmet } from 'react-helmet-async';

const validationSchema = Yup.object({
  email: Yup.string()
    .required('Required')
    .email('Invalid e-mail address!'),
});

class ForgotPassword extends React.Component<
  ForgotPasswordProps & ConnectedProps<typeof connector>,
  ForgotPasswordStates
> {
  state: ForgotPasswordStates = {
    email: '',
    emailSent: false,
  };

  modal: React.RefObject<Modal>;

  constructor(
    props: ForgotPasswordProps & ConnectedProps<typeof connector>
  ) {
    super(props);
    this.modal = React.createRef();
  }

  componentDidMount() {
    this.modal.current?.show();
  }

  componentDidUpdate(prevProps: ConnectedProps<typeof connector>) {
    if (
      prevProps.forgotPassword !== this.props.forgotPassword &&
      !this.props.forgotPassword.isLoading
    ) {
      alert.fire({
        message: this.props.forgotPassword.error
          ? (this.props.forgotPassword.data as unknown as any).message
          : 'Reset password link sent!',
        error: this.props.forgotPassword.error,
      });

      if (!this.props.forgotPassword.error)
        this.setState({ emailSent: true });
    }
  }

  onSubmit = (values: { email: string }) => {
    this.setState({ email: values.email });
    this.props.forgotPasswordAction(values);
  };

  render() {
    return (
      <AuthBackground>
        <Helmet>
          <title>Forgot Password | Beepy</title>
        </Helmet>

        <Modal
          closable={false}
          modalBodyClass="w-100"
          ref={this.modal}
        >
          {!this.state.emailSent ? (
            <div className="d-flex flex-column justify-content-center pt-8">
              <div className="d-flex align-items-center justify-content-center">
                <AuthLogo color="dark" />
              </div>
              <div className="d-flex align-items-center justify-content-center flex-column text-center">
                <h3 id="singinTitleh3">Forgot password?</h3>

                <div className="text-bluey-grey py-3 font-light">
                  No worries, we'll send you reset instructions.
                </div>
              </div>
              <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={{ email: '' }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  this.onSubmit(values);
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <>
                    <form
                      onSubmit={handleSubmit}
                      className="d-flex flex-column"
                    >
                      <div className="d-flex flex-column pt-3">
                        <Elements.InputLabel
                          for="email"
                          label="Email"
                        />
                        <Elements.Input
                          value={values.email}
                          onChange={handleChange}
                          type="email"
                          id="email"
                          placeholder="Enter your email"
                          autoComplete="email"
                        />
                        <Elements.FormErrorText
                          error={errors.email}
                        />
                      </div>
                      <div className="mt-3">
                        <Elements.Button
                          loading={
                            this.props.forgotPassword.isLoading
                          }
                          name="Reset password"
                          className="text-white p-2 w-100"
                          type="submit"
                        />
                      </div>
                    </form>
                  </>
                )}
              </Formik>
              <div className="d-flex align-items-center justify-content-center mt-3 text-sm">
                <Link
                  to="/login"
                  className="d-flex flex-row justify-content-center align-items-center"
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <ArrowLeft className="me-1" />
                  <div className="text-sm text--primary">
                    Back to log in
                  </div>
                </Link>
              </div>
            </div>
          ) : (
            <div className="my-auto mx-auto d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center justify-content-center flex-column text-center">
                <div className="d-flex align-items-center justify-content-center">
                  <AuthLogo color="dark" />
                </div>

                <h3 id="singinTitleh3">Check your email</h3>

                <div className="text-bluey-grey py-4 d-flex flex-column align-items-center justify-content-center">
                  We sent a password reset link to:{' '}
                  <span>{this.state.email}</span>
                </div>
                <div className="text-bluey-grey">
                  If you don't receive an email, please check your
                  spam folder.
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center pt-8 pb-8 text-sm">
                <Link
                  to="/login"
                  className="d-flex flex-row justify-content-center align-items-center mt-4"
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <ArrowLeft className="me-1" />
                  <div className="text-sm text--primary">
                    Back to log in
                  </div>
                </Link>
              </div>
            </div>
          )}
        </Modal>
      </AuthBackground>
    );
  }
}

const mapState = (state: RootState) => ({
  forgotPassword: state.auth.forgotPassword,
});

const mapDispatch = {
  forgotPasswordAction: ({ email }: { email: string }) =>
    authActions.forgotPasswordAction({ email }),
};

const connector = connect(mapState, mapDispatch);
export default connector(router.withRouter(ForgotPassword));
