import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';

import { ChannelDetailsTypes } from '../../@types/pages';
import { channelTypes, reportTypes } from '../../@types/reducers';

import { RootState } from '../../store';
import { alert, modal, router } from '../../helpers';
import {
  authActions,
  channelActions,
  reportActions,
} from '../../actions';

import UploadLogo from '../../components/UploadLogo/UploadLogo';
import { Elements } from '../../components';

const validationSchema = Yup.object({
  title: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  category: Yup.string().required('Required'),
});
class ChannelDetails extends React.Component<
  ChannelDetailsTypes.ChannelDetailsProps &
    ConnectedProps<typeof connector>,
  ChannelDetailsTypes.ChannelDetailsStates
> {
  state: ChannelDetailsTypes.ChannelDetailsStates = {
    editProfile: false,
    details: null,
  };

  componentDidMount() {
    this.props.getChannelDetails({ channel: this.props.params.id });
    this.props.getCategories();
  }

  componentDidUpdate(
    prevProps: ChannelDetailsTypes.ChannelDetailsProps &
      ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.details !== this.props.details &&
      !this.props.details.isLoading &&
      !this.props.details.error &&
      this.props.details.success
    ) {
      this.setState({
        details: this.props.details
          .data as channelTypes.IChannelDetails,
      });
    }

    if (
      prevProps.updatedDetails !== this.props.updatedDetails &&
      !this.props.updatedDetails.isLoading
    ) {
      alert.fire({
        message: this.props.updatedDetails.error
          ? (this.props.updatedDetails.data as unknown as any).message
          : 'The channel information has just been updated successfully!',
        error: this.props.updatedDetails.error,
      });

      this.props.getChannelDetails({ channel: this.props.params.id });
    }

    if (
      prevProps.deletedChannel !== this.props.deletedChannel &&
      !this.props.deletedChannel.isLoading
    ) {
      alert.fire({
        message: this.props.deletedChannel.error
          ? (this.props.deletedChannel.data as unknown as any).message
          : 'The channel has just been deleted successfully!',
        error: this.props.deletedChannel.error,
      });

      if (!this.props.deletedChannel.error)
        this.props.navigate('/channels', { replace: true });
    }

    if (
      prevProps.inactivatedChannel !==
        this.props.inactivatedChannel &&
      !this.props.inactivatedChannel.isLoading
    ) {
      alert.fire({
        message: this.props.inactivatedChannel.error
          ? (this.props.inactivatedChannel.data as unknown as any)
              .message
          : 'The channel has just been inactivated successfully!',
        error: this.props.inactivatedChannel.error,
      });

      if (!this.props.inactivatedChannel.error)
        this.props.navigate('/channels', { replace: true });
    }

    if (
      prevProps.uploadedLogo !== this.props.uploadedLogo &&
      !this.props.uploadedLogo.isLoading
    ) {
      alert.fire({
        message: this.props.uploadedLogo.error
          ? (this.props.uploadedLogo.data as unknown as any).message
          : 'The channel logo has just been updated successfully!',
        error: this.props.uploadedLogo.error,
      });

      this.props.getChannelDetails({ channel: this.props.params.id });
    }

    if (
      prevProps.reportState !== this.props.reportState &&
      !this.props.reportState.isLoading
    ) {
      alert.fire({
        message: this.props.reportState.error
          ? (this.props.reportState.data as unknown as any).message
          : 'Your report reached us. We will contact with you!',
        error: this.props.reportState.error,
      });
    }

    if (
      prevProps.channelLeft !== this.props.channelLeft &&
      !this.props.channelLeft.isLoading
    ) {
      alert.fire({
        message: this.props.channelLeft.error
          ? (this.props.channelLeft.data as unknown as any).message
          : 'You have just left the channel!',
        error: this.props.channelLeft.error,
      });

      if (!this.props.channelLeft.error)
        this.props.navigate('/channels', { replace: true });
    }
  }

  showProfile = () => {
    this.setState({
      editProfile: !this.state.editProfile,
    });
  };

  onSubmit = (values: {
    title: string;
    description: string;
    category: string;
  }) => {
    this.props.updateDetails({
      channel: this.props.params.id,
      title: values.title,
      description: values.description,
      category: values.category,
    });
    this.setState({
      editProfile: !this.state.editProfile,
    });
  };

  render() {
    const { details } = this.state;
    if (!details) return null;
    return (
      <>
        <div className="d-flex flex-column py-2">
          <h4>Channel Details</h4>
          <span className="color--beepy-dark-grey">
            {details.isOwner
              ? 'You can make your channel-related edits here.'
              : 'You can view this channel details here.'}
          </span>
        </div>
        <div className="divider" />

        {details.isOwner && (
          <>
            <div className="d-md-flex py-2 align-items-center justify-content-between">
              <div className="flex-column col-6 col-md-3">
                <h6>Channel Logo</h6>
                <span className="color--beepy-dark-grey d-sm-block d-none">
                  You can change channel's logo here.
                </span>
              </div>
              <div className="d-inline-flex col-12 col-md-9 align-items-center">
                {details.logo && (
                  <img
                    src={details.logo}
                    alt="channel logo"
                    className="pr-8"
                    style={{ width: '128px', height: '128px' }}
                  />
                )}
                <UploadLogo
                  buttonActive={true}
                  onSubmit={(file) => {
                    this.props.uploadLogo({
                      channel: this.props.params.id as string,
                      logo: file as File,
                    });
                  }}
                />
              </div>
            </div>
            <div className="divider" />
          </>
        )}

        <div className="d-sm-flex py-2">
          <div className="flex-column col-12 col-md-3 me-auto">
            <h6>Channel Information</h6>
            <span className="color--beepy-dark-grey">
              {details.isOwner
                ? 'You can edit channel information here.'
                : 'Channel information is shown here.'}
            </span>
            <div
              role="button"
              className="text--primary"
              onClick={this.showProfile}
            >
              {details.isOwner &&
                (this.state.editProfile ? 'Cancel' : 'Edit Details')}
            </div>
          </div>
          <div className="col-12 col-md-6">
            {this.state.editProfile && details.isOwner && details ? (
              <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={{
                  title: details.title,
                  description: details.description,
                  category: details.category,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  this.onSubmit(values);
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <>
                    <form
                      onSubmit={handleSubmit}
                      className="flex select-none flex-col"
                    >
                      <div className="flex flex-col pt-5">
                        <Elements.InputLabel
                          for="title"
                          label="Title"
                        />
                        <Elements.Input
                          value={values.title}
                          onChange={handleChange}
                          type="text"
                          id="title"
                          placeholder="e.g. Title"
                        />
                        <Elements.FormErrorText
                          error={errors.title}
                        />
                      </div>

                      <div className="flex flex-col pt-5">
                        <Elements.InputLabel
                          for="description"
                          label="Description"
                        />
                        <Elements.Input
                          value={values.description}
                          onChange={handleChange}
                          type="text"
                          id="description"
                          placeholder="e.g. Description"
                        />
                        <Elements.FormErrorText
                          error={errors.description}
                        />
                      </div>

                      <div className="flex flex-col pt-5">
                        <Elements.InputLabel
                          for="category"
                          label="Category"
                        />
                        <Elements.Select
                          onChange={handleChange}
                          value={values.category}
                          id="category"
                        >
                          {!this.props.categories.isLoading &&
                            !this.props.categories.error &&
                            this.props.categories.success &&
                            this.props.categories.data &&
                            typeof this.props.categories.data ===
                              'object' && (
                              <>
                                {Object.entries(
                                  this.props.categories.data
                                ).map((category, index) => (
                                  <option
                                    key={index}
                                    value={category[0]}
                                  >
                                    {category[0]}
                                  </option>
                                ))}
                              </>
                            )}
                        </Elements.Select>
                        <Elements.FormErrorText
                          error={errors.category}
                        />
                      </div>

                      <div className="d-flex select-none flex-row-reverse py-4">
                        <Elements.Button
                          loading={
                            this.props.updatedDetails.isLoading
                          }
                          className="w-50 text-white"
                          name="Update"
                          type="submit"
                        />
                        {!this.props.updatedDetails.isLoading && (
                          <Elements.Button
                            onClick={this.showProfile}
                            color="bg-transparent color--beepy-dark"
                            className="w-50 border p-2 me-3"
                            name="Cancel"
                            type="button"
                          />
                        )}
                      </div>
                    </form>
                  </>
                )}
              </Formik>
            ) : (
              <div className="text-break">
                <div className="py-3">
                  <h6>Title</h6>
                  <span>{details.title}</span>
                </div>
                <div className="py-3">
                  <h6>Description</h6>
                  <span>{details.description}</span>
                </div>
                <div className="py-3">
                  <h6>Category</h6>
                  <span>{details.category}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="divider" />

        <div
          style={{
            backgroundColor: '#f1f1f1',
            borderRadius: '8px',
            padding: '1.5rem',
          }}
        >
          <h4 className="beepy-title-color">
            {details.isOwner
              ? 'Delete Channel Permanently'
              : 'Leave the channel'}
          </h4>
          <span style={{ color: '#667085' }}>
            {details.isOwner
              ? 'This action will delete channel information, notifications, members, credentials and webhooks permanently. This action cannot be undone.'
              : 'This action will remove you from the channel. You will no longer be able to access the channel.'}
          </span>
          <div className="mt-2">
            <button
              type="button"
              className="btn btn-light me-3"
              style={{ color: '#344054' }}
              onClick={() => {
                modal.confirmModal({
                  actionName: details.isOwner
                    ? 'Delete Channel'
                    : 'Report Channel',
                  color: 'bg-danger',
                  description: `Are you sure you want to ${
                    details.isOwner
                      ? 'delete this channel'
                      : 'report this channel'
                  }?`,
                  title: details.isOwner ? 'Delete' : 'Report',
                  onClick: () => {
                    details.isOwner
                      ? this.props.deleteChannel({
                          channel: this.props.params.id,
                        })
                      : this.props.reportChannel({
                          guid: this.props.params.id as string,
                          type: 'CHANNEL',
                        });
                  },
                });
              }}
            >
              {details.isOwner ? 'Delete Channel' : 'Report'}
            </button>
            <button
              type="button"
              className="btn btn-dark text-white"
              onClick={() => {
                modal.confirmModal({
                  actionName: details.isOwner
                    ? 'Inactivate Channel'
                    : 'Leave Channel',
                  color: 'bg-danger',
                  description: `Are you sure you want to ${
                    details.isOwner
                      ? 'inactivate this channel'
                      : 'leave this channel'
                  }?`,
                  title: details.isOwner ? 'Inactivate' : 'Leave',
                  onClick: () => {
                    details.isOwner
                      ? this.props.inactivateChannel({
                          channel: this.props.params.id,
                        })
                      : this.props.leaveChannel({
                          channel: this.props.params.id,
                        });
                  },
                });
              }}
            >
              {details.isOwner
                ? 'Inactivate Channel'
                : 'Leave Channel'}
            </button>
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  details: state.channel.channelDetails,
  updatedDetails: state.channel.channelDetailsUpdate,
  categories: state.auth.categories,
  deletedChannel: state.channel.channelDelete,
  inactivatedChannel: state.channel.channelInactivate,
  uploadedLogo: state.channel.uploadLogo,
  channelLeft: state.channel.channelLeave,
  reportState: state.report.issue,
});

const mapDispatch = {
  getChannelDetails: ({
    channel,
  }: channelTypes.IChannelDetailsRequest) =>
    channelActions.channelDetailsAction({ channel }),
  updateDetails: ({
    channel,
    title,
    description,
    category,
  }: channelTypes.IChannelDetailsUpdateRequest) =>
    channelActions.channelDetailsUpdateAction({
      channel,
      title,
      description,
      category,
    }),
  getCategories: () => authActions.listCategoriesAction(),
  deleteChannel: ({ channel }: channelTypes.IChannelDeleteRequest) =>
    channelActions.channelDeleteAction({ channel }),
  inactivateChannel: ({
    channel,
  }: channelTypes.IChannelInactivateRequest) =>
    channelActions.channelInactivateAction({ channel }),
  reportChannel: ({ guid, type }: reportTypes.IIssueRequest) =>
    reportActions.issueAction({ guid, type }),
  uploadLogo: ({ channel, logo }: channelTypes.IUploadLogoRequest) =>
    channelActions.uploadLogoAction({ channel, logo }),
  leaveChannel: ({ channel }: channelTypes.IChannelLeaveRequest) =>
    channelActions.channelLeaveAction({ channel }),
};

const connector = connect(mapState, mapDispatch);
export default connector(router.withRouter(ChannelDetails));
