import React from 'react';

import { FormErrorTextProps } from '../../@types/components/Elements/FormErrorText';
export default class FormErrorText extends React.Component<FormErrorTextProps> {
  render() {
    return (
      <>
        <span className="text-danger">{this.props.error}</span>
      </>
    );
  }
}
