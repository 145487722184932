import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { ChannelNotificationsTypes } from '../../@types/pages';
import { notificationTypes } from '../../@types/reducers';

import { router } from '../../helpers';
import { RootState } from '../../store';
import { notificationActions } from '../../actions';

class ChannelNotifications extends React.Component<
  ChannelNotificationsTypes.ChannelNotificationsProps &
    ConnectedProps<typeof connector>,
  ChannelNotificationsTypes.ChannelNotificationsStates
> {
  render() {
    return 'a';
  }
}

const mapState = (state: RootState) => ({
  notifications: state.notification.notificationList,
  deleteNotificationState: state.notification.deleteNotification,
});
const mapDispatch = {
  getNotifications: ({
    channel,
    page,
  }: notificationTypes.INotificationRequest) =>
    notificationActions.notificationListAction({ channel, page }),
  deleteNotification: ({
    guid,
  }: notificationTypes.IDeleteNotificationRequest) =>
    notificationActions.deleteNotificationAction({ guid }),
};
const connector = connect(mapState, mapDispatch);
export default connector(router.withParams(ChannelNotifications));
