import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { ChannelInvitationsTypes } from '../../@types/pages';
import { channelTypes } from '../../@types/reducers';

import { channelActions } from '../../actions';

import { alert, modal, router } from '../../helpers';
import { RootState } from '../../store';

import { ReactComponent as AlertTriangle } from '../../assets/images/icons/alert-triangle.svg';
import { ReactComponent as CheckCircle } from '../../assets/images/icons/check-circle.svg';
import { ReactComponent as MoreHorizontal } from '../../assets/images/icons/more-horizontal.svg';
import { ReactComponent as Clock } from '../../assets/images/icons/clock.svg';

import { ReactComponent as Mail } from '../../assets/images/icons/mail.svg';
import { ReactComponent as UserPlus } from '../../assets/images/icons/user-plus.svg';
import InviteUser from '../../components/Modal/InviteUser';

class ChannelInvitations extends React.Component<
  ChannelInvitationsTypes.ChannelInvitationsProps &
    ConnectedProps<typeof connector>,
  ChannelInvitationsTypes.ChannelInvitationsStates
> {
  menuRef = React.createRef<HTMLUListElement>();
  modal = React.createRef<InviteUser>();

  state: ChannelInvitationsTypes.ChannelInvitationsStates = {
    selectedInvitation: null,
    invitations: null,
    limits: null,
  };

  constructor(
    props: ChannelInvitationsTypes.ChannelInvitationsProps &
      ConnectedProps<typeof connector>
  ) {
    super(props);
    this.modal = React.createRef<InviteUser>();
  }

  setSelectedInvitations = (invitation: string) => {
    if (this.state.selectedInvitation === invitation) {
      this.setState({ selectedInvitation: null });
    } else {
      this.setState({ selectedInvitation: invitation });
    }
  };

  resendInvitation = (guid: string) => {
    this.props.resendInvitation({ guid });
  };

  toggleAction = (action: string) => {
    this.setState({
      dropdownAction: action,
    });
  };

  handleOutsideClick = (e: any) => {
    if (this.state.dropdownAction) {
      if (this.menuRef && !this.menuRef.current?.contains(e.target)) {
        this.setState({
          dropdownAction: null,
        });
      }
    }
  };

  componentDidMount() {
    this.props.getInvitations({ channel: this.props.params.id });
    this.props.getLimits({ channel: this.props.params.id });

    document.addEventListener('click', this.handleOutsideClick);
  }

  componentDidUpdate(
    prevProps: ChannelInvitationsTypes.ChannelInvitationsProps &
      ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.invitations !== this.props.invitations &&
      !this.props.invitations.isLoading &&
      !this.props.invitations.error &&
      this.props.invitations.success
    ) {
      this.setState({
        invitations: this.props.invitations
          .data as channelTypes.IChannelInvitationList,
      });
    }

    if (
      prevProps.resentInvitaiton !== this.props.resentInvitaiton &&
      !this.props.resentInvitaiton.isLoading
    ) {
      alert.fire({
        message: this.props.resentInvitaiton.error
          ? (this.props.resentInvitaiton.data as unknown as any)
              .message
          : 'The invitation has just been resent successfully',
        error: this.props.resentInvitaiton.error,
      });
    }

    if (
      prevProps.cancelledInvitation !==
        this.props.cancelledInvitation &&
      !this.props.cancelledInvitation.isLoading
    ) {
      alert.fire({
        message: this.props.cancelledInvitation.error
          ? (this.props.cancelledInvitation.data as unknown as any)
              .message
          : 'The invitation has just been cancelled successfully',
        error: this.props.cancelledInvitation.error,
      });

      this.props.getInvitations({ channel: this.props.params.id });
    }

    if (
      prevProps.invitedMember !== this.props.invitedMember &&
      !this.props.invitedMember.isLoading
    ) {
      this.props.getInvitations({ channel: this.props.params.id });
    }

    if (
      prevProps.limits !== this.props.limits &&
      !this.props.limits.isLoading &&
      !this.props.limits.error &&
      this.props.limits.success
    ) {
      this.setState({
        limits: this.props.limits.data as channelTypes.IChannelLimits,
      });
    }
  }

  render() {
    const { invitations } = this.state;

    if (!invitations) return null;

    return (
      <>
        <InviteUser
          title="Invite Member"
          description="Add a new member to this channel"
          ref={this.modal}
          onSubmit={(email) =>
            this.props.addMember({
              channel: this.props.params.id as string,
              email,
            })
          }
          membersCount={this.state.limits?.members.members_count}
          membersLimit={this.state.limits?.members.max_limit}
        />
        {!this.props.invitations.isLoading &&
        invitations &&
        invitations.list.length > 0 ? (
          <ul
            ref={this.menuRef}
            className="invitations-list striped-list"
          >
            <li className="d-sm-flex d-none">
              <div className="col-3 col-md-5">
                Invitations Members Name
              </div>
              <div className="col-3 col-md-3">Invitations Status</div>
              <div className="col-2"></div>
            </li>
            {invitations.list.map((invite, key) => (
              <li className="d-sm-flex d-block row" key={key}>
                <div className="col-4 col-md-5 d-flex">
                  <div className="d-grid details">
                    <span className="title">{invite.email}</span>
                    <span className="subtitle">{invite.date}</span>
                  </div>
                </div>
                <div className="col-3 col-md-3 d-flex align-items-center">
                  <span className="badge rounded-pill bg--beepy-grey text-dark d-inline-flex">
                    <span className="me-1">
                      <Clock />
                    </span>
                    <span>{invite.status}</span>
                  </span>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-end actions">
                  <a
                    className="actions-buttons d-flex align-items-center"
                    href="#1"
                    onClick={() => this.toggleAction(invite.guid)}
                  >
                    <MoreHorizontal />
                  </a>
                  <ul
                    className={`dropdown-menu text-small shadow my-2 me-5 ${
                      this.state.dropdownAction === invite.guid &&
                      'show'
                    }`}
                    style={{ width: '40px' }}
                    aria-labelledby="invitations"
                  >
                    <li>
                      <button
                        className="dropdown-item d-flex align-items-center"
                        onClick={() =>
                          modal.confirmModal({
                            actionName: 'Cancel',
                            description:
                              'Are you sure you want to cancel this invitation?',
                            onClick: () => {
                              this.props.cancelInvitation({
                                guid: invite.guid,
                              });
                            },
                            color: 'bg-danger',
                            title: 'Cancel Invitation',
                          })
                        }
                        style={{ padding: '.5rem' }}
                      >
                        <span className="me-2">
                          <AlertTriangle />
                        </span>
                        Cancel
                      </button>
                      <button
                        className="dropdown-item d-flex align-items-center"
                        onClick={() =>
                          modal.confirmModal({
                            actionName: 'Resend',
                            description:
                              'Are you sure you want to resend this invitation?',
                            onClick: () => {
                              this.props.resendInvitation({
                                guid: invite.guid,
                              });
                            },
                            color: 'bg-primary',
                            title: 'Resend Invitation',
                          })
                        }
                        style={{ padding: '.5rem' }}
                      >
                        <span className="me-2">
                          <CheckCircle />
                        </span>
                        Resend
                      </button>
                    </li>
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div
                className="border rounded-circle p-2 mb-3"
                style={{
                  backgroundColor: '#eaecf0',
                }}
              >
                <div className="border rounded-circle p-2">
                  <Mail />
                </div>
              </div>

              <p className="beepy-title-color fw-semibold fs-4">
                Pending Invitations
              </p>
              <p className="color--beepy-dark-grey w-50 text-center">
                There is no any pending invitations here. To invite
                someone to your channel please use the form in Members
                section.
              </p>
              <button
                onClick={() => this.modal.current?.show()}
                className="btn btn-md rounded-3 border px-5 py-2 color--beepy-dark-grey"
              >
                <UserPlus className="me-1 mb-1" />
                Add member
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  invitations: state.channel.channelInvitationList,
  resentInvitaiton: state.channel.channelInvitationResend,
  cancelledInvitation: state.channel.channelInvitationCancel,
  invitedMember: state.channel.channelInvitaitonSend,
  limits: state.channel.channelLimits,
});

const mapDispatch = {
  getInvitations: ({
    channel,
  }: channelTypes.IChannelInvitationListRequest) =>
    channelActions.channelInvitationListAction({ channel }),
  resendInvitation: ({
    guid,
  }: channelTypes.IInvitationResendRequest) =>
    channelActions.channelInvitationResenAction({ guid }),
  cancelInvitation: ({
    guid,
  }: channelTypes.IInvitationCancelRequest) =>
    channelActions.channelInvitationCancelAction({ guid }),
  addMember: ({
    channel,
    email,
  }: channelTypes.IInvitationSendRequest) =>
    channelActions.channelInvitationSendAction({ channel, email }),
  getLimits: ({ channel }: channelTypes.IChannelLimitsRequest) =>
    channelActions.channelLimitsAction({ channel }),
};

const connector = connect(mapState, mapDispatch);
export default connector(router.withParams(ChannelInvitations));
