import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Outlet } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { accountTypes } from '../../@types/reducers';
import { ProfileTypes } from '../../@types/pages';

import { modal, router } from '../../helpers';
import { accountActions, authActions } from '../../actions';
import { RootState } from '../../store';

import { ReactComponent as Logout } from '../../assets/images/icons/log-out.svg';

import { ReactComponent as User } from '../../assets/images/icons/user.svg';
import { ReactComponent as Smartphone } from '../../assets/images/icons/smartphone.svg';
import { ReactComponent as MessageCircle } from '../../assets/images/icons/message-circle.svg';
import { ReactComponent as CreditCard } from '../../assets/images/icons/credit-card.svg';

class Profile extends React.Component<
  ProfileTypes.ProfileProps & ConnectedProps<typeof connector>,
  ProfileTypes.ProfileStates
> {
  state: ProfileTypes.ProfileStates = {
    user: null,
  };

  links = [
    { to: '/profile', text: 'Profile', icon: <User /> },
    { to: '/profile/devices', text: 'Devices', icon: <Smartphone /> },
    {
      to: '/profile/feedback',
      text: 'Feedback',
      icon: <MessageCircle />,
    },
    {
      to: '/profile/subscription',
      text: 'Subscription',
      icon: <CreditCard />,
    },
  ];

  componentDidMount() {
    this.props.getUserProfile();
  }

  componentDidUpdate(
    prevProps: ProfileTypes.ProfileProps &
      ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.user !== this.props.user &&
      !this.props.user.isLoading &&
      !this.props.user.error &&
      this.props.user.success
    ) {
      this.setState({
        user: this.props.user.data as accountTypes.IUserProfile,
      });
    }
  }

  render() {
    const { user } = this.state;

    if (!user) return null;

    return (
      <>
        <Helmet>
          <title>Profile | Beepy</title>
        </Helmet>
        <div className="content w-100">
          <div className="channel-header">
            <div className="breadcrumb d-flex align-items-center">
              <div className="breadcrumb-item">Settings</div>
              <div className="breadcrumb-divider" />
              <div className="breadcrumb-item active">
                User Settings
              </div>
            </div>
            <div className="row">
              <div className="col-8 col-lg-7 d-flex">
                <div className="channel-logo d-flex align-items-center justify-content-center">
                  <div className="initals">
                    {user.name[0].toUpperCase()}
                    {user.surname[0].toUpperCase()}
                  </div>
                </div>

                <div className="d-flex flex-column">
                  <h4 className="d-inline-flex profile-username">
                    {user.name} {user.surname}
                    <h5>
                      <span
                        className="badge rounded-pill bg--beepy-light-green color--beepy-dark-green"
                        style={{
                          marginLeft: '0.5rem',
                          marginTop: '0.25rem',
                        }}
                      >
                        {user.subscription[0] +
                          user.subscription.slice(1).toLowerCase()}
                      </span>
                    </h5>
                  </h4>
                  <span className="color--beepy-dark-grey">
                    {user.email}
                  </span>
                </div>
              </div>
              <div className="col-4 col-lg-5 d-flex align-items-center justify-content-end">
                <div
                  onClick={() => {
                    modal.confirmModal({
                      title: 'Logout',
                      description: 'Are you sure you want to logout?',
                      actionName: 'Logout',
                      onClick: () => {
                        this.props.logout();
                      },
                      color: 'bg-danger',
                    });
                  }}
                  className="btn header-button"
                >
                  <Logout /> Logout
                </div>
              </div>
            </div>
            <ul className="channel-settings-menu">
              {this.links.map((link, index) => (
                <li
                  className={`${
                    this.props.location.pathname === link.to &&
                    'active'
                  }`}
                  key={index}
                >
                  <Link to={link.to} key={index}>
                    {/*link.icon*/}
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div
            className="profile-body"
            //style={{ overflow: 'hidden' }}
          >
            <Outlet />
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  user: state.account.userProfile,
  loggedOut: state.auth.logout,
});

const mapDispatch = {
  getUserProfile: () => accountActions.userProfileAction(),
  logout: () => authActions.logoutAction(),
};

const connector = connect(mapState, mapDispatch);
export default connector(router.withRouter(Profile));
