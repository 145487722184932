import { ReactComponent as LogoDark } from '../../assets/images/logo-dark.svg';
import { ReactComponent as UserPlus } from '../../assets/images/icons/user-plus.svg';
import { ReactComponent as Plus } from '../../assets/images/icons/plus.svg';
import { ReactComponent as Bell } from '../../assets/images/icons/bell.svg';
import { ReactComponent as MoreVertical } from '../../assets/images/icons/more-vertical.svg';

import { Link } from 'react-router-dom';
import { alert, modal, router } from '../../helpers';
import { connect, ConnectedProps } from 'react-redux';
import {
  accountActions,
  authActions,
  channelActions,
} from '../../actions';
import { RootState } from '../../store';
import {
  SidebarProps,
  SidebarStates,
} from '../../@types/components/Panel/Sidebar';
import { accountTypes, channelTypes } from '../../@types/reducers';
import { ChannelsStatus } from '../../@types/pages/Channels';
import React from 'react';

class Sidebar extends React.Component<
  SidebarProps & ConnectedProps<typeof connector>,
  SidebarStates
> {
  menuRef = React.createRef<HTMLAnchorElement>();

  state: SidebarStates = {
    user: null,
    channels: null,
    focusedChannel: null,
    isMenuOpen: false,
  };

  componentDidMount() {
    this.props.getUserProfile();
    this.props.getChannels();

    document.addEventListener('click', this.handleOutsideClick);
  }

  componentDidUpdate(
    prevProps: SidebarProps & ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.user !== this.props.user &&
      !this.props.user.isLoading &&
      !this.props.user.error &&
      this.props.user.success
    ) {
      this.setState({
        user: this.props.user.data as accountTypes.IUserProfile,
      });
    }

    const channelPage =
      this.props.location.pathname.includes('/channels/') &&
      !this.props.location.pathname.includes('/channels/create');

    if (channelPage && !this.state.focusedChannel) {
      const channelId = this.props.location.pathname
        .split('/channels/')[1]
        .split('/')[0];

      this.setState({
        focusedChannel: channelId,
      });
    } else if (!channelPage && this.state.focusedChannel)
      this.setState({
        focusedChannel: null,
      });

    if (
      prevProps.channels !== this.props.channels &&
      !this.props.channels.isLoading &&
      !this.props.channels.error &&
      this.props.channels.success
    ) {
      this.setState({
        channels: this.props.channels
          .data as channelTypes.IChannelList,
      });
    }

    if (
      prevProps.loggedOut !== this.props.loggedOut &&
      !this.props.loggedOut.isLoading
    ) {
      alert.fire({
        message: this.props.loggedOut.error
          ? (this.props.loggedOut.data as unknown as any).message
          : 'Logged out successfully!',
        error: this.props.loggedOut.error,
      });

      if (!this.props.loggedOut.error) this.props.navigate('/login');
    }

    if (
      (prevProps.createdChannel !== this.props.createdChannel &&
        !this.props.createdChannel.isLoading) ||
      (prevProps.uploadedLogo !== this.props.uploadedLogo &&
        !this.props.uploadedLogo.isLoading)
    ) {
      this.props.getChannels();
    }

    if (
      prevProps.activatedChannel !== this.props.activatedChannel &&
      !this.props.activatedChannel.isLoading
    ) {
      alert.fire({
        message: this.props.activatedChannel.error
          ? (this.props.activatedChannel.data as unknown as any)
              .message
          : 'Channel activated successfully!',
        error: this.props.activatedChannel.error,
      });

      if (this.props.activatedChannel.success) {
        this.props.getChannels();

        this.props.navigate(`/channels/${this.state.focusedChannel}`);
      } else this.setState({ focusedChannel: null });
    }

    if (
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      // @ts-ignore
      document
        .querySelector("button[class='btn-close ms-auto d-lg-none']")
        // @ts-ignore
        .click();
    }
  }

  toggleMenu = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    });
  };

  handleOutsideClick = (e: any) => {
    if (this.state.isMenuOpen) {
      if (this.menuRef && !this.menuRef.current?.contains(e.target)) {
        this.setState({
          isMenuOpen: false,
        });
      }
    }
  };

  render() {
    const { user } = this.state;

    if (!user) {
      return null;
    }

    return (
      <div
        className="sidebar d-flex flex-column flex-shrink-0 offcanvas-lg offcanvas-start"
        id="bdSidebar"
        aria-labelledby="bdSidebarLabel"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="offcanvas-body">
          <div className="top d-flex align-items-center">
            <Link
              to="/"
              className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-decoration-none"
            >
              <LogoDark />
            </Link>
            <div className="sidebar-buttons">
              <Link
                to="invitations"
                className="icon position-relative"
              >
                <UserPlus />
                <span className="position-absolute top-1 start-100 translate-middle badge beepy-light">
                  {user.invitations}
                </span>
              </Link>
              <Link to="messages" className="icon position-relative">
                <Bell />
                <span className="position-absolute top-1 start-100 translate-middle badge beepy-light">
                  {user.messages}
                </span>
              </Link>
            </div>
            <button
              type="button"
              className="btn-close ms-auto d-lg-none"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              data-bs-target="#bdSidebar"
            ></button>
          </div>

          <div className="sidebar-element">
            <Link
              to="channels/create"
              className="create-channel-link"
            >
              <span className="icon">
                <Plus />
              </span>
              <span className="text">Create Channel</span>
            </Link>
          </div>
          <div className="divider"></div>

          <ul className="channels-list nav nav-pills d-grid mb-auto">
            {this.state.channels &&
              this.state.channels.list.map((channel, key) => (
                <li
                  className={`channel-item ${
                    this.state.focusedChannel === channel.guid &&
                    'active'
                  } ${
                    channel.status === ChannelsStatus.INACTIVE &&
                    'inactive'
                  }`}
                  key={key}
                >
                  {channel.status === ChannelsStatus.ACTIVE ? (
                    <Link
                      to={`channels/${channel.guid}`}
                      className="nav-link"
                      onClick={() => {
                        this.setState({
                          focusedChannel: channel.guid,
                        });
                      }}
                    >
                      <div className="d-flex ">
                        <div className="logo">
                          <img
                            src={channel.logo}
                            alt=""
                            style={{
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        </div>
                        <div className="title">
                          {channel.title}
                          <span className="subtitle">
                            @{channel.slug}
                          </span>
                        </div>
                      </div>
                      <div className="badge beepy-light">
                        {channel.unread_count}
                      </div>
                    </Link>
                  ) : (
                    channel.status === ChannelsStatus.INACTIVE && (
                      <a
                        href="#1"
                        className="nav-link"
                        onClick={() => {
                          modal.confirmModal({
                            title: 'Activate Channel',
                            description:
                              'This channel is inactive. Do you want to activate it?',
                            actionName: 'Activate',
                            color: 'primary',
                            onClick: () => {
                              this.setState({
                                focusedChannel: channel.guid,
                              });

                              this.props.activateChannel(
                                channel.guid
                              );
                            },
                          });
                        }}
                      >
                        <div className="d-flex ">
                          <div className="logo">
                            <img
                              src={channel.logo}
                              alt=""
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                            />
                          </div>
                          <div className="title">
                            {channel.title}
                            <span className="subtitle">
                              @{channel.slug}
                            </span>
                          </div>
                        </div>
                      </a>
                    )
                  )}
                  {this.state.channels?.list.length !== key + 1 && (
                    <div className="divider"></div>
                  )}
                </li>
              ))}
          </ul>

          <div className="divider"></div>
          <div className="profile dropdown">
            <a
              ref={this.menuRef}
              href="#1"
              className="d-flex align-items-center justify-content-between text-decoration-none dropdown-toggle"
              onClick={this.toggleMenu}
            >
              <div className="profile-info d-flex">
                <div className="initals">
                  {user.name[0].toUpperCase()}
                  {user.surname[0].toUpperCase()}
                </div>
                <div className="details d-grid">
                  <span className="name">
                    {user.name} {user.surname}
                  </span>
                  <span className="email">{user.email}</span>
                </div>
              </div>
              <span className="icon">
                <MoreVertical />
              </span>
            </a>

            <ul
              className={`dropdown-menu ${
                this.state.isMenuOpen && 'show'
              } text-small shadow`}
              style={{ bottom: '100%' }}
            >
              <li>
                <Link className="dropdown-item" to="profile">
                  Profile
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="profile/subscription"
                >
                  Subscriptions
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="profile/devices">
                  Devices
                </Link>
              </li>
              <li>
                <div className="divider p-0 m-0"></div>
              </li>
              <li>
                <a
                  href="#1"
                  className="dropdown-item"
                  onClick={() => {
                    modal.confirmModal({
                      title: 'Logout',
                      description: 'Are you sure you want to logout?',
                      actionName: 'Logout',
                      onClick: () => {
                        this.props.logout();
                      },
                      color: 'bg-danger',
                    });
                  }}
                >
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: RootState) => ({
  user: state.account.userProfile,
  channels: state.channel.channelList,
  loggedOut: state.auth.logout,
  createdChannel: state.channel.createChannel,
  uploadedLogo: state.channel.uploadLogo,
  activatedChannel: state.channel.channelActivate,
});

const mapDispatch = {
  getUserProfile: () => accountActions.userProfileAction(),
  getChannels: () =>
    channelActions.channelListAction(ChannelsStatus.ALL),
  logout: () => authActions.logoutAction(),
  activateChannel: (guid: string) =>
    channelActions.channelActivateAction({ channel: guid }),
};

const connector = connect(mapState, mapDispatch);
export default connector(router.withRouter(Sidebar));
