import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { ChannelsListTypes } from '../../@types/pages';
import { channelTypes } from '../../@types/reducers';

import { RootState } from '../../store';
import { channelActions } from '../../actions';

import { alert, router } from '../../helpers';

import ConnectImg from '../../assets/images/connect.png';
import { ChannelsStatus } from '../../@types/pages/Channels';

class Channels extends React.Component<
  ChannelsListTypes.ChannelsProps & ConnectedProps<typeof connector>,
  ChannelsListTypes.ChannelsStates
> {
  state: ChannelsListTypes.ChannelsStates = {
    channels: null,
    focusedChannel: null,
  };

  componentDidMount() {
    this.props.getChannelList();
  }

  componentDidUpdate(
    prevProps: ChannelsListTypes.ChannelsProps &
      ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.deletedChannel !== this.props.deletedChannel &&
      !this.props.deletedChannel.isLoading
    ) {
      alert.fire({
        message: this.props.deletedChannel.error
          ? (this.props.deletedChannel.data as unknown as any).message
          : 'The channel has just been deleted successfully!',
        error: this.props.deletedChannel.error,
      });

      this.setState({
        channels: null,
      });

      this.props.getChannelList();
      if (
        !this.props.deletedChannel.error &&
        this.props.deletedChannel.success &&
        this.state.channels?.list &&
        this.state.channels?.list.length - 1 === 0
      )
        this.props.navigate('/channels', { replace: true });
    }

    if (
      prevProps.channelList !== this.props.channelList &&
      !this.props.channelList.isLoading &&
      !this.props.channelList.error &&
      this.props.channelList.success
    ) {
      this.setState({
        channels: this.props.channelList
          .data as channelTypes.IChannelList,
      });
    }
  }

  setFocus = (index: number) => {
    this.setState({
      focusedChannel:
        this.state.focusedChannel === index ? null : index,
    });
  };

  render() {
    const { channels } = this.state;
    if (!channels) return null;

    const ownChannels = channels.list.filter(
      (channel: channelTypes.Channel) =>
        channel.isOwner === true &&
        channel.status === ChannelsStatus.ACTIVE
    );

    return (
      <div className="content w-100">
        <div className="h-100 d-flex justify-content-center align-items-center">
          <div className="text-center">
            <img src={ConnectImg} alt=""></img>
            <h4 className="title">Waiting for connection</h4>
            <h6 className="subtitle">
              Supercharge your channel and connect the tools you use
              every day.
            </h6>
            <br />
            {ownChannels.length === 0 ? (
              <Link to="/channels/create" className="btn beepy-lg">
                Create Your First Channel
              </Link>
            ) : (
              <Navigate
                to={`/channels/${ownChannels[0].guid}`}
                replace
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: RootState) => ({
  channelList: state.channel.channelList,
  deletedChannel: state.channel.channelDelete,
});

const mapDispatch = {
  getChannelList: () =>
    channelActions.channelListAction(
      ChannelsListTypes.ChannelsStatus.ALL
    ),
};

const connector = connect(mapState, mapDispatch);

export default connector(router.withRouter(Channels));
