import React from 'react';
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
  Outlet,
} from 'react-router-dom';

import Page from './components/Panel/Page';
import { auth } from './helpers';
import { ProfileMore } from './pages';

import {
  ChannelsList as ChannelsListNew,
  Invitations,
  Channel as ChannelNew,
  ChannelNotifications as ChannelNotificationsNew,
  ChannelMembers as ChannelMembersNew,
  ChannelInvitations as ChannelInvitationsNew,
  ChannelDetails as ChannelDetailsNew,
  ChannelSettings as ChannelSettingsNew,
  ChannelCredentials as ChannelCredentialsNew,
  ChannelWebhooks as ChannelWebhooksNew,
  Profile as ProfileNew,
  ProfileInformation as ProfileInformationNew,
  ProfileDevices as ProfileDevicesNew,
  ProfileFeedback as ProfileFeedbackNew,
  ProfileSubscription as ProfileSubscriptionNew,
  Login as LoginNew,
} from './pages/new';

import CreateChannel from './pages/Channels/CreateChannelNew';
import RegisterNew from './pages/Auth/RegisterNew';
import VerificationNew from './pages/Auth/VerificationNew';
import ForgotPasswordNew from './pages/Auth/ForgotPasswordNew';
import NewPasswordNew from './pages/Auth/NewPasswordNew';
import Messages from './pages/Messages/Messages';

class PublicRoute extends React.Component {
  render() {
    return auth.isLoggedIn() ? (
      <Navigate to="/channels" replace />
    ) : (
      <Outlet />
    );
  }
}
class PrivateRoute extends React.Component {
  render() {
    return auth.isLoggedIn() ? (
      this.props.children
    ) : (
      <Navigate to="/login" replace />
    );
  }
}

class Router extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route index element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<LoginNew />} />
            <Route
              path="/forgot-password"
              element={<ForgotPasswordNew />}
            />
            <Route
              path="/password/reset"
              element={<NewPasswordNew />}
            />
            {/*  */}
            <Route path="/register" element={<RegisterNew />} />
            <Route
              path="/verification"
              element={<VerificationNew />}
            />
          </Route>
          {/*  */}
          <Route
            element={
              <PrivateRoute>
                <Page />
              </PrivateRoute>
            }
          >
            <Route
              index
              element={<Navigate to="/channels" replace />}
            />
            <Route path="/profile" element={<ProfileNew />}>
              <Route index element={<ProfileInformationNew />} />
              <Route path="devices" element={<ProfileDevicesNew />} />
              <Route
                path="feedback"
                element={<ProfileFeedbackNew />}
              />
              <Route
                path="subscription"
                element={<ProfileSubscriptionNew />}
              />
              <Route path="more" element={<ProfileMore />} />
            </Route>
            <Route path="/channels/:id" element={<ChannelNew />}>
              <Route
                index
                element={<Navigate to="notifications" replace />}
              />
              <Route
                path="notifications"
                element={<ChannelNotificationsNew />}
              />
              <Route path="members" element={<ChannelMembersNew />} />
              <Route
                path="invitations"
                element={<ChannelInvitationsNew />}
              />
              <Route path="details" element={<ChannelDetailsNew />} />
              <Route
                path="settings"
                element={<ChannelSettingsNew />}
              />
              <Route
                path="credentials"
                element={<ChannelCredentialsNew />}
              />
              <Route
                path="webhooks"
                element={<ChannelWebhooksNew />}
              />
            </Route>
            <Route path="channels">
              <Route index element={<ChannelsListNew />} />
              <Route path="create" element={<CreateChannel />} />
            </Route>
            <Route path="invitations" element={<Invitations />} />
            <Route path="messages">
              <Route index element={<Messages />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default Router;
