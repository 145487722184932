import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';

import { ReactComponent as ArrowLeft } from '../../assets/images/icons/arrow-left.svg';
import { Elements } from '../../components';
import { alert, router } from '../../helpers';

import {
  NewPasswordProps,
  NewPasswordStates,
} from '../../@types/pages/Auth';
import { connect, ConnectedProps } from 'react-redux';
import { authActions } from '../../actions';
import { RootState } from '../../store';
import { authTypes } from '../../@types/reducers';
import PasswordSuccess from './PasswordSuccess';
import Modal from '../../components/Modal/Modal';
import AuthBackground from './AuthBackground';
import { AuthLogo } from '../../components/Auth';
import { Helmet } from 'react-helmet-async';

const validationSchema = Yup.object({
  newPassword: Yup.string()
    .required('Required')
    .min(8, 'You must enter a minimum of 8 characters.'),
  confirmPassword: Yup.string()
    .required('Required')
    .min(8, 'You must enter a minimum of 8 characters.')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

class NewPassword extends React.Component<
  NewPasswordProps & ConnectedProps<typeof connector>,
  NewPasswordStates
> {
  state: NewPasswordStates = {
    status: false,
    email: '',
    password: '',
  };

  modal: React.RefObject<Modal>;

  constructor(
    props: NewPasswordProps & ConnectedProps<typeof connector>
  ) {
    super(props);
    this.modal = React.createRef();
  }

  componentDidMount() {
    this.modal.current?.show();
  }

  componentDidUpdate(
    prevProps: NewPasswordProps & ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.newPassword !== this.props.newPassword &&
      !this.props.newPassword.isLoading
    ) {
      alert.fire({
        message: this.props.newPassword.error
          ? (this.props.newPassword.data as unknown as any).message
          : 'Password changed!',
        error: this.props.newPassword.error,
      });

      if (!this.props.newPassword.error)
        this.setState({ status: true });
    }
  }

  onSubmit = (values: {
    newPassword: string;
    confirmPassword: string;
  }) => {
    const query = new URLSearchParams(this.props.location.search);
    const accessToken = query.get('access');
    const email = query.get('email');

    this.setState({
      email: email as string,
      password: values.newPassword,
    });
    this.props.resetPassword({
      password: values.newPassword,
      password_confirmation: values.confirmPassword,
      access: accessToken as string,
      email: email as string,
    });
  };

  render() {
    return (
      <AuthBackground>
        <Helmet>
          <title>New Password | Beepy</title>
        </Helmet>
        <Modal
          closable={false}
          ref={this.modal}
          modalBodyClass="w-100"
        >
          {!this.state.status ? (
            <div className="my-auto mx-auto d-flex flex-column justify-content-center pt-8">
              <div className="d-flex align-items-center justify-content-center flex-column text-center">
                <div className="mt-8 d-flex align-items-center justify-content-center">
                  <AuthLogo color="dark" />
                </div>
                <h3 id="singinTitleh3">Password reset</h3>

                <div className="text-bluey-grey py-3 font-light">
                  Your new password must be different to previously
                  used password.
                </div>
              </div>
              <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={{
                  newPassword: '',
                  confirmPassword: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  this.onSubmit(values);
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="d-flex flex-column"
                  >
                    <div className="d-flex flex-column mt-3">
                      <Elements.InputLabel
                        for="email"
                        label="New Password"
                      />
                      <Elements.Input
                        autoComplete="new-password"
                        id="newPassword"
                        type="password"
                        value={values.newPassword}
                        onChange={handleChange}
                        placeholder="New password"
                      />
                      <Elements.FormErrorText
                        error={errors.newPassword}
                      />
                    </div>
                    <div className="d-flex flex-column mt-3">
                      <Elements.InputLabel
                        for="password"
                        label="Confirm new password"
                      />
                      <Elements.Input
                        autoComplete="new-password"
                        id="confirmPassword"
                        type="password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        placeholder="Confirm new password"
                      />
                      <Elements.FormErrorText
                        error={errors.confirmPassword}
                      />
                    </div>
                    <Elements.Button
                      loading={this.props.newPassword.isLoading}
                      name="Change Password"
                      className="mt-4 text-white p-2"
                      type="submit"
                    />
                  </form>
                )}
              </Formik>
              <div className="d-flex align-items-center justify-content-center mt-3 text-sm">
                <Link
                  to="/login"
                  className="d-flex flex-row justify-content-center align-items-center"
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <ArrowLeft className="me-1" />
                  <div className="text-sm text--primary">
                    Back to log in
                  </div>
                </Link>
              </div>
            </div>
          ) : (
            <PasswordSuccess
              // @ts-ignore
              email={this.state.email}
              password={this.state.password}
            />
          )}
        </Modal>
      </AuthBackground>
    );
  }
}

const mapState = (state: RootState) => ({
  newPassword: state.auth.resetPassword,
});

const mapDispatch = {
  resetPassword: ({
    access,
    email,
    password,
    password_confirmation,
  }: authTypes.ResetPasswordRequest) =>
    authActions.resetPasswordAction({
      access,
      email,
      password,
      password_confirmation,
    }),
};

const connector = connect(mapState, mapDispatch);
export default connector(router.withRouter(NewPassword));
