import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { ChannelNotificationsTypes } from '../../@types/pages';
import {
  channelTypes,
  notificationTypes,
  reportTypes,
} from '../../@types/reducers';

import { alert, modal, router } from '../../helpers';
import { RootState } from '../../store';
import {
  channelActions,
  notificationActions,
  reportActions,
} from '../../actions';

import { ReactComponent as LoaderImage } from '../../assets/images/icons/loader-image.svg';

import InfiniteScroll from 'react-infinite-scroll-component';

class ChannelNotifications extends React.Component<
  ChannelNotificationsTypes.ChannelNotificationsProps &
    ConnectedProps<typeof connector>,
  ChannelNotificationsTypes.ChannelNotificationsStates
> {
  state: ChannelNotificationsTypes.ChannelNotificationsStates = {
    notificationList: [],
    notifications: null,
    totalPages: null,
    currentPage: 1,
  };

  componentDidMount() {
    this.props.getNotifications({ channel: this.props.params.id });
    this.props.getConnections();
  }

  onDeleteNotification = (guid: string) => {
    this.props.deleteNotification({ guid });
  };

  componentDidUpdate(
    prevProps: ChannelNotificationsTypes.ChannelNotificationsProps &
      ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.notifications !== this.props.notifications &&
      !this.props.notifications.isLoading &&
      !this.props.notifications.error &&
      this.props.notifications.success
    ) {
      this.setState({
        notifications: this.props.notifications
          .data as notificationTypes.INotificationList,
        totalPages: (
          this.props.notifications
            .data as notificationTypes.INotificationList
        ).meta.last_page,
        notificationList: [
          ...(this.state.notificationList &&
          this.state.notificationList.length > 0
            ? (this.state
                .notificationList as notificationTypes.INotificationList['data'])
            : []),
          ...(
            this.props.notifications
              .data as notificationTypes.INotificationList
          ).data,
        ],
      });
    }
    // if(prevProps.deleteNotification !== this.props.deleteNotification && !this.props.deleteNotification.isLoading && !this.props.deleteNotification.error && this.props.deleteNotification.success){
    // 	this.props.getNotifications({ channel: this.props.params.id });
    // }
    if (
      prevProps.deleteNotificationState !==
        this.props.deleteNotificationState &&
      !this.props.deleteNotificationState.isLoading
    ) {
      alert.fire({
        message: this.props.deleteNotificationState.error
          ? (
              this.props.deleteNotificationState
                .data as unknown as any
            ).message
          : 'The notification has just been deleted successfully!',
        error: this.props.deleteNotificationState.error,
      });
      this.refresh();
    }

    if (
      prevProps.reportedNotification !==
        this.props.reportedNotification &&
      !this.props.reportedNotification.isLoading
    ) {
      if (this.props.reportedNotification.error) {
        alert.fire({
          message: (
            this.props.reportedNotification.data as unknown as any
          ).message,
          error: this.props.reportedNotification.error,
        });
      } else {
        alert.fire({
          message:
            'The notification has just been reported successfully!',
          error: false,
        });
      }
    }
  }

  loadMore = () => {
    this.setState({
      currentPage: this.state.currentPage + 1,
    });

    this.props.getNotifications({
      page: this.state.currentPage,
      channel: this.props.params.id,
    });
  };

  refresh = () => {
    this.setState({
      currentPage: 1,
      notificationList: [],
    });

    this.props.getNotifications({
      page: this.state.currentPage,
      channel: this.props.params.id,
    });
  };

  render() {
    const currentItems = this.state.notificationList;

    return (
      <>
        {currentItems &&
        currentItems.length !== 0 &&
        Array.isArray(currentItems) ? (
          <ul id="notificationsList" className="notifications-list">
            {currentItems ? (
              <InfiniteScroll
                style={{
                  display: 'flex',
                  flexDirection: 'column-reverse',
                }} //To put endMessage and loader to the top.
                inverse={true}
                dataLength={currentItems.length}
                next={this.loadMore}
                endMessage={
                  <p className="text-center color--beepy-dark-grey">
                    You have seen it all
                  </p>
                }
                hasMore={
                  this.state.currentPage <=
                  (this.state.totalPages ?? 0)
                }
                loader={
                  <div className="d-flex justify-content-center align-items-center p-4">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">
                        Loading...
                      </span>
                    </div>
                  </div>
                }
                scrollableTarget="notificationsList"
              >
                {currentItems.map((currentItem, index) => {
                  return (
                    <li className="col-md-8 col-xs-12" key={index}>
                      <div className="content">
                        <div className="title text-start">
                          {currentItem.title}
                        </div>
                        <div className="description">
                          {currentItem.description}
                        </div>
                        <span className="show-more">Show More</span>
                        <div className="bottom justify-content-between">
                          <span
                            className="tag"
                            style={{
                              backgroundColor:
                                '#' + currentItem.label?.bg_color,
                              color:
                                '#' + currentItem.label?.text_color,
                            }}
                          >
                            {currentItem.label?.text ||
                              'Notification'}
                          </span>
                          <span className="date">
                            {currentItem.date}
                          </span>
                        </div>
                      </div>
                      <div className="footer">
                        {/*
                            <div className="data">
                              Channel Webhooks: AWS CloudWatch
                              Api:*****1234 IP Address : 192.168.1.1
                            </div>
                            */}
                        <div className="actions">
                          {currentItem.isOwner ? (
                            <a
                              href="#1"
                              className="red"
                              onClick={() => {
                                modal.confirmModal({
                                  actionName: 'Delete',
                                  description:
                                    'Are you sure you want to delete this notification?',
                                  color: 'bg-danger',
                                  title: 'Delete Notification',
                                  onClick: () => {
                                    this.props.deleteNotification({
                                      guid: currentItem.guid,
                                    });
                                  },
                                });
                              }}
                            >
                              Delete
                            </a>
                          ) : (
                            <a
                              href="#1"
                              className="light"
                              onClick={() => {
                                modal.confirmModal({
                                  actionName: 'Report',
                                  description:
                                    'Are you sure you want to report this notification?',
                                  color: 'bg-danger',
                                  title: 'Report Notification',
                                  onClick: () => {
                                    this.props.reportNotification({
                                      guid: currentItem.guid,
                                      type: 'NOTIFICATION',
                                    });
                                  },
                                });
                              }}
                            >
                              Report
                            </a>
                          )}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </InfiniteScroll>
            ) : (
              <div className="text-bluey-grey flex items-center justify-center">
                No Messages
              </div>
            )}
          </ul>
        ) : (
          <div className="items-center d-grid h-100 d-grid mb-auto">
            <div className="text-center col-10">
              <LoaderImage className="spinner" />
              <h4 className="title">
                Waiting for the first notification
              </h4>
              <h6 className="subtitle mb-4">
                Supercharge your channel and connect the tools you use
                every day.
              </h6>
              {!this.props.connections.isLoading &&
                this.props.connections.success &&
                this.props.connections.data &&
                (
                  this.props.connections
                    .data as channelTypes.IConnection[]
                ).map((_connection, key) => (
                  <a
                    href={_connection.link}
                    key={key}
                    className="connection btn w-100 d-flex"
                    style={{
                      backgroundColor:
                        '#' + _connection.background_color,
                    }}
                  >
                    <img src={_connection.logo} alt="" />
                    <div
                      className="title"
                      style={{
                        color: '#' + _connection.text_color,
                      }}
                    >
                      Connect to {_connection.title}
                      <span>Learn more on {_connection.title}</span>
                    </div>
                  </a>
                ))}
              <a href="#1" className="link-beepy">
                See more
              </a>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  notifications: state.notification.notificationList,
  deleteNotificationState: state.notification.deleteNotification,
  reportedNotification: state.report.issue,
  connections: state.channel.connections,
});

const mapDispatch = {
  getNotifications: ({
    channel,
    page,
  }: notificationTypes.INotificationRequest) =>
    notificationActions.notificationListAction({ channel, page }),
  deleteNotification: ({
    guid,
  }: notificationTypes.IDeleteNotificationRequest) =>
    notificationActions.deleteNotificationAction({ guid }),
  reportNotification: ({ guid, type }: reportTypes.IIssueRequest) =>
    reportActions.issueAction({ guid, type }),
  getConnections: () => channelActions.connectionsAction(),
};

const connector = connect(mapState, mapDispatch);
export default connector(router.withParams(ChannelNotifications));
