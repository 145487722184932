import React from 'react';
import Dropzone from 'react-dropzone';

import {
  UploadLogoProps,
  UploadLogoStates,
} from '../../@types/components/UploadLogo/UploadLogo';
import Button from '../Elements/Button';

import { ReactComponent as UploadImageLogo } from '../../assets/uploadLogo.svg';

class UploadLogo extends React.Component<
  UploadLogoProps,
  UploadLogoStates
> {
  state: UploadLogoStates = {
    files: [],
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  onSubmit = (files: (File & { preview: string })[]) => {
    this.props.onSubmit(files[0]);
    this.setState({ files: [] });
  };

  render() {
    const { files } = this.state;

    return (
      <form
        onSubmit={this.handleSubmit}
        style={{
          marginLeft: '3rem',
        }}
      >
        <div className="d-flex align-items-start justify-content-start col-12 col-md-8">
          {files.length === 0 ? (
            <Dropzone
              accept="image/jpg, image/jpeg, image/png"
              multiple={false}
              onDrop={(acceptedFiles) => {
                this.setState({
                  files: acceptedFiles.map((file) =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    })
                  ),
                });
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  className="d-flex flex-column align-items-center justify-content-center rounded-3 border p-3 text-center"
                >
                  <input {...getInputProps()} />
                  <div
                    className="col-10 col-sm-4 border rounded-circle bg-gray-100 p-2"
                    style={{
                      backgroundColor: '#eaecf0',
                    }}
                  >
                    <div className="border rounded-circle bg-gray-200 p-2">
                      <UploadImageLogo />
                    </div>
                  </div>
                  <div className="font-medium color--beepy-dark-grey pt-2">
                    <div className="text-sm">
                      <span className="select-none text-sm color--beepy-dark">
                        Click to upload
                      </span>{' '}
                      or drag and drop
                    </div>
                    <div className="text-xs">
                      JPG, JPEG, PNG allowed (Maximum: 512x512,
                      Minimum: 128x128)
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          ) : (
            <>
              {files.map((file) => (
                <div
                  className="rounded-3 border justify-content-center align-items-center"
                  key={file.name}
                >
                  <img
                    className="rounded-3"
                    src={file.preview}
                    alt={''}
                    style={{
                      width: '128px',
                      height: '128px',
                    }}
                  />
                </div>
              ))}
            </>
          )}
        </div>
        <div className="d-flex-inline">
          {files.length > 0 && this.props.buttonActive && (
            <>
              <Button
                onClick={() => this.onSubmit(this.state.files)}
                type="button"
                name="Upload Image"
                className="text-white m-2 p-2"
              />
              <Button
                onClick={() => this.setState({ files: [] })}
                className="text-white m-2 p-2"
                type="button"
                name="Discard"
                color="bg-danger"
              />
            </>
          )}
        </div>
      </form>
    );
  }
}
export default UploadLogo;
