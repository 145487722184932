export const SUBSCRIPTION_INTENT_REQUEST =  "SUBSCRIPTION_INTENT_REQUEST";
export const SUBSCRIPTION_INTENT_SUCCESS =  "SUBSCRIPTION_INTENT_SUCCESS";
export const SUBSCRIPTION_INTENT_FAILURE =  "SUBSCRIPTION_INTENT_FAILURE";

export const SUBSCRIPTION_CREATE_REQUEST =  "SUBSCRIPTION_CREATE_REQUEST";
export const SUBSCRIPTION_CREATE_SUCCESS =  "SUBSCRIPTION_CREATE_SUCCESS";
export const SUBSCRIPTION_CREATE_FAILURE =  "SUBSCRIPTION_CREATE_FAILURE";

export const SUBSCRIPTION_PACKAGES_REQUEST =  "SUBSCRIPTION_PACKAGES_REQUEST";
export const SUBSCRIPTION_PACKAGES_SUCCESS =  "SUBSCRIPTION_PACKAGES_SUCCESS";
export const SUBSCRIPTION_PACKAGES_FAILURE =  "SUBSCRIPTION_PACKAGES_FAILURE";

export const SUBSCRIPTION_PAYMENT_KEY_REQUEST =  "SUBSCRIPTION_PAYMENT_KEY_REQUEST";
export const SUBSCRIPTION_PAYMENT_KEY_SUCCESS =  "SUBSCRIPTION_PAYMENT_KEY_SUCCESS";
export const SUBSCRIPTION_PAYMENT_KEY_FAILURE =  "SUBSCRIPTION_PAYMENT_KEY_FAILURE";