import React from 'react';

import {
  TextareaProps,
  TextareaStates,
} from '../../@types/components/Elements/Textarea';

class Textarea extends React.Component<
  TextareaProps,
  TextareaStates
> {
  render() {
    return (
      <textarea
        value={this.props.value}
        rows={this.props.rows}
        hidden={this.props.hidden}
        maxLength={this.props.maxLength}
        autoComplete={this.props.autoComplete}
        id={this.props.id}
        onChange={this.props.onChange}
        placeholder={this.props.placeholder}
        className="color--beepy-dark-grey mt-1 w-100 appearance-none rounded border py-3 px-2"
      />
    );
  }
}

export default Textarea;
