import { Tooltip } from '@mui/material';
import React from 'react';
import { BsFillTrashFill } from 'react-icons/bs';

import {
  CardProps,
  CardStates,
} from '../../@types/components/Payment/Card';

import { payment } from '../../helpers';

class Card extends React.Component<CardProps, CardStates> {
  state: CardStates = {
    hover: false,
  };

  Move = () => this.setState({ hover: true });

  Leave = () => this.setState({ hover: false });

  render() {
    const { card } = this.props;

    if (!card) return null;

    return (
      <div
        onMouseLeave={this.Leave}
        onMouseEnter={this.Move}
        className="card w-75"
      >
        <div className="card-body d-flex">
          <img
            src={payment.getBrandIcon(card.card_brand)}
            alt={''}
            style={{ width: '64px', marginRight: '1rem' }}
          />
          <div className="d-flex flex-grow-1 flex-column align-items-start">
            <span className="font-semibold">
              {payment.getBrandName(card.card_brand)} ending in{' '}
              {card.last_four}
            </span>
            <div className="text-sm opacity-50">
              Expiry {card.expiry_month}/{card.expiry_year}
            </div>
            {!card.primary && (
              <div className="mt-2 d-flex align-items-center space-x-4">
                <div
                  onClick={() => this.props.onSelect(card.id)}
                  className="text--primary"
                >
                  Set as Primary
                </div>
              </div>
            )}
          </div>
          <div>
            {card.primary && (
              <span className="font-semibold text--primary">
                Primary
              </span>
            )}
            <Tooltip title="Delete" placement="bottom" arrow>
              <BsFillTrashFill
                onClick={() => this.props.onDelete(card.id)}
                className={`${
                  this.state.hover && !card.primary
                    ? 'visible'
                    : 'invisible'
                } cursor-pointer`}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}
export default Card;
