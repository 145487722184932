import React from 'react';

import {
  ButtonProps,
  ButtonStates,
} from '../../@types/components/Elements/Button';
class Button extends React.Component<ButtonProps, ButtonStates> {
  render() {
    return (
      <button
        disabled={this.props.loading || this.props.disabled}
        onClick={this.props.onClick}
        type={this.props.type}
        hidden={this.props.hidden ?? false}
        className={`${
          this.props.color ? this.props.color : 'bg--beepy-dark'
        } border rounded-2 ${
          this.props.className
            ? this.props.className
            : 'h-auto text-white p-2'
        }`}
      >
        {this.props.loading ? (
          <div className="d-flex cursor-not-allowed align-items-center justify-content-center">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        ) : (
          this.props.name
        )}
      </button>
    );
  }
}

export default Button;
