import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../../store';
import { accountActions } from '../../actions';
import {
  MessagesProps,
  MessagesStates,
} from '../../@types/pages/Messages/Messages';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Message } from '../../@types/reducers/account/messageList';
import { accountTypes } from '../../@types/reducers';
import { Elements } from '../../components';
import { Helmet } from 'react-helmet-async';
import { ReactComponent as MessagesIcon } from '../../assets/images/icons/messages.svg';

class Messages extends React.Component<
  ConnectedProps<typeof connector> & MessagesProps,
  MessagesStates
> {
  state: MessagesStates = {
    selectedMessage: null,
    currentPage: 1,
    totalPages: 1,
    messageList: [],
    isLoading: false,
  };

  componentDidMount() {
    this.props.getMessages(this.state.currentPage);
  }

  selectMessage = (message: Message | null) => {
    this.setState({
      selectedMessage: message,
    });

    if (
      message &&
      message.guid &&
      this.state.selectedMessage?.guid !== message.guid &&
      message.read === false
    ) {
      this.props.readMessage(message?.guid);

      const messageToUpdate = this.state.messageList.find(
        (msg) => msg.guid === message.guid
      );

      if (messageToUpdate) {
        messageToUpdate.read = true;
      }
    }

    return true;
  };

  loadMore = () => {
    console.log('LOAD MORE');

    this.setState({
      currentPage: this.state.currentPage + 1,
    });

    this.props.getMessages(this.state.currentPage);
  };

  refresh = () => {
    console.log('REFRESH');

    if (this.state.messageList.length > 0) {
      this.setState({
        currentPage: 1,
        totalPages: 1,
        messageList: [],
      });

      this.props.getMessages(1);
      this.props.getUserProfile();
    }
  };

  componentDidUpdate(
    prevProps: MessagesProps & ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.messages !== this.props.messages &&
      !this.props.messages.isLoading &&
      !this.props.messages.error &&
      this.props.messages.success
    ) {
      const { meta, data } = this.props.messages
        .data as accountTypes.IMessageList;

      if (meta.total > this.state.currentPage) {
        this.setState({
          totalPages: meta.last_page,
        });
      }

      this.setState({
        messageList: [
          ...(this.state.messageList &&
          this.state.messageList.length > 0
            ? this.state.messageList
            : []),
          ...data,
        ],
      });

      if (!this.state.selectedMessage) {
        this.selectMessage(data[0]);
      } else {
        data.forEach((message) => {
          if (
            message.guid === this.state.selectedMessage?.guid &&
            message.read !== this.state.selectedMessage.read
          ) {
            this.selectMessage(message);
          }
        });
      }
    }

    if (
      prevProps.deletedMessage !== this.props.deletedMessage &&
      !this.props.deletedMessage.isLoading &&
      !this.props.deletedMessage.error
    ) {
      this.selectMessage(null);
      this.refresh();
    }

    if (
      prevProps.unreadedMessage !== this.props.unreadedMessage &&
      !this.props.unreadedMessage.isLoading &&
      !this.props.unreadedMessage.error
    ) {
      this.refresh();
    }

    if (
      prevProps.readedMessage !== this.props.readedMessage &&
      !this.props.readedMessage.isLoading &&
      !this.props.readedMessage.error
    ) {
      this.refresh();
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Messages | Beepy</title>
        </Helmet>
        {!this.props.messages.isLoading &&
        this.state.messageList.length === 0 ? (
          <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div
                className="border rounded-circle p-2 mb-3"
                style={{
                  backgroundColor: '#eaecf0',
                }}
              >
                <div className="border rounded-circle p-3">
                  <MessagesIcon />
                </div>
              </div>

              <p className="beepy-title-color fw-semibold fs-4">
                Messages
              </p>
              <p className="color--beepy-dark-grey text-center">
                There is no any messages here.
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className="messages-side">
              <ul className="messages-list">
                <div
                  id="scrollableDiv"
                  style={{
                    height: 'calc(100vh - 100px)',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {this.state.messageList ? (
                    <InfiniteScroll
                      dataLength={this.state.messageList.length}
                      next={this.loadMore}
                      endMessage={
                        !this.props.messages.isLoading && (
                          <p className="text-center color--beepy-dark-grey">
                            You have seen it all
                          </p>
                        )
                      }
                      hasMore={
                        this.state.currentPage < this.state.totalPages
                      }
                      loader={
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">
                            Loading...
                          </span>
                        </div>
                      }
                      scrollableTarget="scrollableDiv"
                    >
                      {this.state.messageList.map((data, key) => {
                        return (
                          <li
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasResponsive"
                            aria-controls="offcanvasResponsive"
                            key={key}
                            className={`${
                              this.state.selectedMessage?.guid ===
                              data.guid
                                ? 'active'
                                : !data.read && 'unread'
                            }`}
                            onClick={() => this.selectMessage(data)}
                          >
                            <div className="details">
                              <span
                                className="tag"
                                style={{
                                  backgroundColor:
                                    '#' + data.label.bg_color,
                                  color: '#' + data.label.text_color,
                                }}
                              >
                                {data.label.text}
                              </span>
                              <span className="date">
                                {data.date}
                              </span>
                            </div>
                            <div className="title">{data.title}</div>
                            <div className="description">
                              {data.description}
                            </div>
                          </li>
                        );
                      })}
                    </InfiniteScroll>
                  ) : (
                    <>
                      <div className="text-bluey-grey flex items-center justify-center">
                        No Messages
                      </div>
                    </>
                  )}
                </div>
              </ul>
            </div>
            {this.state.selectedMessage &&
              this.state.selectedMessage.guid && (
                <div
                  className="messages-body container-fluid overflow-hidden d-flex justify-content-center offcanvas-lg offcanvas-end w-100"
                  id="offcanvasResponsive"
                  aria-labelledby="offcanvasResponsiveLabel"
                  data-bs-scroll="true"
                  data-bs-backdrop="false"
                >
                  <div className="content w-100">
                    <button
                      type="button"
                      className="btn-close d-block d-sm-none p-3"
                      data-bs-dismiss="offcanvas"
                      data-bs-target="#offcanvasResponsive"
                      aria-label="Close"
                    ></button>
                    <div className="channel-header">
                      <div className="row">
                        <div className="col-8 col-lg-7 d-flex">
                          <div className="channel-title d-grid">
                            Message Details
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="divider" />
                    <div
                      className="channel-body p-3"
                      style={{ overflow: 'hidden' }}
                    >
                      <div className="message-details">
                        <div className="details">
                          <span
                            className="tag"
                            style={{
                              backgroundColor:
                                '#' +
                                this.state.selectedMessage.label
                                  .bg_color,
                              color:
                                '#' +
                                this.state.selectedMessage.label
                                  .text_color,
                            }}
                          >
                            {this.state.selectedMessage.label.text}
                          </span>
                          <span className="date">
                            {this.state.selectedMessage.date}
                          </span>
                        </div>
                        <div className="title">
                          {this.state.selectedMessage.title}
                        </div>
                        <div className="details">
                          {this.state.selectedMessage.description}
                        </div>
                        <div className="actions my-4">
                          <Elements.Button
                            name={`Mark as ${
                              this.state.selectedMessage.read
                                ? 'Unread'
                                : 'Read'
                            }`}
                            type="button"
                            loading={
                              this.props.unreadedMessage.isLoading ||
                              this.props.readedMessage.isLoading
                            }
                            className="btn btn-dark"
                            onClick={() => {
                              // @ts-ignore
                              if (!this.state.selectedMessage.read) {
                                this.props.readMessage(
                                  // @ts-ignore

                                  this.state.selectedMessage.guid
                                );
                              } else {
                                this.props.unreadMessage(
                                  // @ts-ignore

                                  this.state.selectedMessage.guid
                                );
                              }
                            }}
                          />
                          <Elements.Button
                            name="Delete"
                            type="button"
                            loading={
                              this.props.deletedMessage.isLoading
                            }
                            className="btn text-white"
                            onClick={() => {
                              this.props.deleteMessage(
                                // @ts-ignore
                                this.state.selectedMessage?.guid
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </>
        )}
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  messages: state.account.messageList,
  readedMessage: state.account.readMessage,
  unreadedMessage: state.account.unreadMessage,
  deletedMessage: state.account.deleteMessage,
});

const mapDispatch = {
  getMessages: (page: number) =>
    accountActions.messageListAction({ id: page }),
  readMessage: (id: string) =>
    accountActions.messageReadAction({ message: id }),
  unreadMessage: (id: string) =>
    accountActions.unreadMessageAction({ message: id }),
  deleteMessage: (id: string) =>
    accountActions.deleteMessageAction({ message: id }),
  getUserProfile: () => accountActions.userProfileAction(),
};

const connector = connect(mapState, mapDispatch);
export default connector(Messages);
