export const NOTIFICATION_LIST_REQUEST = "NOTIFICATION_LIST_REQUEST";
export const NOTIFICATION_LIST_SUCCESS = "NOTIFICATION_LIST_SUCCESS";
export const NOTIFICATION_LIST_FAILURE = "NOTIFICATION_LIST_FAILURE";

export const NOTIFICATION_SEND_REQUEST = "NOTIFICATION_SEND_REQUEST";
export const NOTIFICATION_SEND_SUCCESS = "NOTIFICATION_SEND_SUCCESS";
export const NOTIFICATION_SEND_FAILURE = "NOTIFICATION_SEND_FAILURE";

export const NOTIFICATION_DELETE_REQUEST = "NOTIFICATION_DELETE_REQUEST";
export const NOTIFICATION_DELETE_SUCCESS = "NOTIFICATION_DELETE_SUCCESS";
export const NOTIFICATION_DELETE_FAILURE = "NOTIFICATION_DELETE_FAILURE";

export const NOTIFICATION_READ_REQUEST = "NOTIFICATION_READ_REQUEST";
export const NOTIFICATION_READ_SUCCESS = "NOTIFICATION_READ_SUCCESS";
export const NOTIFICATION_READ_FAILURE = "NOTIFICATION_READ_FAILURE";

export const NOTIFICATION_UNREAD_REQUEST = "NOTIFICATION_UNREAD_REQUEST";
export const NOTIFICATION_UNREAD_SUCCESS = "NOTIFICATION_UNREAD_SUCCESS";
export const NOTIFICATION_UNREAD_FAILURE = "NOTIFICATION_UNREAD_FAILURE";
