import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Tooltip } from '@mui/material';

import { channelTypes } from '../../@types/reducers';
import { ChannelCredentialsTypes } from '../../@types/pages';

import { RootState } from '../../store';
import { alert, modal, router } from '../../helpers';
import { channelActions } from '../../actions';

import { ReactComponent as ArrowUpRight } from '../../assets/arrow-up-right.svg';
import PasswordConfirmation from '../../components/Modal/PasswordConfirmation';

import { ReactComponent as EyeShow } from '../../assets/images/icons/eye-show.svg';
import { ReactComponent as EyeHide } from '../../assets/images/icons/eye-hide.svg';
import { ReactComponent as PlusCircle } from '../../assets/images/icons/plus-circle.svg';
import { ReactComponent as Sync } from '../../assets/images/icons/sync.svg';
import { ReactComponent as TrashAlt } from '../../assets/images/icons/trash-alt.svg';
import AddWhitelist from '../../components/Modal/AddWhitelist';

class ChannelCredentials extends React.Component<
  ChannelCredentialsTypes.ChannelCredentialsProps &
    ConnectedProps<typeof connector>,
  ChannelCredentialsTypes.ChannelCredentialsStates
> {
  modal: React.RefObject<PasswordConfirmation>;
  addWhitelistModal: React.RefObject<AddWhitelist>;

  constructor(
    props: ChannelCredentialsTypes.ChannelCredentialsProps &
      ConnectedProps<typeof connector>
  ) {
    super(props);
    this.modal = React.createRef<PasswordConfirmation>();
    this.addWhitelistModal = React.createRef<AddWhitelist>();
  }

  state: ChannelCredentialsTypes.ChannelCredentialsStates = {
    showApiKey: false,
    whiteLists: null,
    credentials: null,
    limits: null,
  };

  componentDidMount() {
    this.props.getWhiteList({ channel: this.props.params.id });
    this.props.getCredentials({ channel: this.props.params.id });
    this.props.getLimits({ channel: this.props.params.id });
  }

  componentDidUpdate(
    prevProps: ChannelCredentialsTypes.ChannelCredentialsProps &
      ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.whiteLists !== this.props.whiteLists &&
      !this.props.whiteLists.isLoading &&
      !this.props.whiteLists.error &&
      this.props.whiteLists.success
    ) {
      this.setState({
        whiteLists: this.props.whiteLists
          .data as channelTypes.IWhiteListGet[],
      });
    } else if (
      prevProps.credentials !== this.props.credentials &&
      !this.props.credentials.isLoading &&
      !this.props.credentials.error &&
      this.props.credentials.success
    ) {
      this.setState({
        credentials: this.props.credentials
          .data as channelTypes.ICredentialsGet,
      });
    } else if (
      prevProps.limits !== this.props.limits &&
      !this.props.limits.isLoading &&
      !this.props.limits.error &&
      this.props.limits.success
    ) {
      this.setState({
        limits: this.props.limits
          .data as unknown as channelTypes.IChannelLimits,
      });
    }

    if (
      prevProps.addWhiteListState !== this.props.addWhiteListState &&
      !this.props.addWhiteListState.isLoading
    ) {
      alert.fire({
        message: this.props.addWhiteListState.error
          ? (this.props.addWhiteListState.data as unknown as any)
              .message
          : 'The IP adress has just been added successfuly.',
        error: this.props.addWhiteListState.error,
      });

      this.props.getWhiteList({ channel: this.props.params.id });
      this.props.getLimits({ channel: this.props.params.id });
    }

    if (
      prevProps.deleteWhiteListState !==
        this.props.deleteWhiteListState &&
      !this.props.deleteWhiteListState.isLoading
    ) {
      alert.fire({
        message: this.props.deleteWhiteListState.error
          ? (this.props.deleteWhiteListState.data as unknown as any)
              .message
          : 'The ip address has just been deleted successfully!',
        error: this.props.deleteWhiteListState.error,
      });
      this.props.getWhiteList({ channel: this.props.params.id });
      this.props.getLimits({ channel: this.props.params.id });
    }

    if (
      prevProps.newCredentials !== this.props.newCredentials &&
      !this.props.newCredentials.isLoading
    ) {
      alert.fire({
        message: this.props.newCredentials.error
          ? (this.props.newCredentials.data as unknown as any).message
          : 'The credentials have just been updated successfully!',
        error: this.props.newCredentials.error,
      });

      this.props.getCredentials({ channel: this.props.params.id });
    }
  }

  toggleShowApiKey = () => {
    this.setState({ showApiKey: !this.state.showApiKey });
  };

  onSubmit = (values: { serverName: string; ipAddress: string }) => {
    this.props.addWhiteList({
      channel: this.props.params.id,
      ip: values.ipAddress,
      label: values.serverName,
    });
  };

  render() {
    const { whiteLists, credentials, limits } = this.state;

    if (!whiteLists || !credentials || !limits) return null;

    return (
      <>
        <PasswordConfirmation
          ref={this.modal}
          onSubmit={(password) => {
            this.props.renewApiKey({
              channel: this.props.params.id as string,
              password,
            });
          }}
          title="Renew API Key"
          description="Please enter your password for renewing API key."
          action="Renew"
        />
        <AddWhitelist
          ref={this.addWhitelistModal}
          onSubmit={this.onSubmit}
          title="Add IP Address to Whitelist"
          description="Please enter the IP address and the server name."
          action="Add"
        />
        <div className="d-flex flex-column py-2">
          <h4>Channel Credentials</h4>
          <span className="color--beepy-dark-grey">
            Channel credentials to use to send notifications. These
            are secrets. Do not share with anyone or anywhere in
            public.
          </span>
        </div>
        <div className="divider" />
        <div className="d-sm-flex py-2 align-items-center justify-content-between">
          <div className="flex-column col-12 col-md-3">
            <h6>Developer Tool</h6>
            <span className="color--beepy-dark-grey d-sm-block d-none">
              Please see our documentation how to use Beepy API or
              Webhooks to send notifications to your channels.
            </span>
          </div>
          <div className="d-flex flex-column col-12 col-md-6">
            <span className="text-[#bcbcbc]">Developers</span>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://api.beepy.io/"
              className="text--primary hover:underline"
            >
              <div className="d-inline-flex">
                api.beepy.io
                <ArrowUpRight className="ms-1" />
              </div>
            </a>
          </div>
        </div>
        <div className="divider" />

        <div className="d-sm-flex py-2 align-items-center justify-content-between">
          <div className="flex-column col-12 col-md-3">
            <h6>API Key</h6>
            <span className="color--beepy-dark-grey d-sm-block d-none">
              The Beepy API uses API keys to authenticate requests.
              You can view and manage your API key.
            </span>
          </div>
          <div className="d-inline-flex flex-column col-12 col-md-6">
            <span>API Key</span>
            <div className="d-inline-flex justify-content-between">
              <span className="color--beepy-dark-grey text-break">
                {this.state.showApiKey
                  ? credentials?.apikey
                  : '••••••••••••••••••••••••••••••' +
                    (credentials?.apikey
                      ? credentials?.apikey.substring(
                          credentials?.apikey.length - 4
                        )
                      : '')}
              </span>
              <div className="d-inline-flex align-items-center cursor-pointer">
                {this.state.showApiKey ? (
                  <Tooltip
                    title="Hide Api Key"
                    placement="bottom"
                    arrow
                  >
                    <EyeHide onClick={this.toggleShowApiKey} />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Show Api Key"
                    placement="bottom"
                    arrow
                  >
                    <EyeShow onClick={this.toggleShowApiKey} />
                  </Tooltip>
                )}
                <Tooltip
                  title="Renew Api Key"
                  placement="bottom"
                  arrow
                >
                  <div
                    onClick={() => this.modal.current?.show()}
                    className="ml-5"
                  >
                    <Sync />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <div className="divider" />

        <div className="d-sm-flex py-2">
          <div className="flex-column col-12 col-md-3 me-auto">
            <h6>Add New IP Address to Whitelist</h6>
            <span className="color--beepy-dark-grey d-sm-block d-none">
              If you do not have any whitelisted ip address, The Beepy
              API allows access from anywhere.
            </span>
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex justify-content-between">
              <span>
                {limits.whitelist.whitelist_count +
                  ' of ' +
                  limits.whitelist.max_limit}{' '}
                IP Address
              </span>
              <div
                onClick={() => this.addWhitelistModal.current?.show()}
                className="d-flex text--primary fill--primary cursor-pointer"
              >
                <PlusCircle className="me-1 pt-1" /> Add New IP
                Address
              </div>
            </div>
            <div className="divider" />
            {whiteLists.length > 0 ? (
              whiteLists.map((server, index) => {
                return (
                  <div key={index}>
                    <div className="d-flex align-items-center hoverDiv text-break">
                      <div className="d-flex flex-column p-2 me-auto">
                        <span>{server.label}</span>
                        <span>{server.ip}</span>
                      </div>
                      <Tooltip title={'Delete'}>
                        <div
                          className="ms-auto cursor-pointer"
                          onClick={() => {
                            modal.confirmModal({
                              actionName: 'Delete',
                              color: 'bg-danger',
                              description:
                                'Are you sure you want to delete this ip address?',
                              title: 'Delete IP Address',
                              onClick: () => {
                                this.props.deleteWhiteList({
                                  channel: this.props.params.id,
                                  ip: server.guid,
                                });
                              },
                            });
                          }}
                        >
                          <TrashAlt />
                        </div>
                      </Tooltip>
                    </div>
                    <div className="divider" />
                  </div>
                );
              })
            ) : (
              <div>There is no any whitelisted ip address.</div>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapState = (state: RootState) => ({
  whiteLists: state.channel.getWhiteList,
  addWhiteListState: state.channel.addWhiteList,
  deleteWhiteListState: state.channel.deleteWhiteList,
  credentials: state.channel.credentialsGet,
  limits: state.channel.channelLimits,
  newCredentials: state.channel.credentialsRenew,
});

const mapDispatch = {
  getWhiteList: ({ channel }: channelTypes.IWhiteListGetRequest) =>
    channelActions.whiteListGetAction({ channel }),
  addWhiteList: ({
    channel,
    ip,
    label,
  }: channelTypes.IAddWhiteListRequest) =>
    channelActions.addWhiteListAction({ channel, ip, label }),
  getCredentials: ({
    channel,
  }: channelTypes.ICredentialsGetRequest) =>
    channelActions.credentialsGetAction({ channel }),
  getLimits: ({ channel }: channelTypes.IChannelLimitsRequest) =>
    channelActions.channelLimitsAction({ channel }),
  deleteWhiteList: ({
    channel,
    ip,
  }: channelTypes.IDeleteWhiteListRequest) =>
    channelActions.deleteWhiteListAction({ channel, ip }),
  renewApiKey: ({
    password,
    channel,
  }: channelTypes.ICredentialsRenewRequest) =>
    channelActions.credentialsRenewAction({ password, channel }),
};

const connector = connect(mapState, mapDispatch);
export default connector(router.withParams(ChannelCredentials));
