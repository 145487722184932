import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Modal as ModalType } from '../../@types/components';

import { Elements } from '../../components';
import Modal from '../../components/Modal/Modal';

const addWhitelistModalSchema = Yup.object({
  serverName: Yup.string().required('Required'),
  ipAddress: Yup.string().required('Required'),
});

class AddWhitelist extends React.Component<
  ModalType.AddWhitelistProps,
  ModalType.AddWhitelistStates
> {
  modal: React.RefObject<Modal>;

  constructor(props: ModalType.AddWhitelistProps) {
    super(props);
    this.modal = React.createRef();
  }

  onSubmit = (values: { serverName: string; ipAddress: string }) => {
    this.props.onSubmit(values);
    this.modal.current?.hide();
  };

  show = () => this.modal.current?.show();
  hide = () => this.modal.current?.hide();

  render() {
    return (
      <Modal
        ref={this.modal}
        onClose={this.props.onClose}
        modalBodyClass="w-100 sm:w-[28rem]"
        title={this.props.title}
        description={this.props.description}
      >
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{ serverName: '', ipAddress: '' }}
          validationSchema={addWhitelistModalSchema}
          onSubmit={(values) => {
            this.onSubmit(values);
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <form
              onSubmit={handleSubmit}
              className="d-flex flex-column"
            >
              <div className="d-flex flex-column mt-4 w-100">
                <div className="m-1">
                  <Elements.InputLabel
                    for="serverName"
                    label="Server Name"
                  />
                  <Elements.Input
                    value={values.serverName}
                    onChange={handleChange}
                    type="text"
                    id="serverName"
                    placeholder="e.g. Home IP Address"
                  />
                  <Elements.FormErrorText error={errors.serverName} />
                </div>
                <div className="m-1">
                  <Elements.InputLabel
                    for="ipAddress"
                    label="IP Address"
                  />
                  <Elements.Input
                    value={values.ipAddress}
                    onChange={handleChange}
                    type="text"
                    id="ipAddress"
                    placeholder="e.g. 192.168.1.1 or IPv6"
                  />
                  <Elements.FormErrorText error={errors.ipAddress} />
                </div>
              </div>
              <div className="d-flex mt-4">
                <Elements.Button
                  onClick={() => this.hide()}
                  type="button"
                  name="Cancel"
                  color="bg-transparent color--beepy-dark"
                  className="w-50 border border-gray-300 p-2 me-3"
                />
                <Elements.Button
                  type="submit"
                  name={this.props.action ?? 'Add IP Address'}
                  className="w-50 p-2 text-white"
                />
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
}
export default AddWhitelist;
