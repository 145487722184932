import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { channelTypes } from '../../@types/reducers';
import { ChannelWebhooksTypes } from '../../@types/pages';

import { channelActions } from '../../actions';
import { router } from '../../helpers';
import { RootState } from '../../store';

class ChannelWebhooks extends React.Component<
  ChannelWebhooksTypes.ChannelWebhooksProps &
    ConnectedProps<typeof connector>,
  ChannelWebhooksTypes.ChannelWebhooksStates
> {
  render() {
    return <></>;
  }
}
const mapState = (state: RootState) => ({
  webhooks: state.channel.webHooksGet,
  createdWebhook: state.channel.createWebHook,
  updatedWebhook: state.channel.updateWebHook,
  deletedWebhook: state.channel.deleteWebHook,
  limits: state.channel.channelLimits,
});
const mapDispatch = {
  createWebhook: ({
    label,
    channel,
  }: channelTypes.ICreateWebHooksRequest) =>
    channelActions.createWebHookAction({ label, channel }),
  getWebHooks: ({ channel }: channelTypes.IWebHooksGetRequest) =>
    channelActions.webHooksGetAction({ channel }),
  updateWebHook: ({ webhook }: channelTypes.IUpdateWebHooksRequest) =>
    channelActions.updateWebHookAction({ webhook }),
  deleteWebhook: ({ webhook }: channelTypes.IDeleteWebHooksRequest) =>
    channelActions.deleteWebHookAction({ webhook }),
  getChannelLimits: ({
    channel,
  }: channelTypes.IChannelLimitsRequest) =>
    channelActions.channelLimitsAction({ channel }),
};
const connector = connect(mapState, mapDispatch);
export default connector(router.withParams(ChannelWebhooks));
