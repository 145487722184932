import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Outlet } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { ChannelTypes } from '../../@types/pages';
import { channelTypes } from '../../@types/reducers';

import { RootState } from '../../store';
import { channelActions } from '../../actions';
import { alert, router } from '../../helpers';

import { ReactComponent as MoreHorizontal } from '../../assets/images/icons/more-horizontal.svg';
import { ReactComponent as Terminal } from '../../assets/images/icons/terminal.svg';
import { ReactComponent as Shield } from '../../assets/images/icons/shield.svg';
import { ReactComponent as Settings } from '../../assets/images/icons/settings.svg';
import { ReactComponent as AlertCircle } from '../../assets/images/icons/alert-circle.svg';
import { ReactComponent as PlusCircle } from '../../assets/images/icons/plus-circle.svg';
import { ReactComponent as Users } from '../../assets/images/icons/users.svg';
import InviteUser from '../../components/Modal/InviteUser';

class Channel extends React.Component<
  ChannelTypes.ChannelProps & ConnectedProps<typeof connector>,
  ChannelTypes.ChannelState
> {
  menuRef = React.createRef<HTMLDivElement>();

  state: ChannelTypes.ChannelState = {
    details: null,
    limits: null,
    dropdown: false,
  };

  modal = React.createRef<InviteUser>();

  constructor(
    props: ChannelTypes.ChannelProps &
      ConnectedProps<typeof connector>
  ) {
    super(props);
    this.modal = React.createRef<InviteUser>();
  }

  links = [
    {
      to: `/channels/${this.props.params.id}/members`,
      text: 'Members',
      show: false,
      ownerOnly: true,
      icon: <Users />,
    },
    {
      to: `/channels/${this.props.params.id}/invitations`,
      text: 'Invitations',
      show: false,
      ownerOnly: true,
      icon: <PlusCircle />,
    },
    {
      to: `/channels/${this.props.params.id}/details`,
      text: 'Details',
      show: true,
      ownerOnly: false,
      icon: <AlertCircle />,
    },
    {
      to: `/channels/${this.props.params.id}/settings`,
      text: 'Settings',
      show: false,
      ownerOnly: true,
      icon: <Settings />,
    },
    {
      to: `/channels/${this.props.params.id}/credentials`,
      text: 'Credentials',
      show: false,
      ownerOnly: true,
      icon: <Shield />,
    },
    {
      to: `/channels/${this.props.params.id}/webhooks`,
      text: 'Webhooks',
      show: false,
      ownerOnly: true,
      icon: <Terminal />,
    },
  ];

  navigate = this.props.navigate();

  componentDidMount() {
    this.props.getChannelDetails({ channel: this.props.params.id });
    this.props.getLimits({ channel: this.props.params.id });

    document.addEventListener('click', this.handleOutsideClick);
  }

  componentDidUpdate(
    prevProps: ChannelTypes.ChannelProps &
      ConnectedProps<typeof connector>
  ) {
    this.links = this.links.map((link) => {
      link.to = `/channels/${this.props.params.id}/${
        link.to.split('/')[3]
      }`;
      return link;
    });

    if (this.props.location.search === '?addMember=true') {
      this.modal.current?.show();
    }

    if (prevProps.params.id !== this.props.params.id)
      this.props.getChannelDetails({ channel: this.props.params.id });

    if (
      prevProps.details !== this.props.details &&
      !this.props.details.isLoading &&
      !this.props.details.error &&
      this.props.details.success
    ) {
      const details = this.props.details
        .data as channelTypes.IChannelDetails;

      this.setState({ details });

      this.links = this.links.map((link) => {
        if (link.ownerOnly === true && details.isOwner)
          link.show = true;
        else if (link.ownerOnly === true && !details.isOwner)
          link.show = false;
        else if (link.ownerOnly === false) link.show = true;
        return link;
      });
    } else if (
      prevProps.details !== this.props.details &&
      !this.props.details.isLoading &&
      this.props.details.error
    ) {
      alert.fire({
        message: this.props.details.error
          ? (this.props.details.data as unknown as any).message
          : 'An error occurred while loading channel details.',
        error: true,
      });
    }

    if (
      prevProps.invitedMember !== this.props.invitedMember &&
      !this.props.invitedMember.isLoading
    ) {
      alert.fire({
        message: this.props.invitedMember.error
          ? (this.props.invitedMember.data as unknown as any).message
          : 'The member has just been invited to the channel successfully!',
        error: this.props.invitedMember.error,
      });
    }

    if (
      prevProps.limits !== this.props.limits &&
      !this.props.limits.isLoading &&
      !this.props.limits.error &&
      this.props.limits.success
    ) {
      this.setState({
        limits: this.props.limits.data as channelTypes.IChannelLimits,
      });
    }
  }

  handleOutsideClick = (e: any) => {
    if (this.state.dropdown) {
      if (this.menuRef && !this.menuRef.current?.contains(e.target)) {
        this.setState({
          dropdown: false,
        });
      }
    }
  };

  render() {
    const { details } = this.state;
    if (!details) return null;

    return (
      <>
        <Helmet>
          <title>{details.title + ' | Beepy'}</title>
        </Helmet>
        <InviteUser
          title="Invite Member"
          description="Add a new member to this channel"
          ref={this.modal}
          onSubmit={(email) =>
            this.props.addMember({
              channel: this.props.params.id as string,
              email,
            })
          }
          membersCount={this.state.limits?.members.members_count}
          membersLimit={this.state.limits?.members.max_limit}
        />
        <div className="content w-100">
          {this.props.location.pathname.endsWith('/notifications') ? (
            <>
              <div className="channel-header">
                <div className="row">
                  <div className="col-12 col-lg-7 d-flex">
                    <div className="channel-logo d-flex align-items-center justify-content-center">
                      <img src={details.logo} alt="" />
                    </div>
                    <div className="channel-title d-grid text-truncate">
                      {details.title}
                      <span className="channel-slug">
                        @{details.slug}
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-4 col-lg-5 d-flex align-items-center justify-content-end dropdown d-none d-lg-flex"
                    ref={this.menuRef}
                  >
                    {details.isOwner && (
                      <div
                        onClick={() => this.modal.current?.show()}
                        className="btn header-button"
                      >
                        Add Member
                      </div>
                    )}
                    <a
                      href="#1"
                      className="btn header-button ms-3"
                      id="channelMoreDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() =>
                        this.setState({
                          dropdown: !this.state.dropdown,
                        })
                      }
                    >
                      <MoreHorizontal />
                    </a>
                    <ul
                      className={`dropdown-menu dropdown-menu text-small shadow my-2 ${
                        this.state.dropdown && 'show'
                      }`}
                      style={{ width: '200px', top: '55px' }}
                      aria-labelledby="channelMoreDropdown"
                    >
                      {this.links.map(
                        (link, index) =>
                          link.show && (
                            <li key={index}>
                              <Link
                                to={link.to}
                                key={index}
                                className="dropdown-item"
                              >
                                {link.text}
                              </Link>
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="divider" />
              <div

              //style={{ overflow: 'hidden' }}
              >
                <Outlet />
              </div>
            </>
          ) : (
            <>
              <div className="channel-header">
                <div className="breadcrumb d-flex align-items-center">
                  <div className="breadcrumb-item">Channels</div>
                  <div className="breadcrumb-divider" />
                  <Link
                    to={`/channels/${this.props.params.id}/notifications`}
                    className="breadcrumb-item text-truncate"
                    style={{ textDecoration: 'none' }}
                  >
                    {details.title}
                  </Link>
                  <div className="breadcrumb-divider" />
                  <div className="breadcrumb-item active">
                    {
                      this.links.find((link) =>
                        link.to.endsWith(this.props.location.pathname)
                      )?.text
                    }
                  </div>
                </div>
                <ul className="channel-settings-menu">
                  {this.links.map(
                    (link, index) =>
                      link.show && (
                        <li
                          className={`${
                            this.props.location.pathname ===
                              link.to && 'active'
                          }`}
                          key={index}
                        >
                          <Link to={link.to} key={index}>
                            {/*link.icon*/}
                            {link.text}
                          </Link>
                        </li>
                      )
                  )}
                </ul>
              </div>
              <div
                className="channel-body"
                //style={{ overflow: 'hidden' }}
              >
                <Outlet />
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  details: state.channel.channelDetails,
  invitedMember: state.channel.channelInvitaitonSend,
  limits: state.channel.channelLimits,
});

const mapDispatch = {
  getChannelDetails: ({
    channel,
  }: channelTypes.IChannelDetailsRequest) =>
    channelActions.channelDetailsAction({ channel }),
  addMember: ({
    channel,
    email,
  }: channelTypes.IInvitationSendRequest) =>
    channelActions.channelInvitationSendAction({ channel, email }),
  getLimits: ({ channel }: channelTypes.IChannelLimitsRequest) =>
    channelActions.channelLimitsAction({ channel }),
};
const connector = connect(mapState, mapDispatch);
export default connector(router.withRouter(Channel));
