import React from 'react';
import StyledModal from 'styled-react-modal';

import { Modal as ModalType } from '../../@types/components';

class Modal extends React.Component<
  ModalType.ModalProps,
  ModalType.ModalStates
> {
  state = { isOpen: false };
  modalRef = React.createRef<HTMLDivElement>();

  componentDidUpdate(
    prevProps: ModalType.ModalProps,
    prevState: ModalType.ModalStates
  ) {
    if (!this.state.isOpen && prevState.isOpen) {
      document.removeEventListener('click', this.handleOutsideClick);
    } else if (this.state.isOpen && !prevState.isOpen) {
      setTimeout(() => {
        document.addEventListener('click', this.handleOutsideClick);
      }, 800);
    }
  }

  show = () => {
    this.setState({ isOpen: true });
  };

  hide = () => {
    console.log('closable', this.props.closable);
    if (this.props.closable === false) return;

    this.props.onClose && this.props.onClose();
    this.setState({ isOpen: false });
  };

  handleOutsideClick = (e: any) => {
    if (
      this.state.isOpen &&
      this.modalRef &&
      !this.modalRef.current?.contains(e.target)
    )
      this.hide();
  };

  render() {
    return (
      <StyledModal
        onBackgroundClick={this.hide}
        isOpen={this.state.isOpen}
        onEscapeKeydown={this.hide}
      >
        <div
          className={`modal ${this.state.isOpen && 'show'}`}
          style={{ display: this.state.isOpen ? 'block' : 'none' }}
        >
          <div
            className={`modal-dialog modal-dialog-centered ${this.props.modalBodyClass}`}
            role="document"
          >
            <div className="modal-content" ref={this.modalRef}>
              {this.props.title && (
                <div className="modal-header">
                  <h5 className="modal-title">{this.props.title}</h5>
                </div>
              )}
              <div className="modal-body">
                <p>{this.props.description}</p>
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal-backdrop show"
          id="backdrop"
          style={{ display: this.state.isOpen ? 'block' : 'none' }}
        ></div>
      </StyledModal>
    );
  }
}

export default Modal;
