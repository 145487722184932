import React from 'react';

import { connect, ConnectedProps } from 'react-redux';

import { channelTypes } from '../../@types/reducers';
import { ChannelMembersTypes } from '../../@types/pages';

import { alert, modal, router } from '../../helpers';
import { RootState } from '../../store';
import { channelActions } from '../../actions';
import InviteUser from '../../components/Modal/InviteUser';

import { ReactComponent as MoreHorizontal } from '../../assets/images/icons/more-horizontal.svg';
import { ReactComponent as Trash } from '../../assets/images/icons/trash-2.svg';
import { BsFillPeopleFill, BsFillPersonFill } from 'react-icons/bs';

class ChannelMembers extends React.Component<
  ChannelMembersTypes.ChannelMembersProps &
    ConnectedProps<typeof connector>,
  ChannelMembersTypes.ChannelMembersStates
> {
  modal: React.RefObject<InviteUser>;
  menuRef = React.createRef<HTMLUListElement>();

  constructor(
    props: ChannelMembersTypes.ChannelMembersProps &
      ConnectedProps<typeof connector>
  ) {
    super(props);
    this.modal = React.createRef<InviteUser>();
  }

  state: ChannelMembersTypes.ChannelMembersStates = {
    selectedMember: null,
    members: null,
    limits: null,
    dropdownAction: null,
  };

  handleOutsideClick = (e: any) => {
    if (this.state.dropdownAction) {
      if (this.menuRef && !this.menuRef.current?.contains(e.target)) {
        this.setState({
          dropdownAction: null,
        });
      }
    }
  };

  setSelectedMembers = (member: string) => {
    if (this.state.selectedMember === member) {
      this.setState({ selectedMember: null });
    } else {
      this.setState({ selectedMember: member });
    }
  };

  toggleAction = (action: string) => {
    this.setState({
      dropdownAction: action,
    });
  };

  componentDidMount() {
    this.props.getMembers({ channel: this.props.params.id });
    this.props.getLimits({ channel: this.props.params.id });

    document.addEventListener('click', this.handleOutsideClick);
  }

  componentDidUpdate(
    prevProps: ChannelMembersTypes.ChannelMembersProps &
      ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.members !== this.props.members &&
      !this.props.members.isLoading &&
      !this.props.members.error &&
      this.props.members.success
    ) {
      this.setState({
        members: this.props.members
          .data as channelTypes.IChannelMembers,
      });
    }

    if (
      prevProps.limits !== this.props.limits &&
      !this.props.limits.isLoading &&
      !this.props.limits.error &&
      this.props.limits.success
    ) {
      this.setState({
        limits: this.props.limits.data as channelTypes.IChannelLimits,
      });
    }

    if (
      prevProps.invitedMember !== this.props.invitedMember &&
      !this.props.invitedMember.isLoading
    ) {
      alert.fire({
        message: this.props.invitedMember.error
          ? (this.props.invitedMember.data as unknown as any).message
          : 'The member has just been invited to the channel successfully!',
        error: this.props.invitedMember.error,
      });

      this.props.getMembers({ channel: this.props.params.id });
    }

    if (
      prevProps.deleteMemberState !== this.props.deleteMemberState &&
      !this.props.deleteMemberState.isLoading
    ) {
      alert.fire({
        message: this.props.deleteMemberState.error
          ? (this.props.deleteMemberState.data as unknown as any)
              .message
          : 'The member has just been deleted successfully!',
        error: this.props.deleteMemberState.error,
      });

      this.props.getMembers({ channel: this.props.params.id });
    }
  }

  render() {
    const { members } = this.state;

    return (
      <ul
        ref={this.menuRef}
        className="invitations-list striped-list"
      >
        <li className="d-md-flex d-none">
          <div className="col-3 flex-shrink-0">Members Name</div>
          <div className="col-2">Member Status</div>
          <div className="col-3">Invitation Date</div>
          <div className="col-2"></div>
        </li>
        {members &&
          members.list.length > 0 &&
          members.list.map((member, key) => (
            <li className="d-flex row" key={key}>
              <div className="col-12 col-md-3 d-flex">
                <div
                  className="d-flex bg-secondary rounded-pill text-white d-flex justify-content-center align-items-center flex-shrink-0 position-relative"
                  style={{
                    width: '3rem',
                    height: '3rem',
                  }}
                >
                  <span>
                    {member.name[0].toUpperCase()}
                    {member.surname[0].toUpperCase()}
                  </span>
                  {
                    /* online badge on the edge */
                    member.status === 'ACTIVE' && (
                      <span
                        style={{ padding: '6px' }}
                        className="position-absolute bottom-0 end-0 bg--beepy-green border border-light rounded-circle"
                      ></span>
                    )
                  }
                </div>
                <div className="d-grid details flex-grow-1 ms-3">
                  <span className="title">
                    {member.name} {member.surname}
                  </span>
                  <span className="subtitle">{member.email}</span>
                </div>
              </div>
              <div className="col-12 col-md-3 d-flex align-items-center justify-content-end justify-content-md-start">
                <span className="badge rounded-pill bg--beepy-grey text-dark d-inline-flex">
                  {member.type === 'MEMBER' && (
                    <BsFillPeopleFill fill="#ff9b01" />
                  )}
                  {member.type === 'OWNER' && (
                    <BsFillPersonFill fill="#ff9b01" />
                  )}
                  <span style={{ marginLeft: '0.25rem' }}>
                    {member.type}
                  </span>
                </span>
              </div>
              <div className="col-12 col-md-3 d-flex align-items-center">
                {member.date}
              </div>
              {member.type === 'MEMBER' && (
                <div className="col-12 d-flex align-items-center justify-content-end actions">
                  <a
                    className="actions-buttons d-flex align-items-center"
                    href="#1"
                    onClick={() => this.toggleAction(member.guid)}
                  >
                    <MoreHorizontal />
                  </a>
                  <ul
                    className={`dropdown-menu text-small shadow my-2 ${
                      this.state.dropdownAction === member.guid &&
                      'show'
                    }`}
                    style={{ width: '40px' }}
                    aria-labelledby="invitations"
                  >
                    <li>
                      <button
                        className="dropdown-item d-flex align-items-center"
                        onClick={() =>
                          modal.confirmModal({
                            actionName: 'Delete',
                            description:
                              'Are you sure you want to delete this member?',
                            onClick: () => {
                              this.props.deleteMember({
                                member: member.guid,
                              });
                            },
                            color: 'bg-danger',
                            title: 'Delete Member',
                          })
                        }
                      >
                        <span className="me-1">
                          <Trash />
                        </span>
                        Delete
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </li>
          ))}
      </ul>
    );
  }
}

const mapState = (state: RootState) => ({
  limits: state.channel.channelLimits,
  members: state.channel.channelMembers,
  invitedMember: state.channel.channelInvitaitonSend,
  deleteMemberState: state.channel.channelMemberDelete,
});

const mapDispatch = {
  getLimits: ({ channel }: channelTypes.IChannelLimitsRequest) =>
    channelActions.channelLimitsAction({ channel }),
  getMembers: ({ channel }: channelTypes.IChannelMembersRequest) =>
    channelActions.channelMembersAction({ channel }),
  addMember: ({
    channel,
    email,
  }: channelTypes.IInvitationSendRequest) =>
    channelActions.channelInvitationSendAction({ channel, email }),
  deleteMember: ({
    member,
  }: channelTypes.IChannelMemberDeleteRequest) =>
    channelActions.channelMemberDeleteAction({ member }),
};

const connector = connect(mapState, mapDispatch);

export default connector(router.withParams(ChannelMembers));
