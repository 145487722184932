import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { ProfileTypes } from '../../@types/pages';
import { accountTypes } from '../../@types/reducers';

import { RootState } from '../../store';
import { accountActions } from '../../actions';
import { alert, modal } from '../../helpers';

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { ReactComponent as Apple } from '../../assets/Apple.svg';
import { ReactComponent as Android } from '../../assets/Android.svg';
import { ReactComponent as Desktop } from '../../assets/Desktop.svg';
import { ReactComponent as Web } from '../../assets/Web.svg';
import { BsDot } from 'react-icons/bs';
import { Helmet } from 'react-helmet-async';

class ProfileDevices extends React.Component<
  ProfileTypes.ProfileDevicesProps & ConnectedProps<typeof connector>,
  ProfileTypes.ProfileDevicesStates
> {
  state: ProfileTypes.ProfileDevicesStates = {
    selectedDevice: null,
    devices: null,
    isLoading: false,
  };

  componentDidMount() {
    this.props.getDevices();
  }

  componentDidUpdate(
    prevProps: ProfileTypes.ProfileInformationProps &
      ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.devices !== this.props.devices &&
      !this.props.devices.isLoading &&
      !this.props.devices.error &&
      this.props.devices.success
    ) {
      this.setState({
        devices: this.props.devices.data as accountTypes.IDevice[],
      });
    }

    if (
      prevProps.deletedDevice !== this.props.deletedDevice &&
      !this.props.deletedDevice.isLoading
    ) {
      alert.fire({
        message: this.props.deletedDevice.error
          ? (this.props.deletedDevice.data as unknown as any).message
          : 'Device deleted successfully!',
        error: this.props.deletedDevice.error,
      });

      this.props.getDevices();

      this.setState({
        isLoading: false,
      });
    }
  }

  setSelectedDevice = (device: string) => {
    if (this.state.selectedDevice === device) {
      this.setState({ selectedDevice: null });
    } else {
      this.setState({ selectedDevice: device });
    }
  };

  render() {
    const { devices } = this.state;

    return (
      <>
        <Helmet>
          <title>Devices | Beepy</title>
        </Helmet>

        <div className="d-flex flex-column py-2">
          <h4>Where you’re logged in</h4>
          <span className="color--beepy-dark-grey">
            Control your devices.
          </span>
        </div>
        <div className="divider" />

        <div className="d-flex py-2 justify-content-between">
          <div className="flex-column col-6 col-md-3 me-auto">
            <h6>Devices</h6>
            <span className="color--beepy-dark-grey">
              Manage your devices.
            </span>
          </div>
          <div className="col-8 col-md-6 d-grid gap-3">
            {devices &&
              devices.length > 0 &&
              devices.map((device, index) => (
                <div
                  key={index}
                  onClick={() => {
                    this.setSelectedDevice(device.guid);
                  }}
                >
                  <div className="card">
                    <div className="card-body d-flex">
                      <div className="mt-2 pr-3">
                        {device.os === 'IOS' ? (
                          <Apple />
                        ) : device.os === 'ANDROID' ? (
                          <Android />
                        ) : device.os === 'DESKTOP' ? (
                          <Desktop />
                        ) : (
                          <Web />
                        )}
                      </div>
                      <div style={{ marginLeft: '0.5rem' }}>
                        <div className="d-inline-flex ">
                          <span>{device.os}</span>
                          {device.current && (
                            <div
                              className="d-flex rounded-5 bg--beepy-light-green"
                              style={{ marginLeft: '0.5rem' }}
                            >
                              <div className="animate-ping-container">
                                <BsDot
                                  className="animate-ping color--beepy-dark-green"
                                  size={25}
                                />
                              </div>
                              <div
                                className="color--beepy-dark-green"
                                style={{ marginRight: '1rem' }}
                              >
                                Current Device
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="color--beepy-dark-grey">
                          {device.date}
                        </div>
                      </div>
                      <div className="ms-auto mt-3">
                        {this.state.selectedDevice === device.guid ? (
                          <IoIosArrowUp fill="black" />
                        ) : (
                          <IoIosArrowDown fill="black" />
                        )}
                      </div>
                    </div>
                    {this.state.selectedDevice === device.guid && (
                      <div className="mx-4 color--beepy-dark-grey pb-3">
                        <div className="divider" />

                        <div className="d-flex flex-column">
                          <span>
                            Location: {device.location.city}
                          </span>
                          <span>Version: {device.os_version}</span>
                          <span>IP Address: {device.ip_address}</span>
                          <span>Date: {device.date}</span>
                        </div>

                        {!device.current && (
                          <span
                            onClick={() => {
                              if (!this.state.isLoading) {
                                modal.confirmModal({
                                  title: 'Delete device',
                                  description:
                                    'Are you sure you want to delete this device?',
                                  actionName: 'Delete',
                                  onClick: () => {
                                    this.setState({
                                      isLoading: true,
                                    });
                                    this.props.deleteDevice({
                                      guid: device.guid,
                                    });
                                  },
                                  color: 'bg-red-600',
                                });
                              }
                            }}
                            className="text--primary cursor-pointer"
                          >
                            Delete Device
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  devices: state.account.deviceList,
  deletedDevice: state.account.deleteDevice,
});

const mapDispatch = {
  getDevices: () => accountActions.deviceListAction(),
  deleteDevice: ({ guid }: accountTypes.IDeleteDeviceRequest) =>
    accountActions.deleteDeviceAction({ guid }),
};

const connector = connect(mapState, mapDispatch);
export default connector(ProfileDevices);
