import React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect, ConnectedProps } from 'react-redux';

import { AuthTypes } from '../../@types/pages';

import { RootState } from '../../store';
import { authActions } from '../../actions';
import { alert, router } from '../../helpers';

import { Elements } from '../../components';

import { ReactComponent as LogoDark } from '../../assets/images/logo-dark.svg';
import AuthBackground from './AuthBackground';
import Modal from '../../components/Modal/Modal';
import { Helmet } from 'react-helmet-async';

const validationSchema = Yup.object({
  email: Yup.string()
    .required('Required')
    .email('Invalid e-mail address!'),
  password: Yup.string().required('Required'),
});

class Login extends React.Component<
  AuthTypes.LoginProps & ConnectedProps<typeof connector>,
  AuthTypes.LoginStates
> {
  modal: React.RefObject<Modal>;

  constructor(
    props: AuthTypes.LoginProps & ConnectedProps<typeof connector>
  ) {
    super(props);
    this.modal = React.createRef();
  }

  onSubmit = (values: { email: string; password: string }) => {
    this.props.loginAction(values);
  };

  componentDidMount() {
    this.modal.current?.show();
  }

  componentDidUpdate(
    prevProps: AuthTypes.LoginProps & ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.login !== this.props.login &&
      !this.props.login.isLoading
    ) {
      alert.fire({
        message: this.props.login.error
          ? (this.props.login.data as unknown as any).message
          : 'Login successfully!',
        error: this.props.login.error,
      });

      if (!this.props.login.error) this.props.navigate('/channels');
    }
  }

  render() {
    return (
      <AuthBackground>
        <Helmet>
          <title>Login | Beepy</title>
        </Helmet>
        <Modal
          closable={false}
          modalBodyClass="w-100"
          ref={this.modal}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            {' '}
            <LogoDark className="logoAuth" />
            <h3 id="singinTitleh3">Sign in to your account</h3>
            <small className="smallTitle">
              Welcome back! Please enter your details.
            </small>
          </div>
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{ email: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              this.onSubmit(values);
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <form onSubmit={handleSubmit}>
                <div className="d-flex flex-column">
                  <h4 className="inputText">Email</h4>
                  <input
                    type="email"
                    className="form-control rounded-3"
                    id="email"
                    placeholder="Enter your email"
                    autoComplete="email"
                    onChange={handleChange}
                    value={values.email}
                  />
                  <Elements.FormErrorText error={errors.email} />
                </div>

                <div className="d-flex flex-column">
                  <h4 className="inputText">Password</h4>
                  <input
                    type="password"
                    className="form-control rounded-3"
                    id="password"
                    autoComplete="current-password"
                    placeholder="Password"
                    onChange={handleChange}
                    value={values.password}
                  />
                  <Elements.FormErrorText error={errors.password} />
                </div>

                <Elements.Button
                  name="Log in"
                  type="submit"
                  color="w-100 bg--beepy-dark mt-3"
                  loading={this.props.login.isLoading}
                >
                  Log in
                </Elements.Button>
              </form>
            )}
          </Formik>
          <div className="d-flex flex-column justify-content-center align-items-center mt-3">
            <Link to="/forgot-password" id="forgotPass">
              Forgot password
            </Link>
            <Link id="bottomTextDiv" to="/register">
              <small id="smallTextLeft">Don't have account?</small>
              <small id="smallTextRight"> Create an account.</small>
            </Link>
          </div>
        </Modal>
      </AuthBackground>
    );
  }
}

const mapState = (state: RootState) => ({
  login: state.auth.login,
});

const mapDispatch = {
  loginAction: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => authActions.loginAction({ email, password }),
};

const connector = connect(mapState, mapDispatch);
export default connector(router.withRouter(Login));
