import { confirmAlert } from 'react-confirm-alert';

import { Elements } from '../components';

const confirmModal = ({
  title,
  description,
  actionName,
  onClick,
  color,
}: {
  title: string;
  description: string;
  actionName: string;
  onClick: () => void;
  color: string;
}) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <>
          <div className="modal" style={{ display: 'block' }}>
            <div
              className="modal-dialog modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{title}</h5>
                </div>

                <div className="modal-body">
                  <p>{description}</p>
                  <div className="d-flex">
                    <Elements.Button
                      onClick={onClose}
                      type="button"
                      name="Cancel"
                      color="bg-gray-1000"
                      className="bg-gray-1000 w-100 border bg-transparent border-gray-300 p-2 me-3"
                    />
                    <Elements.Button
                      onClick={() => {
                        onClick();
                        onClose();
                      }}
                      type="submit"
                      name={actionName}
                      className={`w-100 p-2 text-white ${color}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    },
  });
};

export { confirmModal };
