export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const LIST_COUNTRIES_REQUEST = 'LIST_COUNTRIES_REQUEST';
export const LIST_COUNTRIES_SUCCESS = 'LIST_COUNTRIES_SUCCESS';
export const LIST_COUNTRIES_FAILURE = 'LIST_COUNTRIES_FAILURE';

export const LIST_TIMEZONES_REQUEST = 'LIST_TIMEZONES_REQUEST';
export const LIST_TIMEZONES_SUCCESS = 'LIST_TIMEZONES_SUCCESS';
export const LIST_TIMEZONES_FAILURE = 'LIST_TIMEZONES_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const LIST_URLS_REQUEST = 'LIST_URLS_REQUEST';
export const LIST_URLS_SUCCESS = 'LIST_URLS_SUCCESS';
export const LIST_URLS_FAILURE = 'LIST_URLS_FAILURE';

export const LIST_FEEDBACKS_REQUEST = 'LIST_FEEDBACKS_REQUEST';
export const LIST_FEEDBACKS_SUCCESS = 'LIST_FEEDBACKS_SUCCESS';
export const LIST_FEEDBACKS_FAILURE = 'LIST_FEEDBACKS_FAILURE';

export const SUBSCRIPTION_PACKAGES_REQUEST = 'SUBSCRIPTION_PACKAGES_REQUEST';
export const SUBSCRIPTION_PACKAGES_SUCCESS = 'SUBSCRIPTION_PACKAGES_SUCCESS';
export const SUBSCRIPTION_PACKAGES_FAILURE = 'SUBSCRIPTION_PACKAGES_FAILURE';

export const LIST_CATEGORIES_REQUEST = 'LIST_CATEGORIES_REQUEST';
export const LIST_CATEGORIES_SUCCESS = 'LIST_CATEGORIES_SUCCESS';
export const LIST_CATEGORIES_FAILURE = 'LIST_CATEGORIES_FAILURE';

export const VERIFICATION_CODE_REQUEST = 'VERIFICATION_CODE_REQUEST';
export const VERIFICATION_CODE_SUCCESS = 'VERIFICATION_CODE_SUCCESS';
export const VERIFICATION_CODE_FAILURE = 'VERIFICATION_CODE_FAILURE';

export const VERIFICATION_CODE_RESEND_REQUEST = 'VERIFICATION_CODE_RESEND_REQUEST';
export const VERIFICATION_CODE_RESEND_SUCCESS = 'VERIFICATION_CODE_RESEND_SUCCESS';
export const VERIFICATION_CODE_RESEND_FAILURE = 'VERIFICATION_CODE_RESEND_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
