import { channelTypes } from '../../@types/reducers';
import { api } from '../../helpers';

export const connectionsAction = () => {
	return async (dispatch: channelTypes.WhiteListDispatchType) => {
		dispatch({ type: channelTypes.actionTypes.CHANNEL_CONNECTIONS_REQUEST });

		const response = await api.post({ endpoint: 'client/channel/connections' });

		if (response.ok) {
			return dispatch({ type: channelTypes.actionTypes.CHANNEL_CONNECTIONS_SUCCESS, data: response.data });
		} else dispatch({ type: channelTypes.actionTypes.CHANNEL_CONNECTIONS_FAILURE, data: response.data });
	};
};
