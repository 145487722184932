import { toast } from 'react-toastify';
import { Toast } from 'react-toastify/dist/types';
import Alert from '../components/Alert/Alert';
import AlertClose from '../components/Alert/AlertClose';

const fire = ({
  message,
  error,
  type,
}: {
  message: string;
  error: boolean;
  type?: Toast['props']['type'];
}) => {
  toast(
    <Alert
      title={error ? 'Error!' : 'Success!'}
      message={message}
      type={
        !type ? (error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS) : type
      }
    />,
    {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose:
        process.env.REACT_APP_ENV === 'production' ? 3000 : false,
      draggable: false,
      hideProgressBar: true,
      closeButton: <AlertClose />,
    }
  );
};

export { fire };
