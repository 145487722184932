import React from 'react';

import {
  InputProps,
  InputStates,
} from '../../@types/components/Elements/Input';

class Input extends React.Component<InputProps, InputStates> {
  render() {
    return (
      <input
        hidden={this.props.hidden}
        maxLength={this.props.maxLength}
        autoComplete={this.props.autoComplete}
        id={this.props.id}
        onChange={this.props.onChange}
        value={this.props.value}
        type={this.props.type}
        placeholder={this.props.placeholder}
        className="bg-white color--beepy-dark-grey border beepy-input-border rounded-3 w-100 py-3 px-2 mt-1"
      />
    );
  }
}

export default Input;
