import { Formik, FormikProps } from 'formik';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as Yup from 'yup';

import { ProfileTypes } from '../../@types/pages';
import { accountTypes, authTypes } from '../../@types/reducers';

import { alert } from '../../helpers';
import { RootState } from '../../store';
import { accountActions, authActions } from '../../actions';
import { Elements } from '../../components';
import { Helmet } from 'react-helmet-async';

const validationSchema = Yup.object({
  message: Yup.string().required('Required').max(255),
  type: Yup.string().required('Required'),
  subject: Yup.string().required('Required'),
});

class ProfileFeedback extends React.Component<
  ProfileTypes.ProfileFeedbackProps &
    ConnectedProps<typeof connector>,
  ProfileTypes.ProfileFeedbackStates
> {
  formik: React.RefObject<
    FormikProps<{ message: string; type: string; subject: string }>
  >;

  constructor(
    props: ProfileTypes.ProfileFeedbackProps &
      ConnectedProps<typeof connector>
  ) {
    super(props);
    this.formik = React.createRef<
      FormikProps<{
        message: string;
        type: string;
        subject: string;
      }>
    >();
  }

  state: ProfileTypes.ProfileFeedbackStates = {
    isLoading: false,
  };

  componentDidMount() {
    this.props.getFeedbacks();
  }

  componentDidUpdate(prevProps: ConnectedProps<typeof connector>) {
    if (
      prevProps.feedback !== this.props.feedback &&
      !this.props.feedback.isLoading
    ) {
      alert.fire({
        message: this.props.feedback.error
          ? (this.props.feedback.data as unknown as any).message
          : 'Feedback sent successfully!',
        error: this.props.feedback.error,
      });

      this.setState({
        isLoading: false,
      });
    }
  }

  onSubmit = (values: {
    type: string;
    subject: string;
    message: string;
  }) => {
    if (!this.state.isLoading) {
      this.formik.current?.resetForm();
      this.setState({ isLoading: true });
      this.props.createFeedback({
        type: values.type,
        subject: values.subject,
        message: values.message,
      });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Feedback | Beepy</title>
        </Helmet>

        <div className="d-flex flex-column py-2">
          <h4>Feedback</h4>
          <span className="color--beepy-dark-grey">
            You can share your ideas and well review.
          </span>
        </div>
        <div className="divider" />

        <div className="d-flex py-2 justify-content-between">
          <div className="flex-column col-6 col-md-3 me-auto">
            <div className="d-grid">
              <div>Type</div>
              <div className="mt-5">Subject</div>
              <div className="mt-5">Message</div>
            </div>
          </div>
          <div className="col-8 col-md-6">
            <Formik
              innerRef={this.formik}
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={{ type: '', subject: '', message: '' }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                this.onSubmit(values);
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <div className="">
                  <form onSubmit={handleSubmit}>
                    <div className="d-grid gap-2">
                      <Elements.Select
                        value={values.type}
                        onChange={handleChange}
                        id="type"
                      >
                        <option value="">Select feedback type</option>
                        {this.props.feedbacks.data &&
                          !this.props.feedbacks.isLoading &&
                          !this.props.feedbacks.error &&
                          Object.entries(
                            (
                              this.props.feedbacks
                                .data as authTypes.IFeedbacks
                            ).types
                          ).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                      </Elements.Select>
                      <Elements.FormErrorText error={errors.type} />

                      <Elements.Select
                        value={values.subject}
                        onChange={handleChange}
                        id="subject"
                      >
                        <option value="">
                          Select feedback subject
                        </option>
                        {this.props.feedbacks.data &&
                          !this.props.feedbacks.isLoading &&
                          !this.props.feedbacks.error &&
                          Object.entries(
                            (
                              this.props.feedbacks
                                .data as authTypes.IFeedbacks
                            ).subjects
                          ).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                      </Elements.Select>
                      <Elements.FormErrorText
                        error={errors.subject}
                      />

                      <Elements.Textarea
                        id="message"
                        onChange={handleChange}
                        value={values.message}
                        maxLength={255}
                        rows={4}
                        placeholder="Message"
                      />
                      <Elements.FormErrorText
                        error={errors.message}
                      />
                      <div className="select-none text-sm color--beepy-dark-grey">
                        {255 - values.message.length} characters left
                      </div>
                      <div className="d-flex select-none flex-row-reverse">
                        <Elements.Button
                          loading={this.props.feedback.isLoading}
                          className="mt-8 w-25 p-2 text-white"
                          type="submit"
                          name="Send"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  feedback: state.account.createFeedback,
  feedbacks: state.auth.feedbacks,
});

const mapDispatch = {
  createFeedback: ({
    type,
    subject,
    message,
  }: accountTypes.ICreateFeedBackRequest) =>
    accountActions.createFeedbackAction({ type, subject, message }),
  getFeedbacks: () => authActions.listFeedBacksAction(),
};

const connector = connect(mapState, mapDispatch);
export default connector(ProfileFeedback);
