export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

export const USER_SUBSCRIPTION_REQUEST = 'USER_SUBSCRIPTION_REQUEST';
export const USER_SUBSCRIPTION_SUCCESS = 'USER_SUBSCRIPTION_SUCCESS';
export const USER_SUBSCRIPTION_FAILURE = 'USER_SUBSCRIPTION_FAILURE';

export const USER_SUBSCRIPTION_RESUME_REQUEST = 'USER_SUBSCRIPTION_RESUME_REQUEST';
export const USER_SUBSCRIPTION_RESUME_SUCCESS = 'USER_SUBSCRIPTION_RESUME_SUCCESS';
export const USER_SUBSCRIPTION_RESUME_FAILURE = 'USER_SUBSCRIPTION_RESUME_FAILURE';

export const USER_SUBSCRIPTION_CANCEL_REQUEST = 'USER_SUBSCRIPTION_CANCEL_REQUEST';
export const USER_SUBSCRIPTION_CANCEL_SUCCESS = 'USER_SUBSCRIPTION_CANCEL_SUCCESS';
export const USER_SUBSCRIPTION_CANCEL_FAILURE = 'USER_SUBSCRIPTION_CANCEL_FAILURE';

export const USER_SUBSCRIPTION_CHANGE_REQUEST = 'USER_SUBSCRIPTION_CHANGE_REQUEST';
export const USER_SUBSCRIPTION_CHANGE_SUCCESS = 'USER_SUBSCRIPTION_CHANGE_SUCCESS';
export const USER_SUBSCRIPTION_CHANGE_FAILURE = 'USER_SUBSCRIPTION_CHANGE_FAILURE';

export const USER_PAYMENT_METHODS_REQUEST = 'USER_PAYMENT_METHODS_REQUEST';
export const USER_PAYMENT_METHODS_SUCCESS = 'USER_PAYMENT_METHODS_SUCCESS';
export const USER_PAYMENT_METHODS_FAILURE = 'USER_PAYMENT_METHODS_FAILURE';

export const USER_PAYMENT_METHODS_ADD_REQUEST = 'USER_PAYMENT_METHODS_ADD_REQUEST';
export const USER_PAYMENT_METHODS_ADD_SUCCESS = 'USER_PAYMENT_METHODS_ADD_SUCCESS';
export const USER_PAYMENT_METHODS_ADD_FAILURE = 'USER_PAYMENT_METHODS_ADD_FAILURE';

export const USER_PAYMENT_METHODS_DELETE_REQUEST = 'USER_PAYMENT_METHODS_DELETE_REQUEST';
export const USER_PAYMENT_METHODS_DELETE_SUCCESS = 'USER_PAYMENT_METHODS_DELETE_SUCCESS';
export const USER_PAYMENT_METHODS_DELETE_FAILURE = 'USER_PAYMENT_METHODS_DELETE_FAILURE';

export const USER_PAYMENT_METHODS_SET_PRIMARY_REQUEST = 'USER_PAYMENT_METHODS_SETPRIMARY_REQUEST';
export const USER_PAYMENT_METHODS_SET_PRIMARY_SUCCESS = 'USER_PAYMENT_METHODS_SETPRIMARY_SUCCESS';
export const USER_PAYMENT_METHODS_SET_PRIMARY_FAILURE = 'USER_PAYMENT_METHODS_SETPRIMARY_FAILURE';

export const USER_INVOICES_REQUEST = 'USER_INVOICES_REQUEST';
export const USER_INVOICES_SUCCESS = 'USER_INVOICES_SUCCESS';
export const USER_INVOICES_FAILURE = 'USER_INVOICES_FAILURE';

export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';

export const UPDATE_BILLING_ADDRESS_REQUEST = 'UPDATE_BILLING_ADDRESS_REQUEST';
export const UPDATE_BILLING_ADDRESS_SUCCESS = 'UPDATE_BILLING_ADDRESS_SUCCESS';
export const UPDATE_BILLING_ADDRESS_FAILURE = 'UPDATE_BILLING_ADDRESS_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const CREATE_FEEDBACK_REQUEST = 'CREATE_FEEDBACK_REQUEST';
export const CREATE_FEEDBACK_SUCCESS = 'CREATE_FEEDBACK_SUCCESS';
export const CREATE_FEEDBACK_FAILURE = 'CREATE_FEEDBACK_FAILURE';

export const INVITATION_LIST_REQUEST = 'INVITATION_LIST_REQUEST';
export const INVITATION_LIST_SUCCESS = 'INVITATION_LIST_SUCCESS';
export const INVITATION_LIST_FAILURE = 'INVITATION_LIST_FAILURE';

export const INVITATION_ACTION_REQUEST = 'INVITATION_ACTION_REQUEST';
export const INVITATION_ACTION_SUCCESS = 'INVITATION_ACTION_SUCCESS';
export const INVITATION_ACTION_FAILURE = 'INVITATION_ACTION_FAILURE';

export const UPDATE_PUSH_TOKEN_REQUEST = 'UPDATE_PUSH_TOKEN_REQUEST';
export const UPDATE_PUSH_TOKEN_SUCCESS = 'UPDATE_PUSH_TOKEN_SUCCESS';
export const UPDATE_PUSH_TOKEN_FAILURE = 'UPDATE_PUSH_TOKEN_FAILURE';

export const DEVICE_LIST_REQUEST = 'DEVICE_LIST_REQUEST';
export const DEVICE_LIST_SUCCESS = 'DEVICE_LIST_SUCCESS';
export const DEVICE_LIST_FAILURE = 'DEVICE_LIST_FAILURE';

export const DEVICE_DELETE_REQUEST = 'DEVICE_DELETE_REQUEST';
export const DEVICE_DELETE_SUCCESS = 'DEVICE_DELETE_SUCCESS';
export const DEVICE_DELETE_FAILURE = 'DEVICE_DELETE_FAILURE';

export const MESSAGE_LIST_REQUEST = 'MESSAGE_LIST_REQUEST';
export const MESSAGE_LIST_SUCCESS = 'MESSAGE_LIST_SUCCESS';
export const MESSAGE_LIST_FAILURE = 'MESSAGE_LIST_FAILURE';

export const MESSAGE_READ_REQUEST = 'MESSAGE_READ_REQUEST';
export const MESSAGE_READ_SUCCESS = 'MESSAGE_READ_SUCCESS';
export const MESSAGE_READ_FAILURE = 'MESSAGE_READ_FAILURE';

export const MESSAGE_UNREAD_REQUEST = 'MESSAGE_UNREAD_REQUEST';
export const MESSAGE_UNREAD_SUCCESS = 'MESSAGE_UNREAD_SUCCESS';
export const MESSAGE_UNREAD_FAILURE = 'MESSAGE_UNREAD_FAILURE';

export const MESSAGE_DELETE_REQUEST = 'MESSAGE_DELETE_REQUEST';
export const MESSAGE_DELETE_SUCCESS = 'MESSAGE_DELETE_SUCCESS';
export const MESSAGE_DELETE_FAILURE = 'MESSAGE_DELETE_FAILURE';
