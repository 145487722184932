import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import {
  accountActions,
  authActions,
  channelActions,
} from '../../actions';
import { RootState } from '../../store';
import { router } from '../../helpers';

import {
  NavbarProps,
  NavbarStates,
} from '../../@types/components/Panel/Navbar';

import { ReactComponent as LogoDark } from '../../assets/images/logo-dark.svg';
import { ReactComponent as MoreHorizontal } from '../../assets/images/icons/more-horizontal.svg';

import { Link } from 'react-router-dom';
import { channelTypes } from '../../@types/reducers';
class Navbar extends React.Component<
  NavbarProps & ConnectedProps<typeof connector>,
  NavbarStates
> {
  state: NavbarStates = {
    isMobileMenuOpen: false,
    isInvitationsOpen: false,
    isMessagesOpen: false,
    user: null,
    channel: null,
  };

  componentDidMount() {
    const channelId = this.props.location.pathname.includes(
      '/channels/'
    )
      ? this.props.location.pathname.split('/')[2]
      : null;

    if (channelId) this.props.getChannelDetails(channelId);
  }

  componentDidUpdate(
    prevProps: NavbarProps & ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.channel !== this.props.channel &&
      !this.props.channel.isLoading
    ) {
      if (this.props.channel.data) {
        this.setState({
          channel: this.props.channel
            .data as channelTypes.IChannelDetails,
        });
      } else {
        this.setState({
          channel: null,
        });
      }
    }

    if (
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      if (
        // @ts-ignore
        document.querySelector("button[data-bs-target='#beSidebar']")
          .ariaLabel === 'Close'
      ) {
        // @ts-ignore
        document
          // @ts-ignore
          .querySelector("button[data-bs-target='#beSidebar']")
          // @ts-ignore
          .click();
      }
    }
  }

  classNameNames = (...classNamees: any) => {
    return classNamees.filter(Boolean).join(' ');
  };

  handleMobileMenu = () => {
    this.setState({
      isMobileMenuOpen: !this.state.isMobileMenuOpen,
    });
  };

  handleInvitationsMenu = () => {
    if (this.state.isMobileMenuOpen) {
      this.setState({
        isMobileMenuOpen: false,
      });
    }
    this.setState({
      isInvitationsOpen: !this.state.isInvitationsOpen,
    });
  };

  handleMessagesMenu = () => {
    if (this.state.isMobileMenuOpen) {
      this.setState({
        isMobileMenuOpen: false,
      });
    }
    this.setState({
      isMessagesOpen: !this.state.isMessagesOpen,
    });
  };

  render() {
    return (
      <>
        <header className="navbar navbar-expand-lg navbar-dark bd-navbar sticky-top d-md-block d-lg-none">
          <nav
            className="container-xxl bd-gutter flex-wrap flex-lg-nowrap d-lg-none"
            aria-label="Main navigation"
          >
            <button
              className="navbar-toggler p-2"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#bdSidebar"
              aria-controls="bdSidebar"
              aria-expanded="false"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                className="bi"
                fill="#667085"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                ></path>
              </svg>

              <span className="d-none fs-6 pe-1">Browse</span>
            </button>

            <Link
              className="navbar-brand p-0 me-0 me-lg-2"
              to="/"
              aria-label="Bootstrap"
            >
              <LogoDark />
            </Link>

            <div className="d-flex align-items-center justify-content-end dropdown">
              <Link
                to="#1"
                className="navbar-toggler p-2"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#beSidebar"
                aria-controls="beSidebar"
                aria-expanded="false"
              >
                <MoreHorizontal />
              </Link>
              <div
                className="offcanvas-lg offcanvas-end"
                id="beSidebar"
                aria-labelledby="beSidebarLabel"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
              >
                <div className="offcanvas-header px-4 pb-0">
                  <Link
                    className="navbar-brand p-0 me-0 me-lg-2"
                    to="/"
                    aria-label="Bootstrap"
                  >
                    <LogoDark />
                  </Link>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    data-bs-target="#beSidebar"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  {this.props.location.pathname.includes(
                    '/channels/'
                  ) ? (
                    <ul className="channels-list nav nav-pills d-grid mb-auto">
                      {this.state.channel?.isOwner && (
                        <>
                          <li className="channel-item">
                            <Link
                              to={`/channels/${
                                this.props.location.pathname.split(
                                  '/'
                                )[2]
                              }/notifications?addMember=true`}
                              className="nav-link"
                              aria-current="page"
                            >
                              Add Member
                            </Link>
                            <div className="divider"></div>
                          </li>
                          <li
                            className={`channel-item ${
                              this.props.location.pathname.endsWith(
                                '/members'
                              ) && 'active'
                            }`}
                          >
                            <Link
                              to={`/channels/${
                                this.props.location.pathname.split(
                                  '/'
                                )[2]
                              }/members`}
                              className="nav-link"
                              aria-current="page"
                            >
                              Members
                            </Link>
                            <div className="divider"></div>
                          </li>
                          <li
                            className={`channel-item ${
                              this.props.location.pathname.endsWith(
                                '/invitations'
                              ) && 'active'
                            }`}
                          >
                            <Link
                              to={`/channels/${
                                this.props.location.pathname.split(
                                  '/'
                                )[2]
                              }/invitations`}
                              className="nav-link"
                              aria-current="page"
                            >
                              Invitations
                            </Link>
                            <div className="divider"></div>
                          </li>
                          <li
                            className={`channel-item ${
                              this.props.location.pathname.endsWith(
                                '/settings'
                              ) && 'active'
                            }`}
                          >
                            <Link
                              to={`/channels/${
                                this.props.location.pathname.split(
                                  '/'
                                )[2]
                              }/settings`}
                              className="nav-link"
                              aria-current="page"
                            >
                              Settings
                            </Link>
                            <div className="divider"></div>
                          </li>
                          <li
                            className={`channel-item ${
                              this.props.location.pathname.endsWith(
                                '/credentials'
                              ) && 'active'
                            }`}
                          >
                            <Link
                              to={`/channels/${
                                this.props.location.pathname.split(
                                  '/'
                                )[2]
                              }/credentials`}
                              className="nav-link"
                              aria-current="page"
                            >
                              Credentials
                            </Link>
                            <div className="divider"></div>
                          </li>
                          <li
                            className={`channel-item ${
                              this.props.location.pathname.endsWith(
                                '/webhooks'
                              ) && 'active'
                            }`}
                          >
                            <Link
                              to={`/channels/${
                                this.props.location.pathname.split(
                                  '/'
                                )[2]
                              }/webhooks`}
                              className="nav-link"
                              aria-current="page"
                            >
                              Webhooks
                            </Link>
                          </li>
                        </>
                      )}
                      <li
                        className={`channel-item ${
                          this.props.location.pathname.endsWith(
                            '/details'
                          ) && 'active'
                        }`}
                      >
                        {this.state.channel?.isOwner && (
                          <div className="divider"></div>
                        )}
                        <Link
                          to={`/channels/${
                            this.props.location.pathname.split('/')[2]
                          }/details`}
                          className="nav-link"
                          aria-current="page"
                        >
                          Details
                        </Link>
                        <div className="divider"></div>
                      </li>
                    </ul>
                  ) : this.props.location.pathname.includes(
                      '/profile'
                    ) ? (
                    <ul className="channels-list nav nav-pills d-grid mb-auto ">
                      <li
                        className={`channel-item ${
                          this.props.location.pathname.endsWith(
                            '/profile'
                          ) && 'active'
                        }`}
                      >
                        <Link
                          to="/profile"
                          className="nav-link"
                          aria-current="page"
                        >
                          Profile
                        </Link>
                        <div className="divider"></div>
                      </li>
                      <li
                        className={`channel-item ${
                          this.props.location.pathname.endsWith(
                            '/profile/devices'
                          ) && 'active'
                        }`}
                      >
                        <Link
                          to="/profile/devices"
                          className="nav-link"
                          aria-current="page"
                        >
                          Devices
                        </Link>
                        <div className="divider"></div>
                      </li>
                      <li
                        className={`channel-item ${
                          this.props.location.pathname.endsWith(
                            '/profile/feedback'
                          ) && 'active'
                        }`}
                      >
                        <Link
                          to="/profile/feedback"
                          className="nav-link"
                          aria-current="page"
                        >
                          Feedback
                        </Link>
                        <div className="divider"></div>
                      </li>
                      <li
                        className={`channel-item ${
                          this.props.location.pathname.endsWith(
                            '/profile/subscription'
                          ) && 'active'
                        }`}
                      >
                        <Link
                          to="/profile/subscription"
                          className="nav-link"
                          aria-current="page"
                        >
                          Subscription
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    <ul className="channels-list nav nav-pills d-grid mb-auto ">
                      <li
                        className={`channel-item ${
                          this.props.location.pathname.endsWith(
                            '/invitations'
                          ) && 'active'
                        }`}
                      >
                        <Link
                          to="/invitations"
                          className="nav-link"
                          aria-current="page"
                        >
                          Invitations
                        </Link>
                        <div className="divider"></div>
                      </li>
                      <li
                        className={`channel-item ${
                          this.props.location.pathname.endsWith(
                            '/messages'
                          ) && 'active'
                        }`}
                      >
                        <Link
                          to="/messages"
                          className="nav-link"
                          aria-current="page"
                        >
                          Messages
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </nav>
        </header>
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  user: state.account.userProfile,
  channel: state.channel.channelDetails,
  loggedOut: state.auth.logout,
});

const mapDispatch = {
  getUserProfile: () => accountActions.userProfileAction(),
  getChannelDetails: (channelId: string) =>
    channelActions.channelDetailsAction({ channel: channelId }),
  logout: () => authActions.logoutAction(),
};

const connector = connect(mapState, mapDispatch);
export default connector(router.withRouter(Navbar));
