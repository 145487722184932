import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { channelTypes } from '../../@types/reducers';
import { ChannelWebhooksTypes } from '../../@types/pages';

import { channelActions } from '../../actions';
import { alert, modal, router } from '../../helpers';
import { RootState } from '../../store';

import CreateWebhook from '../../components/Modal/CreateWebhook';
import WebhookShow from '../../components/Modal/WebhookShow';

import { ReactComponent as EyeShow } from '../../assets/images/icons/eye-show.svg';
import { ReactComponent as TrashAlt } from '../../assets/images/icons/trash-alt.svg';
import { ReactComponent as PlusCircle } from '../../assets/images/icons/plus-circle.svg';
import { Tooltip } from '@mui/material';

class ChannelWebhooks extends React.Component<
  ChannelWebhooksTypes.ChannelWebhooksProps &
    ConnectedProps<typeof connector>,
  ChannelWebhooksTypes.ChannelWebhooksStates
> {
  createWebhookModal: React.RefObject<CreateWebhook>;
  showWebhookModal: React.RefObject<WebhookShow>;

  constructor(
    props: ChannelWebhooksTypes.ChannelWebhooksProps &
      ConnectedProps<typeof connector>
  ) {
    super(props);
    this.createWebhookModal = React.createRef<CreateWebhook>();
    this.showWebhookModal = React.createRef<WebhookShow>();
  }

  state: ChannelWebhooksTypes.ChannelWebhooksStates = {
    webhooks: null,
    limits: null,
    currentWebhook: null,
  };

  setWebHookActive = (
    webhook: channelTypes.IWebHooksGet['webhook']
  ) => {
    this.props.updateWebHook({ webhook: webhook });
  };

  componentDidMount() {
    this.props.getWebHooks({ channel: this.props.params.id });
    this.props.getChannelLimits({ channel: this.props.params.id });
  }

  componentDidUpdate(
    prevProps: ChannelWebhooksTypes.ChannelWebhooksProps &
      ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.webhooks !== this.props.webhooks &&
      !this.props.webhooks.isLoading &&
      !this.props.webhooks.error &&
      this.props.webhooks.success
    ) {
      this.setState({
        webhooks: this.props.webhooks
          .data as channelTypes.IWebHooksGet[],
      });
    }

    if (
      prevProps.limits !== this.props.limits &&
      !this.props.limits.isLoading &&
      !this.props.limits.error &&
      this.props.limits.success
    ) {
      this.setState({
        limits: this.props.limits.data as channelTypes.IChannelLimits,
      });
    }

    if (
      prevProps.createdWebhook !== this.props.createdWebhook &&
      !this.props.createdWebhook.isLoading
    ) {
      alert.fire({
        message: this.props.createdWebhook.error
          ? (this.props.createdWebhook.data as unknown as any).message
          : 'The webhook has just been created successfully!',
        error: this.props.createdWebhook.error,
      });

      this.componentDidMount();
    }

    if (
      prevProps.updatedWebhook !== this.props.updatedWebhook &&
      !this.props.updatedWebhook.isLoading
    ) {
      alert.fire({
        message: this.props.updatedWebhook.error
          ? (this.props.updatedWebhook.data as unknown as any).message
          : 'The webhook has just been updated successfully!',
        error: this.props.updatedWebhook.error,
      });

      this.componentDidMount();
    }

    if (
      prevProps.deletedWebhook !== this.props.deletedWebhook &&
      !this.props.deletedWebhook.isLoading
    ) {
      alert.fire({
        message: this.props.deletedWebhook.error
          ? (this.props.deletedWebhook.data as unknown as any).message
          : 'The webhook has just been deleted successfully!',
        error: this.props.deletedWebhook.error,
      });

      this.componentDidMount();
    }
  }

  render() {
    const { webhooks } = this.state;
    if (!webhooks) return null;
    return (
      <>
        <CreateWebhook
          title="Create Webhook"
          description="Add a new Webhook to the channel!"
          ref={this.createWebhookModal}
          onSubmit={(webhook) =>
            this.props.createWebhook({
              label: webhook,
              channel: this.props.params.id as string,
            })
          }
          webhookLimit={this.state.limits?.webhooks.max_limit ?? 0}
          webhooksCount={
            this.state.limits?.webhooks.webhooks_count ?? 0
          }
        />
        <WebhookShow
          ref={this.showWebhookModal}
          webhook={this.state.currentWebhook}
          title={`Webhook ${this.state.currentWebhook?.label}`}
          /*
          onDelete={() => {
            modal.confirmModal({
              actionName: 'Delete',
              description:
                'Are you sure you want to delete this webhook?',
              color: 'bg-red-600',
              title: 'Delete Webhook',
              onClick: () => {
                this.state.currentWebhook &&
                  this.props.deleteWebhook({
                    webhook: this.state.currentWebhook.guid,
                  });
              },
            });
          }}
          */
        />
        <div className="d-flex flex-column py-2">
          <h4>Channel Webhooks</h4>
          <span className="color--beepy-dark-grey">
            Webhooks are a simple way to post messages from apps into
            Beepy Channel.
          </span>
        </div>
        <div className="divider" />

        <div className="d-sm-flex py-2 align-items-center justify-content-between">
          <div className="flex-column col-12 col-md-3">
            <h6>Create Webhook</h6>
            <span className="color--beepy-dark-grey">
              Webhooks requires POST request from your source.
            </span>
          </div>
          <div
            className="d-flex col-12 col-md-6"
            onClick={() => this.createWebhookModal.current?.show()}
          >
            <PlusCircle className="me-1 pt-1" />
            <span className="text--primary cursor-pointer">
              Create Webhook
            </span>
          </div>
        </div>
        <div className="divider" />

        <div className="d-sm-flex py-2">
          <div className="flex-column col-12 col-md-3 me-auto">
            <h6>URL List</h6>
            <span className="color--beepy-dark-grey">
              All of your created webhooks are listed.
            </span>
          </div>
          <div className="col-12 col-md-6">
            {webhooks.length > 0 ? (
              webhooks.map((webhook, index) => {
                return (
                  <div key={index}>
                    <div className="hoverDiv">
                      <div className="d-flex justify-content-between p-2">
                        <div className="d-flex flex-column text-break me-4">
                          <span>{webhook.label}</span>
                          <span className="d-none d-lg-flex color--beepy-dark-grey">
                            {webhook.webhook
                              .split('/')
                              .slice(0, 5)
                              .join('/') +
                              '/' +
                              webhook.webhook
                                .split('/')
                                .slice(0, 6)[5]
                                .substring(0, 5) +
                              '*****'}
                          </span>
                        </div>
                        <div className="col-md-6 col-xl-4 d-inline-flex align-items-center">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked={
                                webhook.status === 'ACTIVE'
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                this.setWebHookActive(webhook.guid);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexSwitchCheckDefault"
                            >
                              {webhook.status}
                            </label>
                          </div>
                          <Tooltip title="Show Webhook">
                            <div
                              onClick={() => {
                                this.setState({
                                  currentWebhook: webhook,
                                });
                                this.showWebhookModal.current?.show();
                              }}
                            >
                              <EyeShow />
                            </div>
                          </Tooltip>
                          <Tooltip title="Delete Webhook">
                            <div
                              onClick={() => {
                                modal.confirmModal({
                                  actionName: 'Delete',
                                  description:
                                    'Are you sure you want to delete this webhook?',
                                  color: 'bg-danger',
                                  title: 'Delete Webhook',
                                  onClick: () => {
                                    this.props.deleteWebhook({
                                      webhook: webhook.guid,
                                    });
                                  },
                                });
                              }}
                            >
                              <TrashAlt />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    {index !== webhooks.length - 1 && (
                      <div className="divider" />
                    )}
                  </div>
                );
              })
            ) : (
              <div className="d-flex flex-column justify-content-center">
                <span className="color--beepy-dark-grey">
                  You don't have any webhooks yet.
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="divider" />
      </>
    );
  }
}
const mapState = (state: RootState) => ({
  webhooks: state.channel.webHooksGet,
  createdWebhook: state.channel.createWebHook,
  updatedWebhook: state.channel.updateWebHook,
  deletedWebhook: state.channel.deleteWebHook,
  limits: state.channel.channelLimits,
});
const mapDispatch = {
  createWebhook: ({
    label,
    channel,
  }: channelTypes.ICreateWebHooksRequest) =>
    channelActions.createWebHookAction({ label, channel }),
  getWebHooks: ({ channel }: channelTypes.IWebHooksGetRequest) =>
    channelActions.webHooksGetAction({ channel }),
  updateWebHook: ({ webhook }: channelTypes.IUpdateWebHooksRequest) =>
    channelActions.updateWebHookAction({ webhook }),
  deleteWebhook: ({ webhook }: channelTypes.IDeleteWebHooksRequest) =>
    channelActions.deleteWebHookAction({ webhook }),
  getChannelLimits: ({
    channel,
  }: channelTypes.IChannelLimitsRequest) =>
    channelActions.channelLimitsAction({ channel }),
};
const connector = connect(mapState, mapDispatch);
export default connector(router.withParams(ChannelWebhooks));
