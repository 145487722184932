import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ArrowLeft } from '../../assets/images/icons/arrow-left.svg';
import { Elements } from '../../components';

import {
  PasswordSuccessProps,
  PasswordSuccessStates,
} from '../../@types/pages/Auth';
import { alert, router } from '../../helpers';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../store';
import { authActions } from '../../actions';
import { authTypes } from '../../@types/reducers';
import { AuthLogo } from '../../components/Auth';

class PasswordSuccess extends React.Component<
  PasswordSuccessProps & ConnectedProps<typeof connector>,
  PasswordSuccessStates
> {
  componentDidUpdate(
    prevProps: PasswordSuccessProps & ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.loggedIn !== this.props.loggedIn &&
      !this.props.loggedIn.isLoading
    ) {
      alert.fire({
        message: this.props.loggedIn.error
          ? (this.props.loggedIn.data as unknown as any).message
          : 'Logged in successfully!',
        error: this.props.loggedIn.error,
      });

      if (!this.props.loggedIn.error)
        this.props.navigate('/channels');
    }
  }

  render() {
    return (
      <div className="my-auto mx-auto d-flex justify-content-center pt-8">
        <div className="d-flex align-items-center justify-content-center flex-column text-center">
          <div className="mt-8 d-flex align-items-center justify-content-center">
            <AuthLogo color="dark" />
          </div>
          <h3 id="singinTitleh3">Password reset</h3>

          <div className="text-bluey-grey py-3 font-light">
            Your password has been successfully reset. Click below to
            log in magically.
          </div>

          <Elements.Button
            loading={this.props.loggedIn.isLoading}
            onClick={() =>
              this.props.login({
                email: this.props.email,
                password: this.props.password,
              })
            }
            name="Continue"
            className="mt-4 text-white p-2 w-100"
            type="button"
          />

          <div className="d-flex align-items-center justify-content-center mt-3 text-sm">
            <Link
              to="/login"
              className="d-flex flex-row justify-content-center align-items-center"
              style={{
                textDecoration: 'none',
              }}
            >
              <ArrowLeft className="me-1" />
              <div className="text-sm text--primary">
                Back to log in
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: RootState) => ({
  loggedIn: state.auth.login,
});

const mapDispatch = {
  login: ({ email, password }: authTypes.LoginRequest) =>
    authActions.loginAction({ email, password }),
};

const connector = connect(mapState, mapDispatch);
export default connector(router.withRouter(PasswordSuccess));
