import React from 'react';
import { toast } from 'react-toastify';

import {
  AlertProps,
  AlertStates,
} from '../../@types/components/Alert/Alert';

import { ReactComponent as AlertSuccess } from '../../assets/alertSuccess.svg';
import { ReactComponent as AlertRed } from '../../assets/alertRed.svg';
import { ReactComponent as AlertTriangle } from '../../assets/alertTriangle.svg';

class Alert extends React.Component<AlertProps, AlertStates> {
  selectedIcon = () => {
    switch (this.props.type) {
      case toast.TYPE.SUCCESS:
        return <AlertSuccess />;
      case toast.TYPE.ERROR:
        return <AlertRed />;
      case toast.TYPE.WARNING:
        return <AlertTriangle />;
      default:
        return <AlertTriangle />;
    }
  };

  render() {
    return (
      <p className="text-sm text-secondary p-1 alertBody">
        <span className="fw-bold">{this.props.title}</span>{' '}
        {this.props.message}
      </p>
    );
  }
}

export default Alert;
