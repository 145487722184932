import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

// import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import {
  accountTypes,
  authTypes,
  subscriptionTypes,
} from '../../@types/reducers';
import { ProfileTypes } from '../../@types/pages';

import { RootState } from '../../store';
import {
  accountActions,
  authActions,
  subscriptionActions,
} from '../../actions';
import { alert, modal } from '../../helpers';

import { ReactComponent as Plus } from '../../assets/plus.svg';

import Card from '../../components/Payment/Card';
import BillingAddress from '../../components/Modal/BillingAddress';
import AddPaymentMethod from '../../components/Modal/AddPaymentMethod';
import SubscriptionCancellation from '../../components/Modal/SubscriptionCancellation';
import ChangePlan from '../../components/Modal/ChangePlanNew';

import Checkout from '../../components/Modal/CheckoutNew';

import { ReactComponent as CheckMark } from '../../assets/images/icons/check-mark.svg';
import { ReactComponent as Cross } from '../../assets/images/icons/cross.svg';
import { ReactComponent as DownloadCloud } from '../../assets/images/icons/download-cloud.svg';
import { Tooltip } from '@mui/material';

import { ReactComponent as ArrowUpRight } from '../../assets/arrow-up-right.svg';
import { Helmet } from 'react-helmet-async';

const stripePromise = loadStripe(
  (process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_STRIPE_PROD_KEY
    : process.env.REACT_APP_STRIPE_DEV_KEY) as string
);

class ProfileSubscription extends React.Component<
  ProfileTypes.ProfileSubscriptionProps &
    ConnectedProps<typeof connector>,
  ProfileTypes.ProfileSubscriptionStates
> {
  billingAddressModal: React.RefObject<BillingAddress>;
  addPaymentMethodModal: React.RefObject<AddPaymentMethod>;
  subscriptionCancellationModal: React.RefObject<SubscriptionCancellation>;
  changePlanModal: React.RefObject<ChangePlan>;
  checkoutModal: React.RefObject<Checkout>;

  constructor(
    props: ProfileTypes.ProfileSubscriptionProps &
      ConnectedProps<typeof connector>
  ) {
    super(props);
    this.billingAddressModal = React.createRef<BillingAddress>();
    this.addPaymentMethodModal = React.createRef<AddPaymentMethod>();
    this.subscriptionCancellationModal =
      React.createRef<SubscriptionCancellation>();
    this.changePlanModal = React.createRef<ChangePlan>();
    this.checkoutModal = React.createRef<Checkout>();
  }

  state: ProfileTypes.ProfileSubscriptionStates = {
    subscription: null,
    invoices: null,
    paymentMethods: null,
    user: null,
    selectedPlan: null,
  };

  componentDidMount() {
    this.props.getProfile();
    this.props.getSubscription();
    this.props.getInvoices();
    this.props.getPaymentMethods();
    this.props.getCountries();
    this.props.getPackages();
  }

  format = (value: number) => {
    return new Intl.NumberFormat(navigator.language, {
      maximumSignificantDigits: 3,
    }).format(value);
  };

  loading = () => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  componentDidUpdate(
    prevProps: ProfileTypes.ProfileSubscriptionStates &
      ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.user !== this.props.user &&
      !this.props.user.isLoading &&
      !this.props.user.error &&
      this.props.user.success
    ) {
      this.setState({
        user: this.props.user.data as accountTypes.IUserProfile,
      });
    }

    if (
      prevProps.subscription !== this.props.subscription &&
      !this.props.subscription.isLoading &&
      !this.props.subscription.error &&
      this.props.subscription.success
    ) {
      this.setState({
        subscription: this.props.subscription
          .data as accountTypes.IUserSubscriptionResponse,
      });
    }

    if (
      prevProps.invoices !== this.props.invoices &&
      !this.props.invoices.isLoading &&
      !this.props.invoices.error &&
      this.props.invoices.success
    ) {
      this.setState({
        invoices: this.props.invoices
          .data as accountTypes.IUserInvoices[],
      });
    }

    if (
      prevProps.paymentMethods !== this.props.paymentMethods &&
      !this.props.paymentMethods.isLoading &&
      !this.props.paymentMethods.error &&
      this.props.paymentMethods.success
    ) {
      this.setState({
        paymentMethods: this.props.paymentMethods
          .data as accountTypes.IPaymentMethods[],
      });
    }

    if (
      prevProps.resumedSubscription !==
        this.props.resumedSubscription &&
      !this.props.resumedSubscription.isLoading
    ) {
      this.props.getSubscription();
      this.props.getInvoices();

      alert.fire({
        message: this.props.resumedSubscription.error
          ? (this.props.resumedSubscription.data as unknown as any)
              .message
          : 'Subscription resumed successfully!',
        error: this.props.resumedSubscription.error,
      });
    }

    if (
      prevProps.cancelledSubscription !==
        this.props.cancelledSubscription &&
      !this.props.cancelledSubscription.isLoading
    ) {
      this.props.getSubscription();
      this.props.getInvoices();

      alert.fire({
        message: this.props.cancelledSubscription.error
          ? (this.props.cancelledSubscription.data as unknown as any)
              .message
          : 'Subscription cancelled successfully!',
        error: this.props.cancelledSubscription.error,
      });
    }

    if (
      prevProps.updatedPaymentMethod !==
        this.props.updatedPaymentMethod &&
      !this.props.updatedPaymentMethod.isLoading
    ) {
      this.props.getPaymentMethods();

      alert.fire({
        message: this.props.updatedPaymentMethod.error
          ? (this.props.updatedPaymentMethod.data as unknown as any)
              .message
          : 'Payment method updated successfully!',
        error: this.props.updatedPaymentMethod.error,
      });
    }

    if (
      prevProps.deletedPaymentMethod !==
        this.props.deletedPaymentMethod &&
      !this.props.deletedPaymentMethod.isLoading
    ) {
      this.props.getPaymentMethods();

      alert.fire({
        message: this.props.deletedPaymentMethod.error
          ? (this.props.deletedPaymentMethod.data as unknown as any)
              .message
          : 'Payment method deleted successfully!',
        error: this.props.deletedPaymentMethod.error,
      });
    }

    if (
      prevProps.updatedBillingAddress !==
        this.props.updatedBillingAddress &&
      !this.props.updatedBillingAddress.isLoading
    ) {
      this.props.getProfile();

      alert.fire({
        message: this.props.updatedBillingAddress.error
          ? (this.props.updatedBillingAddress.data as unknown as any)
              .message
          : 'Billing address updated successfully!',
        error: this.props.updatedBillingAddress.error,
      });
    }

    if (
      prevProps.changedSubscription !==
        this.props.changedSubscription &&
      !this.props.changedSubscription.isLoading
    ) {
      this.props.getSubscription();
      this.props.getInvoices();

      alert.fire({
        message: this.props.changedSubscription.error
          ? (this.props.changedSubscription.data as unknown as any)
              .message
          : 'Plan updated successfully!',
        error: this.props.changedSubscription.error,
      });

      setTimeout(() => {
        this.props.getPackages();
      }, 1 * 1000);
    }

    if (
      prevProps.addedPaymentMethod !==
        this.props.addedPaymentMethod &&
      !this.props.addedPaymentMethod.isLoading
    ) {
      this.props.getPaymentMethods();

      alert.fire({
        message: this.props.addedPaymentMethod.error
          ? (this.props.addedPaymentMethod.data as unknown as any)
              .message
          : 'Payment method added successfully!',
        error: this.props.addedPaymentMethod.error,
      });
    }

    if (
      prevProps.createdSubscription !== this.props.createdSubscription
    ) {
      this.checkoutModal.current?.loading(
        this.props.createdSubscription.isLoading
      );

      if (!this.props.createdSubscription.isLoading) {
        this.props.getProfile();
        this.props.getSubscription();
        this.props.getInvoices();
        this.props.getPaymentMethods();
        setTimeout(() => {
          this.props.getPackages();
        }, 1 * 1000);

        this.checkoutModal.current?.hide();

        alert.fire({
          message: this.props.createdSubscription.error
            ? (this.props.createdSubscription.data as unknown as any)
                .message
            : 'Subscribed successfully!',
          error: this.props.createdSubscription.error,
        });
      }
    }
  }

  planDetailDescription = (
    text: string,
    available: boolean,
    limit?: number
  ) => (
    <li
      className={`d-inline-flex subscription-feature ${
        !available && 'unavailable'
      }`}
    >
      {available ? (
        <CheckMark style={{ marginTop: '0.3rem' }} />
      ) : (
        <Cross style={{ marginTop: '0.3rem' }} />
      )}
      &nbsp;
      <span className="subscription-feature feature">
        {(limit ? limit + ' ' : '') + text}
      </span>
    </li>
  );

  render() {
    const { invoices, paymentMethods, user } = this.state;

    const subscription = this.state
      .subscription as unknown as accountTypes.IUserSubscriptionResponse;

    return (
      <>
        {subscription && (
          <SubscriptionCancellation
            title="Cancel Subscription"
            description="If you cancel your current subscription,
            your plan will be downgraded to Free
            package plan and it's features. Your
            current channels will be Disabled and
            you will not receive any notifications
            from your integrations unless you change
            your plan to any paid plan back to
            activate your channels to keep receiving
            notifications. To cancel your subscription please enter your password"
            ref={this.subscriptionCancellationModal}
            onSubmit={(password) =>
              this.props.cancelSubscription({
                subscription: subscription?.id,
                password,
              })
            }
          />
        )}
        {subscription &&
          this.props.packages.data &&
          !this.props.packages.isLoading && (
            <ChangePlan
              ref={this.changePlanModal}
              changePlan={(plan, code) => {
                modal.confirmModal({
                  title: plan,
                  description:
                    'Are you sure you want to ' +
                    plan.split(' ')[0].toLowerCase() +
                    ' your plan?',
                  actionName: plan.split(' ')[0] + ' Plan',
                  color: '',
                  onClick: () => {
                    this.changePlanModal.current?.hide();
                    this.props.changeSubscription({
                      packageCode: code,
                    });
                  },
                });
              }}
              packages={
                this.props.packages
                  .data as subscriptionTypes.ISubscriptionPackages[]
              }
              getStarted={(code) => {
                this.setState({
                  selectedPlan: code,
                });

                this.changePlanModal.current?.hide();
                this.props.getIntent();
                this.checkoutModal.current?.show();
              }}
            />
          )}
        {user &&
          subscription &&
          this.props.countries &&
          this.props.countries.data && (
            <BillingAddress
              title="Billing Address"
              ref={this.billingAddressModal}
              countries={
                this.props.countries.data as authTypes.ICountry[]
              }
              currentAddress={{
                address: user.billing_address?.address,
                country: user.billing_address?.country,
                city: user.billing_address?.city,
                state: user.billing_address?.state,
                zipcode: user.billing_address?.zip_code,
              }}
              onSubmit={({
                address,
                country,
                city,
                state,
                zipcode,
              }) =>
                this.props.updateBillingAddress({
                  address,
                  country,
                  city,
                  state,
                  zipcode,
                })
              }
            />
          )}
        <AddPaymentMethod
          ref={this.addPaymentMethodModal}
          title="Add Payment Method"
          description="Please enter your card details for adding payment method"
          stripe={stripePromise}
          getIntent={() => this.props.getIntent()}
          intent={
            this.props.intent.data
              ? (
                  this.props.intent
                    .data as subscriptionTypes.ISubscriptionIntent
                ).secret
              : ''
          }
          onSubmit={(payment_method) =>
            this.props.addPaymentMethod({ payment_method })
          }
        />
        {this.props.packages.data &&
          !this.props.packages.isLoading &&
          this.props.user.data && (
            <Checkout
              ref={this.checkoutModal}
              title="Checkout"
              stripe={stripePromise}
              getIntent={() => this.props.getIntent()}
              intent={
                (this.props.intent &&
                  (this.props.intent
                    .data as subscriptionTypes.ISubscriptionIntent)) ??
                null
              }
              // @ts-ignore
              selectedPlan={this.props.packages.data.find(
                // @ts-ignore
                (plan) => plan.code === this.state.selectedPlan
              )}
              user={this.props.user.data as accountTypes.IUserProfile}
              editAddress={() =>
                this.billingAddressModal.current?.show()
              }
              pay={(payment_method) => {
                this.props.createSubscription({
                  payment_method,
                  _package: this.state.selectedPlan as string,
                });
                //this.checkoutModal.current?.hide();
              }}
              changePlan={() => {
                this.checkoutModal.current?.hide();
                this.changePlanModal.current?.show();
              }}
            />
          )}

        {!user?.billing_address && !this.props.user.isLoading && (
          <div
            className="alert alert-black d-flex justify-content-center"
            role="alert"
          >
            Please add your billing address before checkout.&nbsp;
            <a
              href="#1"
              onClick={() => {
                this.billingAddressModal.current?.show();
              }}
            >
              Click here
            </a>
            &nbsp;to add your billing address.
          </div>
        )}

        <Helmet>
          <title>Subscription | Beepy</title>
        </Helmet>

        <div className="d-flex flex-column py-2">
          <h4>Subscription</h4>
          <span className="color--beepy-dark-grey">
            Manage your billing and payment details.
          </span>
        </div>
        <div className="divider" />

        {subscription && subscription.package && (
          <div className="subscription-feature">
            <div className="d-flex py-2 justify-content-between">
              <div className="flex-column col-8 col-md-4 me-auto">
                <h6>Plan Details</h6>
                <span className="color--beepy-dark-grey">
                  Check your plan, upgrade or downgrade, cancel or
                  switch billing frequency.
                </span>
              </div>
              <div className="col-8 col-md-6 d-grid gap-3">
                <div className="card w-50 rounded-3">
                  <div className="card-body">
                    <h4 className="fw-bold">
                      {subscription?.package.name}
                    </h4>
                    <p className="subscription-feature description">
                      {subscription?.package.description}
                    </p>
                    <ul className="d-grid gap-2 subscription-feature list">
                      {this.planDetailDescription(
                        'Channel',
                        subscription.package.features.channel_max > 0,
                        subscription.package.features.channel_max
                      )}
                      {this.planDetailDescription(
                        'Members',
                        subscription.package.features
                          .members_max_per_channel > 0,
                        subscription.package.features
                          .members_max_per_channel
                      )}
                      {this.planDetailDescription(
                        'Notifications',
                        subscription.package.features
                          .notification_limit > 0,
                        subscription.package.features
                          .notification_limit
                      )}
                      {this.planDetailDescription(
                        'API Services',
                        subscription.package.features.api_services
                      )}
                      {this.planDetailDescription(
                        'API Rate Limit',
                        subscription.package.features
                          .api_rate_limit_request > 0,
                        subscription.package.features
                          .api_rate_limit_request
                      )}
                      {this.planDetailDescription(
                        'Multiple Device',
                        subscription.package.features.multiple_device
                      )}
                      {this.planDetailDescription(
                        'Multiple Device Limit',
                        subscription.package.features
                          .multiple_device_limit > 0,
                        subscription.package.features
                          .multiple_device_limit
                      )}
                      {this.planDetailDescription(
                        'Whitelist Limit',
                        subscription.package.features
                          .ip_whitelist_limit > 0,
                        subscription.package.features
                          .ip_whitelist_limit
                      )}
                      {this.planDetailDescription(
                        'Webhooks',
                        subscription.package.features.webhooks_limit >
                          0,
                        subscription.package.features.webhooks_limit
                      )}
                    </ul>
                    <span>
                      $
                      <span className="subscription-feature price">
                        {subscription?.package.price}
                      </span>
                      <span className="subscription-feature cycle ms-1">
                        /month
                      </span>
                    </span>
                  </div>
                  <div className="card-footer">
                    <span
                      className="text--primary cursor-pointer"
                      onClick={() =>
                        this.changePlanModal.current?.show()
                      }
                    >
                      <ArrowUpRight className="me-1 mb-1" />
                      Change Plan
                    </span>

                    {subscription.code !== 'FREE' &&
                      (!subscription.end_date ? (
                        <>
                          {/* divider */}
                          <div className="divider" />
                          <span
                            className="text-danger cursor-pointer"
                            onClick={() =>
                              this.subscriptionCancellationModal.current?.show()
                            }
                          >
                            Cancel Subscription
                          </span>
                        </>
                      ) : (
                        <>
                          {/* divider */}
                          <div className="divider" />
                          <span
                            className="text--primary cursor-pointer"
                            onClick={() =>
                              this.props.resumeSubscription(
                                subscription.id
                              )
                            }
                          >
                            Resume Subscription
                          </span>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="divider" />

            <div className="d-flex py-2 justify-content-between">
              <div className="flex-column col-8 col-md-4 me-auto">
                <h6>Payment Method</h6>
                <span className="color--beepy-dark-grey">
                  You can see card information, manage your cards and
                  add new card.
                </span>
              </div>
              <div className="col-8 col-md-6 d-grid gap-3">
                {paymentMethods && paymentMethods.length > 0 ? (
                  paymentMethods.map((card, index) => (
                    <Card
                      key={index}
                      index={index}
                      card={card}
                      onDelete={(id) => {
                        modal.confirmModal({
                          title: 'Delete Payment Method',
                          description:
                            'Are you sure you want to delete this payment method?',
                          actionName: 'Delete',
                          onClick: () => {
                            this.props.deletePaymentMethod(id);
                          },
                          color: 'bg-danger',
                        });
                      }}
                      onSelect={(id) => {
                        modal.confirmModal({
                          title: 'Change Payment Method',
                          description:
                            'Are you sure you want to change your payment method?',
                          actionName: 'Change',
                          onClick: () => {
                            this.props.setAsPrimaryCard(id);
                          },
                          color: 'bg--primary',
                        });
                      }}
                    />
                  ))
                ) : this.props.paymentMethods.isLoading ? (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    {this.loading}
                  </div>
                ) : (
                  <div className="text-bluey-grey">
                    No cards added yet.
                  </div>
                )}
                {subscription && subscription.code !== 'FREE' && (
                  <div className="d-flex cursor-pointer align-items-center justify-content-start">
                    <Plus stroke="gray" />
                    <div
                      className="text-md ms-1 select-none color--beepy-dark-grey"
                      onClick={() => {
                        this.props.getIntent();
                        this.addPaymentMethodModal.current?.show();
                      }}
                    >
                      Add Paymnet Method
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="divider" />

            <div className="d-flex py-2 justify-content-between">
              <div className="flex-column col-8 col-md-4 me-auto">
                <h6>Billing Address</h6>
                <span className="color--beepy-dark-grey">
                  Add your billing address information.
                </span>
              </div>
              <div className="col-8 col-md-6 d-grid gap-3">
                {user?.billing_address ? (
                  <div className="card w-75">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <p className="color--beepy-dark-grey">
                          {user.billing_address.address} <br />{' '}
                          {user.billing_address.city}{' '}
                          {user.billing_address.state} <br />
                          {user.billing_address.country} -{' '}
                          {user.billing_address.zip_code}
                        </p>
                        <button
                          type="button"
                          className="btn btn-outline-secondary h-25"
                          onClick={() =>
                            this.billingAddressModal.current?.show()
                          }
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-bluey-grey">
                    No billing address added yet.
                  </div>
                )}
              </div>
            </div>
            <div className="divider" />

            <div className="d-flex py-2 justify-content-between">
              <div className="flex-column col-8 col-md-4 me-auto">
                <h6>Payment History</h6>
                <span className="color--beepy-dark-grey">
                  You can check your payments and plans.
                </span>
              </div>
              <div className="col-8 col-md-6 d-grid gap-3">
                <table className="table beepy-invoice-table shadow p-3 mb-5 rounded-3 w-75">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Plan</th>
                      <th>Amount</th>
                      <th>Reason</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {invoices && invoices.length > 0 ? (
                      invoices.map((invoice, index) => (
                        <tr key={index}>
                          <td>{invoice.created}</td>
                          <td>{invoice.plan}</td>
                          <td>{invoice.amount}</td>
                          <td>{invoice.reason}</td>
                          <td>{invoice.status}</td>
                          {invoice.pdf && (
                            <td>
                              <a
                                href={invoice.pdf}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Tooltip
                                  title="Download PDF"
                                  placement="bottom"
                                  arrow
                                >
                                  <DownloadCloud />
                                </Tooltip>
                              </a>
                            </td>
                          )}
                        </tr>
                      ))
                    ) : this.props.invoices.isLoading ? (
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        {this.loading}
                      </div>
                    ) : (
                      <div className="p-3">No invoices found.</div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="divider" />
          </div>
        )}
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  countries: state.auth.countries,
  user: state.account.userProfile,

  invoices: state.account.userInvoices,
  paymentMethods: state.account.paymentMethods,
  subscription: state.account.userSubscription,
  packages: state.subscription.packages,

  resumedSubscription: state.account.subscriptionResume,
  cancelledSubscription: state.account.subscriptionCancel,
  changedSubscription: state.account.changeSubscription,

  updatedBillingAddress: state.account.updateBillingAddress,

  addedPaymentMethod: state.account.addPaymentMethod,
  updatedPaymentMethod: state.account.setPrimaryMethod,
  deletedPaymentMethod: state.account.deletePaymentMethod,

  createdSubscription: state.subscription.createSubscription,

  intent: state.subscription.subscriptionIntent,
});

const mapDispatch = {
  getCountries: () => authActions.listCountriesAction(),
  getProfile: () => accountActions.userProfileAction(),

  getSubscription: () => accountActions.userSubscriptionAction(),
  getInvoices: () => accountActions.userInvoicesAction(),
  getPaymentMethods: () => accountActions.paymentMethodsAction(),
  getPackages: () => subscriptionActions.subscriptionPackagesAction(),

  changeSubscription: ({
    packageCode,
  }: accountTypes.IChangeSubscriptionRequest) =>
    accountActions.changeSubscriptionAction({ packageCode }),
  resumeSubscription: (id: string) =>
    accountActions.userSubscriptionResumeAction({ subscription: id }),
  cancelSubscription: ({
    subscription,
    password,
  }: {
    subscription: string;
    password: string;
  }) =>
    accountActions.subscriptionCancelAction({
      subscription,
      password,
    }),

  updateBillingAddress: ({
    address,
    country,
    city,
    state,
    zipcode,
  }: accountTypes.IUpdateBillingAddressRequest) =>
    accountActions.updateBillingAddressAction({
      address,
      country,
      city,
      state,
      zipcode,
    }),

  addPaymentMethod: (
    paymentMethod: accountTypes.IAddPaymentMethodsRequest
  ) => accountActions.addPaymentMethodsAction(paymentMethod),
  setAsPrimaryCard: (id: string) =>
    accountActions.setPrimaryPaymentMethodsAction({
      payment_method: id,
    }),
  deletePaymentMethod: (id: string) =>
    accountActions.deletePaymentMethodsAction({ payment_method: id }),

  createSubscription: ({
    _package,
    payment_method,
  }: subscriptionTypes.ICreateSubscriptionRequest) =>
    subscriptionActions.createSubscriptionAction({
      _package,
      payment_method,
    }),

  getIntent: () => subscriptionActions.subscriptionIntentAction(),
};

const connector = connect(mapState, mapDispatch);
export default connector(ProfileSubscription);
