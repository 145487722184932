import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { connect, ConnectedProps } from 'react-redux';
import zxcvbn from 'zxcvbn';

import { AuthTypes } from '../../@types/pages';
import { authTypes } from '../../@types/reducers';

import { authActions } from '../../actions';
import { RootState } from '../../store';

import AuthLogo from '../../components/Auth/AuthLogo';
import { Elements } from '../../components';
import { alert, router } from '../../helpers';
import AuthBackground from './AuthBackground';

import Modal from '../../components/Modal/Modal';

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  surname: Yup.string().required('Required'),
  email: Yup.string()
    .required('Required')
    .email('Invalid e-mail address!'),
  country: Yup.string().required('Required'),
  password: Yup.string()
    .required('Required')
    .min(8, 'You must enter a minimum of 8 characters.'),
  checkbox: Yup.boolean().oneOf(
    [true],
    'You must accept the terms and conditions.'
  ),
});

class Register extends React.Component<
  AuthTypes.RegisterProps & ConnectedProps<typeof connector>,
  AuthTypes.RegisterStates
> {
  modal: React.RefObject<Modal>;

  state: AuthTypes.RegisterStates = {
    passwordScore: 0,
  };

  constructor(
    props: AuthTypes.RegisterProps & ConnectedProps<typeof connector>
  ) {
    super(props);
    this.modal = React.createRef();
  }
  componentDidMount() {
    this.props.listCountries();
    this.modal.current?.show();
  }

  componentDidUpdate(
    prevProps: AuthTypes.RegisterProps &
      ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.registered !== this.props.registered &&
      !this.props.registered.isLoading
    ) {
      alert.fire({
        message: this.props.registered.error
          ? (this.props.registered.data as unknown as any).message
          : 'Registered successfully!',
        error: this.props.registered.error,
      });

      if (!this.props.registered.error)
        this.props.navigate('/verification');
    }
  }

  onSubmit = (values: any) => {
    values.timezone =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.props.register(values);
  };

  calculatePasswordScore = (password: string) => {
    const result = zxcvbn(password);
    this.setState({ passwordScore: result.score });
  };

  currentYear = new Date().getFullYear();

  render() {
    const { passwordScore } = this.state;

    return (
      <AuthBackground>
        <Helmet>
          <title>Register | Beepy</title>
        </Helmet>
        <Modal
          closable={false}
          modalBodyClass="w-100"
          ref={this.modal}
        >
          <div className="d-flex flex-column align-items-center">
            <div className="mt-8 d-flex align-items-center justify-content-center">
              <AuthLogo color="dark" />
            </div>
            <div className="my-auto mx-auto d-flex flex-column justify-content-center pt-8">
              <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={{
                  name: '',
                  surname: '',
                  email: '',
                  country: '',
                  password: '',
                  checkbox: false,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  this.onSubmit(values);
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <>
                    <form
                      onSubmit={handleSubmit}
                      className="d-flex flex-column"
                    >
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column mt-2 w-50 me-2">
                          <Elements.InputLabel
                            for="name"
                            label="Name*"
                          />
                          <Elements.Input
                            id="name"
                            autoComplete="given-name"
                            type="text"
                            placeholder="Name"
                            onChange={handleChange}
                            value={values.name}
                          />
                          <Elements.FormErrorText
                            error={errors.name}
                          />
                        </div>
                        <div className="d-flex flex-column mt-2 w-50 ms-2">
                          <Elements.InputLabel
                            for="surname"
                            label="Surname*"
                          />
                          <Elements.Input
                            id="surname"
                            type="text"
                            autoComplete="family-name"
                            placeholder="Surname"
                            onChange={handleChange}
                            value={values.surname}
                          />
                          <Elements.FormErrorText
                            error={errors.surname}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column mt-2">
                        <Elements.InputLabel
                          for="email"
                          label="Email*"
                        />
                        <Elements.Input
                          id="email"
                          type="email"
                          placeholder="Enter your email"
                          autoComplete="email"
                          onChange={handleChange}
                          value={values.email}
                        />
                        <Elements.FormErrorText
                          error={errors.email}
                        />
                      </div>
                      <div className="d-flex flex-column mt-2">
                        <Elements.InputLabel
                          for="country"
                          label="Country*"
                        />
                        <select
                          onChange={handleChange}
                          value={values.country}
                          autoComplete="country"
                          id="country"
                          className="beepy-input-border mt-1 w-100 rounded-3 border py-3 px-2"
                        >
                          <option disabled>Choose country</option>
                          {this.props.countries.data &&
                            !this.props.countries.isLoading &&
                            !this.props.countries.error &&
                            (
                              this.props.countries
                                .data as authTypes.ICountry[]
                            ).map((country, index) => {
                              return (
                                <option
                                  key={index}
                                  value={country.code}
                                >
                                  {country.value}
                                </option>
                              );
                            })}
                        </select>
                        <Elements.FormErrorText
                          error={errors.country}
                        />
                      </div>
                      <div className="d-flex flex-column mt-2">
                        <Elements.InputLabel
                          for="password"
                          label="Password*"
                        />
                        <Elements.Input
                          id="password"
                          type="password"
                          autoComplete="new-password"
                          placeholder="Password"
                          onChange={(e) => {
                            this.calculatePasswordScore(
                              e.target.value
                            );
                            handleChange(e);
                          }}
                          value={values.password}
                        />

                        <div className="d-flex mt-3">
                          {[0, 1, 2, 3].map((i) => {
                            return (
                              <div key={i} className="px-2 w-100">
                                <div
                                  style={{
                                    height: '0.25rem',
                                  }}
                                  className={`rounded ${
                                    i < passwordScore
                                      ? passwordScore <= 2
                                        ? 'bg-danger'
                                        : passwordScore <= 3
                                        ? 'bg-warning'
                                        : 'bg-success'
                                      : 'bg-secondary'
                                  }`}
                                ></div>
                              </div>
                            );
                          })}
                        </div>
                        <Elements.FormErrorText
                          error={errors.password}
                        />
                        <p className="text-sm text-bluey-grey mt-2">
                          Use 8 or more characters with a mix of
                          letters, numbers &amp; symbols.
                        </p>
                      </div>
                      <div className="d-flex align-items-start flex-column justify-content-between">
                        <label className="text-slate-blue">
                          <div className="d-flex algin-items-center justify-content-start mb-2">
                            <Field
                              className="ring-0 text--primary"
                              name="checkbox"
                              type="checkbox"
                            />
                            <div className="ms-1">
                              I agree with the
                            </div>
                            <a
                              href="https://beepy.io/terms-conditions"
                              target="_blank"
                              className="text--primary ms-1"
                              rel="noreferrer"
                            >
                              Terms and conditions.
                            </a>
                          </div>
                        </label>
                        <Elements.FormErrorText
                          error={errors.checkbox}
                        />
                      </div>
                      <Elements.Button
                        name="Register"
                        type="submit"
                        loading={this.props.registered.isLoading}
                      />
                    </form>
                  </>
                )}
              </Formik>
              <div className="text-bluey-grey mt-3 text-center text-sm">
                <div className="d-flex align-items-center justify-content-center">
                  Already have an account?
                  <Link to="/login" className="text--primary ms-1">
                    Sign in here.
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </AuthBackground>
    );
  }
}

const mapState = (state: RootState) => ({
  countries: state.auth.countries,
  registered: state.auth.register,
});

const mapDispatch = {
  listCountries: () => authActions.listCountriesAction(),
  register: ({
    name,
    surname,
    email,
    country,
    password,
    timezone,
  }: authTypes.IRegisterRequest) =>
    authActions.registerAction({
      name,
      surname,
      email,
      country,
      password,
      timezone,
    }),
};

const connector = connect(mapState, mapDispatch);
export default connector(router.withRouter(Register));
