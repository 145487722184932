import React from 'react';

import { ReactComponent as UserPlus } from '../../assets/images/icons/user-plus.svg';

import { ReactComponent as LogoDark } from '../../assets/images/logo-dark.svg';
import { ReactComponent as MoreHorizontal } from '../../assets/images/icons/more-horizontal.svg';
import { ReactComponent as Bell } from '../../assets/images/icons/bell.svg';
import { ReactComponent as Plus } from '../../assets/images/icons/plus.svg';
import { ReactComponent as MoreVertical } from '../../assets/images/icons/more-vertical.svg';
import NoImg from '../../assets/images/no-img.png';

class AuthBackground extends React.Component {
  notificationInterval: NodeJS.Timer | null = null;

  componentDidMount() {
    this.notificationInterval = setInterval(() => {
      const notificationList = document.querySelector(
        '.notifications-list'
      );

      if (notificationList) {
        const notification = document.getElementById(
          'example-notification'
        );
        if (notification) {
          const clone = notification.cloneNode(true);
          notificationList.appendChild(clone);
          notificationList.children[0].classList.add('animated');
          notificationList.children[0].classList.add('fadeInLeft');

          setTimeout(() => {
            notificationList.children[0].classList.remove('animated');
            notificationList.children[0].classList.remove(
              'fadeInLeft'
            );
          }, 2900);

          if (notificationList.children.length >= 5) {
            notificationList.children[4].remove();
          }
        }
      }

      const channelList = document.querySelector('.channels-list');
      if (channelList && channelList.childElementCount < 10) {
        const channel = document.getElementById('example-channel');

        if (channel) {
          const clone = channel.cloneNode(true);
          channelList.appendChild(clone);
        }
      }
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.notificationInterval as NodeJS.Timer);
  }

  render() {
    return (
      <div>
        <div
          style={{
            opacity: '65%',
          }}
        >
          <header className="navbar navbar-expand-lg navbar-dark bd-navbar sticky-top d-md-block d-lg-none">
            <nav
              className="container-xxl bd-gutter flex-wrap flex-lg-nowrap d-lg-none"
              aria-label="Main navigation"
            >
              <button
                className="navbar-toggler p-2"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#bdSidebar"
                aria-controls="bdSidebar"
                aria-expanded="false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  className="bi"
                  fill="#667085"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                  ></path>
                </svg>
                <span className="d-none fs-6 pe-1">Browse</span>
              </button>
              <a
                className="navbar-brand p-0 me-0 me-lg-2"
                href="/"
                aria-label="Bootstrap"
              >
                <LogoDark />
              </a>
              <div className="d-flex align-items-center justify-content-end dropdown">
                <a
                  className="navbar-toggler p-2"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#beSidebar"
                  aria-controls="beSidebar"
                  aria-expanded="false"
                  href="#1"
                >
                  <MoreHorizontal />
                </a>
                <div
                  className="offcanvas-lg offcanvas-end"
                  tabIndex={-1}
                  id="beSidebar"
                  aria-labelledby="beSidebarLabel"
                >
                  <div className="offcanvas-header px-4 pb-0">
                    <a
                      className="navbar-brand p-0 me-0 me-lg-2"
                      href="/"
                      aria-label="Bootstrap"
                    >
                      <LogoDark />
                    </a>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      data-bs-target="#beSidebar"
                    />
                  </div>
                </div>
              </div>
            </nav>
          </header>
          <div className="main container-fluid  d-flex flex-nowrap h-100 ">
            <div
              className="sidebar d-flex flex-column flex-shrink-0 offcanvas-lg offcanvas-start"
              tabIndex={-1}
              id="bdSidebar"
              aria-labelledby="bdSidebarLabel"
            >
              <div className="offcanvas-body">
                <div className="top d-flex align-items-center">
                  <a
                    href="index.html"
                    className="d-flex align-items-center mb-3 mb-md-0 me-md-auto  text-decoration-none"
                  >
                    <LogoDark />
                  </a>
                  <div className="sidebar-buttons">
                    <a href="#1" className="icon position-relative">
                      <UserPlus />
                      <span className="position-absolute top-1 start-100 translate-middle badge beepy-light ">
                        2
                      </span>
                    </a>
                    <a href="#1" className="icon position-relative">
                      <Bell />
                      <span className="position-absolute top-1 start-100 translate-middle badge beepy-light ">
                        2
                      </span>
                    </a>
                  </div>
                  <button
                    type="button"
                    className="btn-close ms-auto d-lg-none"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    data-bs-target="#bdSidebar"
                  />
                </div>
                <div className="sidebar-element">
                  <a href="#1" className="create-channel-link">
                    <span className="icon">
                      <Plus />
                    </span>
                    <span className="text">Create Channel</span>
                  </a>
                </div>
                <div className="divider" />
                <ul className="channels-list nav nav-pills d-grid mb-auto">
                  <li className="channel-item active">
                    <a
                      href="#1"
                      className="nav-link"
                      aria-current="page"
                    >
                      <div className="d-flex">
                        <div className="logo">
                          <img src={NoImg} alt="Channel Logo" />
                        </div>
                        <div className="title">
                          My Personal Channel
                          <span className="subtitle">
                            @channel_slug
                          </span>
                        </div>
                      </div>
                      <div className="badge beepy-light">2</div>
                    </a>
                    <div className="divider" />
                  </li>
                  <li
                    id="example-channel"
                    className="channel-item animated fadeInLeft"
                  >
                    <a
                      href="#1"
                      className="nav-link"
                      aria-current="page"
                    >
                      <div className="d-flex">
                        <div className="logo">
                          <img src={NoImg} alt="Channel Logo" />
                        </div>
                        <div className="title">
                          My Personal Channel
                          <span className="subtitle">
                            @channel_slug
                          </span>
                        </div>
                      </div>
                      <div className="badge beepy-light">2</div>
                    </a>
                    <div className="divider" />
                  </li>
                </ul>
                <div className="divider" />
                <div className="profile dropdown">
                  <a
                    href="#1"
                    className="d-flex align-items-center justify-content-between   text-decoration-none dropdown-toggle"
                    id="dropdownUser1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="profile-info d-flex">
                      <div className="initals">BI</div>
                      <div className="details d-grid">
                        <span className="name">Beepy.io</span>
                        <span className="email">info@beepy.io</span>
                      </div>
                    </div>
                    <span className="icon">
                      <MoreVertical />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="container-fluid overflow-hidden d-flex  justify-content-center">
              <div className="content w-100">
                <div className="channel-header">
                  <div className="row">
                    <div className="col-8 col-lg-7 d-flex">
                      <div className="channel-logo d-flex align-items-center justify-content-center">
                        <img src={NoImg} alt="Channel Logo" />
                      </div>
                      <div className="channel-title d-grid">
                        My Personal Channel
                        <span className="channel-slug">
                          @my_personal_channel
                        </span>
                      </div>
                    </div>
                    <div className="col-4 col-lg-5 d-flex align-items-center justify-content-end dropdown d-none d-lg-flex">
                      <a className="btn header-button" href="#1">
                        Add Member
                      </a>
                      <a
                        className="btn header-button ms-3"
                        id="channelMoreDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        href="#1"
                      >
                        <MoreHorizontal />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="divider" />
                <div
                  className="channel-body p-3"
                  style={{ overflow: 'hidden' }}
                >
                  <ul className="notifications-list">
                    <li
                      id="example-notification"
                      className="col-md-8 col-xs-12"
                    >
                      <div className="content">
                        <div className="title text-start">
                          Lorem ipsum dolor sit amet, consectetur
                          adipiscing elit. Maecenas ut ipsum tellus.
                          Pellentesque bibendum orci non dapibus
                          mollis.Lorem ipsum dolor sit amet,
                          consectetur adipiscing elit. Maecenas ut
                          ipsum tellus. Pellentesque bibendum orci non
                          dapibus mollis.
                        </div>
                        <div className="description">
                          Lorem ipsum dolor sit amet, consectetur
                          adipiscing elit. Maecenas ut ipsum tellus.
                          Pellentesque bibendum orci non dapibus
                          mollis. Curabitur rutrum accumsan enim, ac
                          maximus nulla pharetra id. Interdum et
                          malesuada fames ac ante ipsum primis in
                          faucibus. Ut ullamcorper ipsum gravida eros
                          efficitur rutrum. Nulla eu pharetra felis.
                          Quisque orci lacus, venenatis eu dapibus at,
                          tempor id augue. Mauris quis sodales nulla.
                          Vestibulum fermentum nibh in est suscipit,
                          nec pharetra sem cursus. Show more
                        </div>
                        <span className="show-more">Show More</span>
                        <div className="bottom justify-content-between">
                          <span
                            className="tag"
                            style={{ backgroundColor: '#6dd400' }}
                          >
                            Success
                          </span>
                          <span className="date">
                            27/11/2021 10:02 pm
                          </span>
                        </div>
                      </div>
                      <div className="footer">
                        <div className="data">
                          Channel Webhooks: AWS CloudWatch
                          Api:*****1234 IP Address : 192.168.1.1
                        </div>
                        <div className="actions">
                          <a href="#1" className="light">
                            Report
                          </a>
                          <a href="#1" className="red">
                            Delete
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}
export default AuthBackground;
