import React from 'react';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { Helmet } from 'react-helmet-async';
import Dropzone from 'react-dropzone';

import { Elements } from '../../components';
import {
  CreateChannelProps,
  CreateChannelStates,
} from '../../@types/pages/Channels';
import { connect, ConnectedProps } from 'react-redux';
import { alert, router } from '../../helpers';
import { RootState } from '../../store';
import { authActions, channelActions } from '../../actions';
import { channelTypes } from '../../@types/reducers';
import { ICategories } from '../../@types/reducers/auth';

import noImages from '../../assets/no-images.png';
import { ReactComponent as UploadImageLogo } from '../../assets/uploadLogo.svg';

import { ReactComponent as Public } from '../../assets/images/icons/public.svg';
import { ReactComponent as Private } from '../../assets/images/icons/private.svg';
import BlackCheck from '../../assets/images/icons/black-check.svg';

const validationSchemaStepTwo = Yup.object({
  title: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

class CreateChannel extends React.Component<
  CreateChannelProps & ConnectedProps<typeof connector>,
  CreateChannelStates
> {
  formik: React.RefObject<
    FormikProps<{ title: string; description: string }>
  >;

  constructor(
    props: CreateChannelProps & ConnectedProps<typeof connector>
  ) {
    super(props);
    this.formik = React.createRef<
      FormikProps<{
        title: string;
        description: string;
      }>
    >();
  }

  state: CreateChannelStates = {
    stepPage: 0,
    categories: [],
    selectedCategory: null,
    title: '',
    description: '',
    channelType: 'Private',
    channelCategory: 'ALERT',
    files: [],
  };

  pageSteps = [
    {
      title: 'Channel Type',
      description: 'Setup Your Channel Type',
      stepTitle: 'Choose Channel Type',
      stepDescription:
        'Please choose one of the best channel types below for you.',
    },
    {
      title: 'Channel Details',
      description: 'Setup Your Channel Details',
      stepTitle: 'Channel Details',
      stepDescription: 'Create your channel title and description.',
    },
    {
      title: 'Channel Category',
      description: 'Your Business Related Info',
      stepTitle: 'Channel Category',
      stepDescription:
        'Please choose one of the best categories below for you.',
    },
    {
      title: 'Channel Logo',
      description: 'Set Your Channel Logo',
      stepTitle: 'Channel Logo',
      stepDescription: 'Create your channel logo.',
    },
    {
      title: 'Preview',
      description: 'Check before you go',
      stepTitle: 'Almost Done!',
      stepDescription:
        'Please check your channel details before create!',
    },
  ];

  componentDidMount() {
    this.props.getCategories();
  }

  componentDidUpdate(
    prevProps: CreateChannelProps & ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.categories !== this.props.categories &&
      !this.props.categories.isLoading
    ) {
      this.setState({
        categories: this.props.categories.data as ICategories[],
      });
    }
    if (
      prevProps.uploadedLogo !== this.props.uploadedLogo &&
      !this.props.uploadedLogo.isLoading &&
      (this.props.uploadedLogo.success ||
        this.props.uploadedLogo.error)
    ) {
      this.setState({
        title: '',
        description: '',
        channelType: null,
        selectedCategory: null,
        files: [],
        createdChannel: '',
      });

      this.props.navigate(
        '/channels/' + this.state.createdChannel + '/notifications'
      );
    }
    if (
      prevProps.createdChannel !== this.props.createdChannel &&
      !this.props.createdChannel.isLoading
    ) {
      let data: channelTypes.ICreateChannel = this.props
        .createdChannel.data as channelTypes.ICreateChannel;
      alert.fire({
        message: this.props.createdChannel.error
          ? (this.props.createdChannel.data as unknown as any).message
          : 'The channel has just been created successfully',
        error: this.props.createdChannel.error,
      });
      if (
        !this.props.createdChannel.error &&
        this.props.createdChannel.data &&
        this.props.createdChannel.success
      ) {
        if (!this.state.files && !this.state.files[0]) {
          this.props.navigate(
            '/channels/' + data.channel + '/notifications'
          );
        } else {
          this.setState({
            createdChannel: data.channel,
          });

          this.props.uploadLogo({
            logo: this.state.files[0],
            channel: data.channel,
          });
        }
      } else {
        this.props.navigate('/channels');
      }
    }
  }

  stepDescription = (title: string, description: string) => (
    <>
      <div className="select-none">
        <div className="text-dark-grey-blue text-3xl font-medium">
          {title ?? ''}
        </div>
        <div className="text-bluey-grey py-2 opacity-90">
          {description ?? ''}
        </div>
      </div>
    </>
  );

  allSubmit = () => {
    this.props.createChannel({
      title: this.state.title,
      description: this.state.description,
      type: this.state.channelType as 'Private' | 'Public',
      slug: '',
      category: this.state.channelCategory as string,
    });
  };
  radioCheck = (index: string, _category: ICategories) => {
    this.setState({
      selectedCategory: _category,
      channelCategory: index,
    });
  };

  stepTwoOnSubmit = (values: {
    title: string;
    description: string;
  }) => {
    this.setState({
      title: values.title,
      description: values.description,
    });
    this.setState({ stepPage: this.state.stepPage + 1 });
  };

  render() {
    const { files } = this.state;
    return (
      <>
        <Helmet>
          <title>Create Channel | Beepy</title>
        </Helmet>
        <div className="content w-100 h-100">
          <div className="d-flex flex-column align-items-center">
            <div
              style={{
                width: '75%',
                padding: '4rem',
              }}
            >
              <div className="stepwizard">
                <div className="stepwizard-row stepwizard-row-bar">
                  {this.pageSteps.map((step, key) => {
                    return (
                      <div key={key} className="stepwizard-step">
                        <button
                          style={
                            this.state.stepPage > key
                              ? {
                                  width: '1.5rem',
                                  height: '1.25rem',
                                  border: 'none',
                                  backgroundImage: `url(${BlackCheck})`,
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center',
                                  backgroundSize: 'contain',
                                  backgroundColor: 'transparent',
                                }
                              : this.state.stepPage < key
                              ? {
                                  width: '0.5rem',
                                  height: '1.25rem',
                                  borderWidth: '0.25rem',
                                  borderColor: 'black',
                                  borderRadius: '50%',
                                  backgroundColor: 'white',
                                  opacity: '0.5',
                                }
                              : {
                                  width: '0.5rem',
                                  height: '1.25rem',
                                  borderWidth: '0.25rem',
                                  borderColor: 'black',
                                  borderRadius: '50%',
                                  backgroundColor: 'white',
                                }
                          }
                        />
                        <p>
                          <small className="beepy-subtitle-color">
                            {step.title}
                          </small>
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div
              className="row w-75 mx-auto"
              style={{
                lineHeight: '20px',
              }}
            >
              <h3>{this.pageSteps[this.state.stepPage].stepTitle}</h3>
              <p>
                {this.pageSteps[this.state.stepPage].stepDescription}
              </p>

              {this.state.stepPage === 0 && (
                <div className="d-flex col">
                  <div className="card mt-2 me-2 bg--beepy-dark rounded-3 border w-50">
                    <div className="card-body d-flex text-white">
                      <div className="align-items-start justify-content-start">
                        <Private />
                      </div>
                      <div className="ms-3">
                        <p className="fw-bold">Private Channel</p>
                        <p>
                          Private channels are used for special
                          projects
                        </p>
                      </div>
                      <div className="ms-5 align-items-start justify-content-end">
                        <input
                          type="radio"
                          className="text--primary rounded check"
                          checked={true}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="card mt-2 ms-2 rounded-3 border cursor-not-allowed w-50">
                    <div className="card-body d-flex">
                      <div className="align-items-start justfiy-content-end">
                        <Public />
                      </div>
                      <div className="ms-3">
                        <p className="beepy-subtitle-color">
                          Public Channel
                        </p>
                        <p className="color--beepy-grey">
                          Public channels for everyone to share
                          information
                        </p>
                      </div>
                      <div className="ms-5 align-items-start justify-content-end">
                        <input
                          type="radio"
                          className="text--primary rounded check"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {this.state.stepPage === 1 && (
                <Formik
                  innerRef={this.formik}
                  validateOnBlur={false}
                  validateOnChange={false}
                  initialValues={{
                    title: this.state.title,
                    description: this.state.description,
                  }}
                  validationSchema={validationSchemaStepTwo}
                  onSubmit={(values) => {
                    this.stepTwoOnSubmit(values);
                  }}
                >
                  {({ handleChange, values, errors }) => (
                    <form>
                      <div className="d-flex flex-column mb-4">
                        <Elements.InputLabel
                          label="Channel Title"
                          for="channeltitle"
                        />
                        <Elements.Input
                          id="title"
                          value={values.title}
                          onChange={handleChange}
                          type="text"
                          placeholder="e.g. New channel"
                        />
                        <Elements.FormErrorText
                          error={errors.title}
                        />
                      </div>
                      <div className="d-flex flex-column mb-4">
                        <Elements.InputLabel
                          for="description"
                          label="Description"
                        />
                        <Elements.Textarea
                          value={values.description}
                          id="description"
                          onChange={handleChange}
                          rows={6}
                          maxLength={275}
                          placeholder="e.g. This is my new Beepy channel"
                        />
                        <Elements.FormErrorText
                          error={errors.description}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              )}

              {this.state.stepPage === 2 && (
                <div
                  className="d-flex flex-column channelCategories"
                  /* NOT WORKING */
                >
                  {this.state.categories &&
                    Object.entries(this.state.categories).map(
                      ([index, _category]) => (
                        <div
                          key={index}
                          onClick={() =>
                            this.radioCheck(index, _category)
                          }
                          className="card mt-2 rounded-3 border cursor-pointer"
                        >
                          <div
                            className={`${
                              this.state.channelCategory === index &&
                              'bg--beepy-dark text-white border rounded-3'
                            } d-flex justify-content-between py-2 px-4 align-items-center justify-content-center categoryElement`}
                          >
                            <div className="d-flex flex-column">
                              <div className="font-medium text-dark-grey-blue">
                                {_category.title}
                              </div>
                              <div className="text-bluey-grey">
                                {_category.description}
                              </div>
                            </div>
                            <input
                              name="category"
                              value={index}
                              checked={
                                this.state.channelCategory === index
                              }
                              type="radio"
                              readOnly={true}
                              className="check"
                            />
                          </div>
                        </div>
                      )
                    )}
                </div>
              )}

              {this.state.stepPage === 3 && (
                <div className="d-flex flex-column card justify-content-center align-items-center p-4">
                  {files && files.length === 0 ? (
                    <Dropzone
                      accept="image/jpg, image/jpeg, image/png"
                      multiple={false}
                      onDrop={(acceptedFiles) => {
                        this.setState({
                          files: acceptedFiles.map((file) =>
                            Object.assign(file, {
                              preview: URL.createObjectURL(file),
                            })
                          ),
                        });
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({ className: 'dropzone' })}
                          className="d-flex flex-column align-items-center justify-content-center p-3 text-center"
                        >
                          <input {...getInputProps()} />
                          <div
                            className="w-25 border rounded-circle bg-gray-100 p-2"
                            style={{
                              backgroundColor: '#eaecf0',
                            }}
                          >
                            <div className="border rounded-circle bg-gray-200 p-2">
                              <UploadImageLogo />
                            </div>
                          </div>
                          <div className="font-medium color--beepy-dark-grey pt-2">
                            <div className="text-sm">
                              <span className="select-none text-sm color--beepy-dark">
                                Click to upload
                              </span>{' '}
                              or drag and drop
                            </div>
                            <div className="text-xs">
                              JPG, JPEG, PNG allowed (Maximum:
                              512x512, Minimum: 128x128)
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  ) : (
                    <>
                      {files.map((file, key) => (
                        <div
                          className="rounded-3 border gray-400 justify-content-center align-items-center"
                          key={key}
                        >
                          <img
                            className="rounded-3"
                            src={file.preview}
                            alt={''}
                            style={{
                              width: '128px',
                              height: '128px',
                            }}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}

              {this.state.stepPage === 4 && (
                <div className="justify-content-center">
                  <div className="d-flex p-4">
                    {/* the picture will come */}
                    {files.length > 0 ? (
                      files.map((file, key) => (
                        <img
                          className="rounded"
                          src={file.preview}
                          alt={''}
                          key={key}
                          style={{
                            width: '64px',
                            height: '64px',
                          }}
                        />
                      ))
                    ) : (
                      <img
                        className="rounded"
                        src={noImages}
                        alt=""
                        style={{
                          width: '64px',
                          height: '64px',
                        }}
                      />
                    )}

                    <div className="ms-2">
                      <span
                        className="fw-semibold"
                        style={{
                          color: '#353e54',
                        }}
                      >
                        {this.state.title
                          ? this.state.title
                          : 'Title'}
                      </span>
                      <span
                        className="badge rounded-pill bg--beepy-light-green color--beepy-dark-green"
                        style={{ marginLeft: '0.5rem' }}
                      >
                        Active
                      </span>
                      <p
                        style={{
                          color: '#8290b0',
                        }}
                      >
                        <small>
                          {this.state.description
                            ? this.state.description
                            : 'Description'}
                        </small>
                      </p>
                    </div>
                  </div>

                  <div
                    className="d-flex align-items-center h-50 p-4"
                    style={{
                      backgroundColor: '#eaecf0',
                    }}
                  >
                    <div
                      className="d-flex flex-column me-4"
                      style={{
                        color: '#415e81',
                      }}
                    >
                      <div className="text-sm font-medium mb-2">
                        Type
                      </div>
                      <div className="fw-semibold uppercase text-black/60">
                        {this.state.channelType}
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column ms-4"
                      style={{
                        color: '#415e81',
                      }}
                    >
                      <div className="text-sm font-medium mb-2">
                        Category
                      </div>
                      <div className="fw-semibold uppercase text-black/60">
                        {this.state.channelCategory}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-between mt-3">
                <Elements.Button
                  name="Back"
                  type="button"
                  onClick={() => {
                    this.setState({
                      stepPage: this.state.stepPage - 1,
                    });
                  }}
                  hidden={this.state.stepPage === 0}
                  className="btn btn-md px-5 py-2 border rounded-2 bg--beepy-secondary-button me-auto fw-semibold color--beepy-dark"
                >
                  Back
                </Elements.Button>
                <Elements.Button
                  name="Next"
                  type="button"
                  className="btn btn-md px-5 py-2 border rounded-2 bg--beepy-dark ms-auto text-white fw-semibold"
                  loading={
                    this.props.createdChannel.isLoading ||
                    this.props.uploadedLogo.isLoading
                  }
                  onClick={() => {
                    if (this.state.stepPage === 1) {
                      return this.formik.current?.handleSubmit();
                    }

                    if (this.state.stepPage === 4) {
                      return this.allSubmit();
                    }

                    this.setState({
                      stepPage: this.state.stepPage + 1,
                    });
                  }}
                >
                  Next
                </Elements.Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  categories: state.auth.categories,
  createdChannel: state.channel.createChannel,
  uploadedLogo: state.channel.uploadLogo,
});

const mapDispatch = {
  getCategories: () => authActions.listCategoriesAction(),
  createChannel: ({
    title,
    description,
    type,
    slug,
    category,
  }: channelTypes.ICreateChannelRequest) =>
    channelActions.createChannelAction({
      title,
      description,
      type,
      slug,
      category,
    }),
  uploadLogo: ({ channel, logo }: channelTypes.IUploadLogoRequest) =>
    channelActions.uploadLogoAction({ channel, logo }),
};

const connector = connect(mapState, mapDispatch);

export default connector(router.withRouter(CreateChannel));
