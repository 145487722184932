import React from 'react';

import {
  SelectProps,
  SelectStates,
} from '../../@types/components/Elements/Select';
class Select extends React.Component<SelectProps, SelectStates> {
  render() {
    return (
      <>
        <select
          id={this.props.id}
          onChange={this.props.onChange}
          value={this.props.value}
          placeholder={this.props.placeholder}
          autoComplete={this.props.autoComplete}
          className="beepy-input-border color--beepy-dark-grey mt-1 w-100 rounded-3 border py-3 px-2"
        >
          {this.props.children}
        </select>
      </>
    );
  }
}
export default Select;
