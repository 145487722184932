import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { ChannelSettingsTypes } from '../../@types/pages';
import { channelTypes } from '../../@types/reducers';

import { channelActions } from '../../actions';
import { alert, router } from '../../helpers';
import { RootState } from '../../store';

class ChannelSettings extends React.Component<
  ChannelSettingsTypes.ChannelSettingsProps &
    ConnectedProps<typeof connector>,
  ChannelSettingsTypes.ChannelSettingsStates
> {
  state: ChannelSettingsTypes.ChannelSettingsStates = {
    channelSettings: null,
    muteable: false,
    invitations_notify: false,
  };

  setMuteable = () => {
    if (this.state.channelSettings) {
      this.setState({
        channelSettings: {
          ...this.state.channelSettings,
          muteable: !this.state.channelSettings.muteable,
        },
      });
      let val = !this.state.channelSettings.muteable;
      this.props.updateChannel({
        channel: this.props.params.id,
        muteable: val,
        display_logo: null,
        invitations_notify:
          this.state.channelSettings.invitations_notify,
      });
    }
  };

  setInviteNotifications = () => {
    if (this.state.channelSettings) {
      this.setState({
        channelSettings: {
          ...this.state.channelSettings,
          invitations_notify:
            !this.state.channelSettings.invitations_notify,
        },
      });

      let value = !this.state.channelSettings.invitations_notify;
      this.props.updateChannel({
        channel: this.props.params.id,
        muteable: this.state.channelSettings.muteable,
        display_logo: null,
        invitations_notify: value,
      });
    }
  };

  componentDidMount() {
    this.props.getChannelSettings({ channel: this.props.params.id });
  }

  componentDidUpdate(
    prevProps: ChannelSettingsTypes.ChannelSettingsProps &
      ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.channelSettings !== this.props.channelSettings &&
      !this.props.channelSettings.isLoading &&
      !this.props.channelSettings.error &&
      this.props.channelSettings.success &&
      this.props.channelSettings.data
    ) {
      this.setState({
        channelSettings: this.props.channelSettings
          .data as channelTypes.IChannelSettings,
      });
    } else if (
      prevProps.updateChannelState !==
        this.props.updateChannelState &&
      !this.props.updateChannelState.isLoading
    ) {
      alert.fire({
        message: this.props.updateChannelState.error
          ? (this.props.updateChannelState.data as unknown as any)
              .message
          : 'The channel settings has just ben updated successfully!',
        error: this.props.updateChannelState.error,
      });

      this.props.getChannelSettings({
        channel: this.props.params.id,
      });
    }
  }

  render() {
    if (!this.state.channelSettings) return null;
    const { muteable, invitations_notify } =
      this.state.channelSettings;

    return (
      <>
        <div className="d-flex flex-column py-2">
          <h4>Channel Settings</h4>
          <span className="color--beepy-dark-grey">
            To update channel settings.
          </span>
        </div>
        <div className="divider" />

        <div className="d-sm-flex py-2 align-items-center">
          <div className="flex-column col-12 col-md-3 me-auto">
            <h6>Mutable</h6>
            <span className="color--beepy-dark-grey">
              You can allow or disallow your channel to be muted by
              channel members.
            </span>
          </div>
          <div className="d-flex col-12 col-md-4 pt-3">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={muteable ?? false}
                readOnly={true}
                onClick={this.setMuteable}
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckDefault"
              >
                {muteable ? 'Enabled' : 'Disabled'}
              </label>
            </div>
          </div>
        </div>
        <div className="divider" />

        <div className="d-sm-flex py-2 align-items-center">
          <div className="flex-column col-12 col-md-3 me-auto">
            <h6>Invitation Action</h6>
            <span className="color--beepy-dark-grey">
              We will notify you when people take accept/reject action
              for your channel invitations.
            </span>
          </div>
          <div className="d-flex col-12 col-md-4 pt-3">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                readOnly={true}
                checked={invitations_notify ?? false}
                onClick={this.setInviteNotifications}
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckDefault"
              >
                {invitations_notify ? 'Enabled' : 'Disabled'}
              </label>
            </div>
          </div>
        </div>
        <div className="divider" />
      </>
    );
  }
}
const mapState = (state: RootState) => ({
  channelSettings: state.channel.channelSettings,
  updateChannelState: state.channel.channelSettingsUpdate,
});
const mapDispatch = {
  getChannelSettings: ({
    channel,
  }: channelTypes.IChannelSettingsRequest) =>
    channelActions.channelSettingsAction({ channel }),
  updateChannel: ({
    channel,
    muteable,
    display_logo,
    invitations_notify,
  }: channelTypes.IChannelSettingsUpdateRequest) =>
    channelActions.channelSettingsUpdateAction({
      channel,
      muteable,
      display_logo,
      invitations_notify,
    }),
};
const connector = connect(mapState, mapDispatch);
export default connector(router.withParams(ChannelSettings));
