import React from 'react';

import { Modal as ModalType } from '../../@types/components';

import Modal from '../../components/Modal/Modal';

import poweredByStripe from '../../assets/powered_by_stripe.svg';
import { ReactComponent as ArrowUpRight } from '../../assets/arrow-up-right.svg';

import {
  CardElement,
  Elements as PaymentElements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { Elements } from '..';
import { accountTypes } from '../../@types/reducers';
import { alert, payment } from '../../helpers';
import { Stripe, StripeElements } from '@stripe/stripe-js';

const cardStyle = {
  style: {
    base: {
      fontSmoothing: 'antialiased',
      fontSize: '16px',
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const getCurrencySymbol = (currency: string) =>
  (0)
    .toLocaleString(navigator.language, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

class Checkout extends React.Component<
  ModalType.CheckoutProps,
  ModalType.CheckoutStates
> {
  modal: React.RefObject<Modal>;

  constructor(props: ModalType.CheckoutProps) {
    super(props);
    this.modal = React.createRef();
  }

  state: ModalType.CheckoutStates = {
    termsAccepted: false,
    stripe: null,
    elements: null,
    currentCard: null,
    loading: false,
  };

  show = () => this.modal.current?.show();
  hide = () => this.modal.current?.hide();
  loading = (loading: boolean) => this.setState({ loading });

  setStripe = (
    elements: StripeElements | null,
    stripe: Stripe | null
  ) => {
    if (this.state.stripe !== null && this.state.elements !== null)
      return;
    if (!elements || !stripe) return;

    this.setState({ elements, stripe });
  };

  pay = async (id: string) => {
    this.props.pay(id);
  };

  stripePay = async () => {
    if (!this.state.stripe || !this.state.elements) {
      this.loading(false);
      return this.hide();
    }

    // @ts-ignore
    const cardElement = this.state.elements.getElement(
      CardElement
      // @ts-ignore
    ) as CardElement;

    const { error, paymentMethod } =
      await this.state.stripe?.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

    if (error) {
      this.loading(false);
      return alert.fire({
        message:
          error.message ??
          'An error occurred while processing your payment.',
        error: true,
      });
    }

    if (paymentMethod) this.pay(paymentMethod.id);
  };

  handleChangeCard = (id: string) => {
    if (id === this.state.currentCard)
      return this.setState({ currentCard: null });

    this.setState({
      currentCard: id,
    });
  };

  isCardBlank() {
    return document.querySelector('.StripeElement--empty')
      ? true
      : false;
  }

  clearCard() {
    if (this.state.elements) {
      // @ts-ignore
      this.state.elements.getElement(CardElement).clear();
    }
  }

  render() {
    const { selectedPlan } = this.props;

    return (
      <Modal
        ref={this.modal}
        onClose={this.props.onClose}
        modalBodyClass="w-100 modal-md modal-dialog-scrollable"
        title={this.props.title}
        description={this.props.description}
        closable={!this.state.loading}
      >
        {selectedPlan && (
          <div>
            {!this.props.user?.billing_address && (
              <div className="alert alert-black" role="alert">
                Please add your billing address before checkout.&nbsp;
                <a
                  href="#1"
                  onClick={() => {
                    this.props.editAddress();
                  }}
                >
                  Click here
                </a>
                &nbsp;to add your billing address.
              </div>
            )}
            <div className="d-flex">
              <span className="color--beepy-dark-grey">
                Your current plan is{' '}
                <span className="fw-bold">
                  {this.props.user?.subscription[0] +
                    '' +
                    this.props.user?.subscription
                      .toLowerCase()
                      .substring(1)}
                </span>
              </span>
            </div>
            <div
              className="d-flex flex-column mt-3"
              style={{ marginBottom: '0rem!important' }}
            >
              <p className="beepy-title-color">Order Summary</p>
              <p className="color--beepy-dark-grey">
                Update your photo and personal details here.
              </p>
              <div className="divider"></div>
              <div className="mt-3 d-flex p-3 border rounded-3 card bg--beepy-dark">
                <span className="text-white">
                  <span className="fw-bold">
                    {selectedPlan?.name}
                  </span>{' '}
                  <span>
                    {getCurrencySymbol(
                      selectedPlan?.currency as string
                    )}
                    {selectedPlan?.price}/{selectedPlan?.billing_type}
                  </span>
                  <p>{selectedPlan?.description}</p>
                </span>
              </div>
              <span
                className="mt-3 text--primary"
                onClick={() => this.props.changePlan()}
              >
                Change Plan
                <ArrowUpRight className="ms-1 mb-1" />
              </span>
              <div
                className="mt-3 d-flex p-3 border rounded-3 card"
                style={{ backgroundColor: '#f8f8fa' }}
              >
                <span>
                  beepy.io Subscription {selectedPlan.name} (
                  {selectedPlan.billing_type[0].toUpperCase() +
                    selectedPlan.billing_type.slice(1)}{' '}
                  plan) Billed {selectedPlan.billing_type} at{' '}
                  {getCurrencySymbol(selectedPlan.currency)}
                  {selectedPlan.price} + local tax
                </span>
              </div>
              <p className="mt-3 beepy-subtitle-color">
                Your subscription will automatically renew each month
                at the price below.
              </p>
              <div className="d-flex mt-3">
                <span className="color--beepy-dark me-auto">
                  <p>Price</p>
                  <p>Tax</p>
                  <span className="beepy-subtitle-color fw-bold">
                    Total
                  </span>
                </span>

                <span className="color--beepy-dark ms-auto">
                  <p>
                    {getCurrencySymbol(
                      selectedPlan?.currency as string
                    )}
                    {selectedPlan?.price}/{selectedPlan?.billing_type}
                  </p>
                  <p>
                    {getCurrencySymbol(
                      selectedPlan?.currency as string
                    )}
                    0.00/{selectedPlan?.billing_type}
                  </p>
                  <span className="beepy-subtitle-color fw-bold">
                    {' '}
                    {getCurrencySymbol(
                      selectedPlan?.currency as string
                    )}
                    {selectedPlan?.price}/{selectedPlan?.billing_type}
                  </span>
                </span>
              </div>
            </div>
            <div
              className="d-flex flex-column mt-3"
              style={{ marginBottom: '0px!important' }}
            >
              <div
                className="mt-3 d-flex p-3 border rounded-3 card"
                style={{
                  backgroundColor: '#f7f7f8',
                }}
              >
                <span>Billing address</span>
              </div>
              {this.props.user?.billing_address ? (
                <div className="card mt-2">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <p className="text-[#8290b0]">
                        {this.props.user?.billing_address.address}{' '}
                        <br /> {this.props.user?.billing_address.city}{' '}
                        {this.props.user?.billing_address.state}{' '}
                        <br />
                        {
                          this.props.user?.billing_address.country
                        } -{' '}
                        {this.props.user?.billing_address.zip_code}
                      </p>
                      <button
                        type="button"
                        className="btn btn-outline-secondary h-25"
                        onClick={() => this.props.editAddress()}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-bluey-grey">
                  No billing address added yet.
                </div>
              )}
            </div>
            <div
              className="d-flex flex-column mt-3"
              style={{ marginBottom: '0px!important' }}
            >
              <div
                className="mt-3 d-flex p-3 border rounded-3 card"
                style={{
                  backgroundColor: '#f7f7f8',
                }}
              >
                <span>Payment method</span>
              </div>
              <div>
                {!this.props.intent?.paymentMethods ||
                (Array.isArray(this.props.intent.paymentMethods) &&
                  this.props.intent.paymentMethods.length === 0) ? (
                  <div className="text-bluey-grey mt-4 mb-4 d-flex align-items-start justify-content-start">
                    You do not have a payment method.
                  </div>
                ) : (
                  <>
                    <div
                      className="mt-2"
                      style={{
                        overflowY: 'scroll',
                        height: '7rem',
                      }}
                    >
                      {this.props.intent.paymentMethods &&
                        Array.isArray(
                          this.props.intent.paymentMethods
                        ) &&
                        this.props.intent.paymentMethods.length > 0 &&
                        this.props.intent.paymentMethods.map(
                          (card, index) => (
                            <div
                              onClick={() =>
                                this.handleChangeCard(card.id)
                              }
                              key={index}
                              className="card mt-2"
                            >
                              <div className="card-body d-flex">
                                <img
                                  src={payment.getBrandIcon(
                                    card.card_brand
                                  )}
                                  alt={''}
                                  style={{
                                    width: '64px',
                                    marginRight: '1rem',
                                  }}
                                />
                                <div className="d-flex flex-grow-1 flex-column align-items-start">
                                  <span className="font-semibold">
                                    {payment.getBrandName(
                                      card.card_brand
                                    )}{' '}
                                    ending in {card.last_four}
                                  </span>
                                  <div className="text-sm opacity-50">
                                    Expiry {card.expiry_month}/
                                    {card.expiry_year}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                  <input
                                    disabled={this.state.loading}
                                    checked={
                                      card.id ===
                                      this.state.currentCard
                                    }
                                    onChange={() =>
                                      this.handleChangeCard(card.id)
                                    }
                                    type="radio"
                                    value={card.id}
                                    className="text--primary rounded"
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </>
                )}
                {this.props.intent ? (
                  <div className="w-100 mt-4">
                    <PaymentElements
                      stripe={this.props.stripe}
                      options={{
                        clientSecret: this.props.intent.secret,
                      }}
                    >
                      <ElementsConsumer>
                        {({ elements, stripe }) => {
                          this.setStripe(elements, stripe);

                          return (
                            <form>
                              <CardElement
                                options={cardStyle}
                                className="rounded border w-auto p-2"
                              />
                            </form>
                          );
                        }}
                      </ElementsConsumer>
                    </PaymentElements>
                  </div>
                ) : (
                  <div className="p-2">Loading...</div>
                )}
                <img
                  className="w-25 h-25 mt-2"
                  src={poweredByStripe}
                  alt="powered by stripe"
                />
              </div>
            </div>
            <div className="divider"></div>
            <div className="mt-8 d-flex-inline">
              <Label terms={true} urls={this.props.user?.urls.urls}>
                <input
                  disabled={this.state.loading}
                  id="terms"
                  checked={this.state.termsAccepted}
                  onChange={() =>
                    this.setState({
                      termsAccepted: !this.state.termsAccepted,
                    })
                  }
                  type="checkbox"
                  name="terms"
                  className="text--primary rounded me-1"
                  required
                />
              </Label>
            </div>
            <div className="d-flex justify-content-end align-items-end mt-2">
              <Elements.Button
                disabled={this.state.loading}
                onClick={() => this.hide()}
                type="button"
                name="Cancel"
                color="bg-transparent"
                className="border beepy-input-border rounded-3 py-2 px-3 beepy-subtitle-color me-3"
              />
              <Elements.Button
                name="Complete order"
                type="submit"
                loading={this.state.loading}
                disabled={
                  !this.props.user?.billing_address?.address ||
                  !this.state.termsAccepted
                }
                color="bg--beepy-dark"
                className="border beepy-input-border rounded-3 py-2 px-3 text-white"
                onClick={async () => {
                  if (this.isCardBlank() && !this.state.currentCard)
                    return alert.fire({
                      message:
                        'Please select or enter a card to continue.',
                      error: true,
                    });
                  else {
                    this.setState({
                      loading: true,
                    });

                    if (this.isCardBlank() && this.state.currentCard)
                      this.pay(this.state.currentCard);
                    else if (
                      !this.isCardBlank() &&
                      !this.state.currentCard
                    )
                      this.stripePay();
                  }
                }}
              />
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
class Label extends React.Component<{
  relaxed?: boolean;
  htmlFor?: string;
  label?: string;
  terms?: boolean;
  urls?: accountTypes.IUserProfile['urls']['urls'];
}> {
  render() {
    const { label, htmlFor, terms, children, relaxed, urls } =
      this.props;

    if (terms === true)
      return (
        <label
          htmlFor="terms"
          className="d-flex align-items-center space-x-2 font-medium"
        >
          {children}

          <span className="d-flex align-items-center justify-content-center">
            <div>I agree to the</div>
            <a
              href={urls?.terms.url}
              className="text--primary ms-1"
              target="_blank"
              rel="noreferrer"
            >
              {urls?.terms.label}
            </a>
          </span>
        </label>
      );

    return (
      <label
        htmlFor={htmlFor}
        className={`d-flex flex-column space-y-1 ${
          relaxed && 'md:col-span-2'
        }`}
      >
        <span className="text-sm font-medium text-gray-700">
          {label}
        </span>
        {children}
      </label>
    );
  }
}

export default Checkout;
