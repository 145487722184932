import React from 'react';
import { Helmet } from 'react-helmet-async';

import AuthLogo from '../../components/Auth/AuthLogo';

import { ReactComponent as ArrowLeft } from '../../assets/images/icons/arrow-left.svg';
import { Link } from 'react-router-dom';
import { alert, auth, router } from '../../helpers';
import OtpInput from 'react-auth-code-input';
import {
  VerificationProps,
  VerificationStates,
} from '../../@types/pages/Auth';
import { Elements } from '../../components';
import { RootState } from '../../store';
import { authActions } from '../../actions';
import { authTypes } from '../../@types/reducers';
import { connect, ConnectedProps } from 'react-redux';
import AuthBackground from './AuthBackground';
import Modal from '../../components/Modal/Modal';

class Verification extends React.Component<
  VerificationProps & ConnectedProps<typeof connector>,
  VerificationStates
> {
  modal: React.RefObject<Modal>;

  state: VerificationStates = {
    otp: '',
  };

  constructor(
    props: VerificationProps & ConnectedProps<typeof connector>
  ) {
    super(props);
    this.modal = React.createRef();
  }

  componentDidMount() {
    this.modal.current?.show();
  }

  componentDidUpdate(
    prevProps: VerificationProps & ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.verification !== this.props.verification &&
      !this.props.verification.isLoading
    ) {
      alert.fire({
        message: this.props.verification.error
          ? (this.props.verification.data as unknown as any).message
          : 'Verification successfully!',
        error: this.props.verification.error,
      });

      if (!this.props.verification.error)
        this.props.navigate('/channels');
    }

    if (
      prevProps.resentVerification !==
        this.props.resentVerification &&
      !this.props.resentVerification.isLoading
    ) {
      alert.fire({
        message: this.props.resentVerification.error
          ? (this.props.resentVerification.data as unknown as any)
              .message
          : 'Resent verification link successfully!',
        error: this.props.resentVerification.error,
      });
    }
  }

  handleChange = (otp: string) => {
    this.setState({ otp });
  };

  verify = () => {
    const registerData = auth.getRegisterData();
    this.props.verify({ ...registerData, code: this.state.otp });
  };

  render() {
    return (
      <AuthBackground>
        <Helmet>
          <title>Verification | Beepy</title>
        </Helmet>
        <Modal
          closable={false}
          modalBodyClass="w-100"
          ref={this.modal}
        >
          <div className="d-flex flex-column">
            <div className="mt-8 d-flex align-items-center justify-content-center">
              <AuthLogo color="dark" />
            </div>
            <div className="m-auto d-flex flex-column justify-content-center align-items-center text-center">
              <h3 id="singinTitleh3">Email Verification</h3>
              <div className="text-bluey-grey m-2 d-flex flex-column align-items-center justify-content-center font-light">
                <div>
                  Enter the 6-digit verification code we sent to{' '}
                </div>
                <div>
                  {auth.getRegisterData()
                    ? auth.getRegisterData().email
                    : ''}
                </div>
              </div>

              <div className="d-flex flex-column">
                <OtpInput
                  containerClassName="mt-2 d-flex flex-row align-items-center justify-content-center px-2"
                  inputClassName="text-dark form-control m-2 rounded border border-dark border text-center text-2xl outline-none"
                  onChange={this.handleChange}
                  allowedCharacters={'numeric'}
                  length={6}
                />
                <div className="my-3">
                  <Elements.Button
                    onClick={this.verify}
                    loading={this.props.verification.isLoading}
                    disabled={this.state.otp.length !== 6}
                    type="button"
                    name="Verify Email"
                    className="h-auto text-white p-2 w-100"
                  />
                </div>
              </div>

              <div className="text-bluey-grey d-flex flex-column align-items-center justify-content-center p-8 text-sm">
                <div>
                  Didn't receive the email?
                  <span
                    className="text--primary ms-1 cursor-pointer"
                    onClick={() => {
                      this.props.resendVerification();
                    }}
                  >
                    Click to resend.
                  </span>
                </div>
                <div className="mt-5 d-flex align-items-center justify-content-center">
                  <ArrowLeft className="me-1" />
                  <Link
                    style={{
                      textDecoration: 'none',
                    }}
                    to="/login"
                    className="text--primary"
                  >
                    Back to log in
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </AuthBackground>
    );
  }
}

const mapState = (state: RootState) => ({
  verification: state.auth.verificationCode,
  resentVerification: state.auth.verificationResend,
});

const mapDispatch = {
  verify: ({
    name,
    surname,
    email,
    country,
    password,
    timezone,
    code,
  }: authTypes.IVerificationRequest) =>
    authActions.verificationCodeAction({
      name,
      surname,
      email,
      country,
      password,
      timezone,
      code,
    }),
  resendVerification: () =>
    authActions.verificationCodeResendAction(),
};

const connector = connect(mapState, mapDispatch);
export default connector(router.withRouter(Verification));
