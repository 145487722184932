import React from 'react';

import { Modal as ModalType } from '../../@types/components';

import Modal from '../../components/Modal/Modal';

import { ReactComponent as HelpCircle } from '../../assets/images/icons/help-circle.svg';
import { ReactComponent as Check } from '../../assets/images/icons/check-feature.svg';
import { ReactComponent as Close } from '../../assets/images/icons/x.svg';

const getCurrencySymbol = (currency: string) =>
  (0)
    .toLocaleString(navigator.language, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
class ChangePlan extends React.Component<
  ModalType.ChangePlanProps,
  ModalType.ChangePlanStates
> {
  modal: React.RefObject<Modal>;

  constructor(props: ModalType.ChangePlanProps) {
    super(props);
    this.modal = React.createRef();
  }

  state: ModalType.ChangePlanStates = {
    packages: this.props.packages,
  };

  componentDidUpdate(prevProps: ModalType.ChangePlanProps) {
    if (prevProps.packages !== this.state.packages) {
      this.setState({
        packages: this.state.packages,
      });
    }
  }

  show = () => this.modal.current?.show();
  hide = () => this.modal.current?.hide();

  planFeature = (
    title: string,
    plan: Array<string | number | boolean>
  ) => (
    <li className="d-flex row">
      <div className="col-3 d-flex align-items-center">
        <div className="d-grid align-items-center justify-content-center details">
          <span className="d-inline-flex">
            {title}
            <span className="ms-2">
              <HelpCircle />
            </span>
          </span>
        </div>
      </div>
      {plan.map((description, index) => (
        <div className="col-3" key={index}>
          <div className="d-grid align-items-center justify-content-center details">
            {typeof description === 'string' ||
            typeof description === 'number' ? (
              description
            ) : description ? (
              <Check />
            ) : (
              '-'
            )}
          </div>
        </div>
      ))}
    </li>
  );

  render() {
    if (!this.state.packages || this.state.packages.length === 0)
      return null;

    const packages = [
      this.state.packages[1],
      this.state.packages[2],
      this.state.packages[3],
    ];

    return (
      <Modal
        ref={this.modal}
        onClose={this.props.onClose}
        modalBodyClass="w-100 modal-xl"
        title={this.props.title}
        description={this.props.description}
      >
        <div className="d-flex flex-column">
          <div className="ms-auto color--beepy-grey">
            <Close
              className="cursor-pointer"
              onClick={() => this.hide()}
            />
            <p>ESC</p>
          </div>
          <ul className="pricing-list-head">
            <li className="d-flex row">
              <div className="col-3"></div>
              {packages.map((plan, index) => (
                <div className="col-3" key={index}>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <h6>{plan.name}</h6>
                    <h1>
                      {getCurrencySymbol(plan.currency)}
                      {plan.price}
                    </h1>
                    <p>{plan.billing_type}</p>
                    <p>{plan.description}</p>
                    {plan.current === true ? (
                      <button className="w-100 rounded-3 border text--beepy-dark">
                        <div className="my-2">Current Plan</div>
                      </button>
                    ) : this.state.packages.find(
                        (p) => p.code === 'FREE'
                      )?.current ? (
                      <button
                        onClick={() =>
                          this.props.getStarted(plan.code)
                        }
                        className="bg--beepy-dark w-100 rounded-3 border text-white"
                      >
                        <div className="my-2">Get Started</div>
                      </button>
                    ) : (
                      packages.map((_plan, _index) => {
                        return (
                          (packages[index + _index] &&
                            packages[index + _index].current ===
                              true && (
                              <button
                                key={_index}
                                onClick={() => {
                                  this.props.changePlan(
                                    'Downgrade to ' + plan.name,
                                    plan.code
                                  );
                                }}
                                className="bg--beepy-dark w-100 rounded-3 border text-white"
                              >
                                <div className="my-2">Downgrade</div>
                              </button>
                            )) ||
                          (packages[index - _index] &&
                            packages[index - _index].current ===
                              true && (
                              <button
                                key={_index}
                                onClick={() => {
                                  this.props.changePlan(
                                    'Upgrade to ' + plan.name,
                                    plan.code
                                  );
                                }}
                                className="bg--beepy-dark w-100 rounded-3 border text-white"
                              >
                                <div className="my-2">Upgrade</div>
                              </button>
                            ))
                        );
                      })
                    )}{' '}
                  </div>
                </div>
              ))}
            </li>
          </ul>
          <ul className="pricing-list striped-list">
            {this.planFeature('Channel', [
              this.state.packages[1].features.channel_max,
              this.state.packages[2].features.channel_max,
              this.state.packages[3].features.channel_max,
            ])}
            {this.planFeature('Member', [
              this.state.packages[1].features.members_max_per_channel,
              this.state.packages[2].features.members_max_per_channel,
              this.state.packages[3].features.members_max_per_channel,
            ])}
            {this.planFeature('Notifications', [
              this.state.packages[1].features.notification_limit,
              this.state.packages[2].features.notification_limit,
              this.state.packages[3].features.notification_limit,
            ])}
            {this.planFeature('API Services', [
              this.state.packages[1].features.api_services,
              this.state.packages[2].features.api_services,
              this.state.packages[3].features.api_services,
            ])}
            {this.planFeature('API Rate Limit', [
              this.state.packages[1].features.api_rate_limit_request,
              this.state.packages[2].features.api_rate_limit_request,
              this.state.packages[3].features.api_rate_limit_request,
            ])}
            {this.planFeature('Multiple Device', [
              this.state.packages[1].features.multiple_device,
              this.state.packages[2].features.multiple_device,
              this.state.packages[3].features.multiple_device,
            ])}
            {this.planFeature('Webhooks', [
              this.state.packages[1].features.webhooks_limit,
              this.state.packages[2].features.webhooks_limit,
              this.state.packages[3].features.webhooks_limit,
            ])}
          </ul>
        </div>
      </Modal>
    );
  }
}
export default ChangePlan;
