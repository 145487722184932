import React from 'react';
import { Outlet } from 'react-router-dom';

import { PageProps } from '../../@types/components/Panel/Page';

import Navbar from './Navbar';
import { router } from '../../helpers';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../../assets/css/datatables.min.css';
import '../../assets/css/responsive.css';
import '../../assets/css/style.css';
import Sidebar from './Sidebar';

class Page extends React.Component<PageProps> {
  render() {
    return (
      <>
        <Navbar />
        <main className="main container-fluid d-flex flex-nowrap h-100">

          <Sidebar />

          <div className="container-fluid overflow-hidden d-flex justify-content-center">
            <Outlet />
          </div>

          {/*this.props.location.pathname !== '/channels/create' && (
            <footer className={`p-4 ${this.props.className}`}>
              <Footer />
            </footer>
          )*/}
        </main>
      </>
    );
  }
}
export default router.withRouter(Page);
