import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../../store';
import { accountActions } from '../../actions';

import { alert } from '../../helpers';

import { ReactComponent as MoreHorizontal } from '../../assets/images/icons/more-horizontal.svg';
import { ReactComponent as AlertTriangle } from '../../assets/images/icons/alert-triangle.svg';
import { ReactComponent as CheckCircle } from '../../assets/images/icons/check-circle.svg';

import { ReactComponent as UserPlus } from '../../assets/images/icons/user-plus.svg';

import {
  InvitationsProps,
  InvitationsStates,
} from '../../@types/components/Invitations/Invitations';
import { accountTypes } from '../../@types/reducers';
import { Helmet } from 'react-helmet-async';

class Invitations extends React.Component<
  ConnectedProps<typeof connector>,
  {}
> {
  menuRef = React.createRef<HTMLUListElement>();

  state: InvitationsStates = {
    isLoading: false,
    dropdownAction: null,
  };

  componentDidMount() {
    this.props.getInvitations();

    document.addEventListener('click', this.handleOutsideClick);
  }

  componentDidUpdate(
    prevProps: InvitationsProps & ConnectedProps<typeof connector>
  ) {
    if (
      prevProps.inviteAction !== this.props.inviteAction &&
      !this.props.inviteAction.isLoading
    ) {
      alert.fire({
        message: this.props.inviteAction.error
          ? (this.props.inviteAction.data as unknown as any).message
          : 'Invitation ' +
            this.props.inviteAction.request?.action.toLowerCase() +
            ' successfully!',
        error: this.props.inviteAction.error,
      });

      this.props.getInvitations();

      this.setState({
        isLoading: false,
      });
    }
  }

  acceptInvite = ({ guid }: { guid: string }) => {
    if (!this.state.isLoading) {
      this.setState({
        isLoading: true,
      });

      this.props.invitationAction({ guid, action: 'ACCEPTED' });
    }
  };

  rejectInvite = ({ guid }: { guid: string }) => {
    if (!this.state.isLoading) {
      this.setState({
        isLoading: true,
      });

      this.props.invitationAction({ guid, action: 'REJECTED' });
    }
  };

  toggleAction = (action: string) => {
    this.setState({
      dropdownAction: action,
    });
  };

  handleOutsideClick = (e: any) => {
    if (this.state.dropdownAction) {
      if (this.menuRef && !this.menuRef.current?.contains(e.target)) {
        this.setState({
          dropdownAction: null,
        });
      }
    }
  };

  render() {
    const invitations = this.props.invitaitons
      .data as accountTypes.IInvitation[];
    return (
      <div className="container-fluid overflow-hidden d-flex justify-content-center">
        <Helmet>
          <title>Invitations | Beepy</title>
        </Helmet>
        <div className="content w-100">
          <div className="channel-header">
            <div className="row">
              <div className="col-8 col-lg-7 d-flex">
                <div className="channel-title d-grid">
                  Invitations
                </div>
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="channel-body">
            <ul
              ref={this.menuRef}
              className="invitations-list striped-list"
            >
              {!this.props.invitaitons.isLoading &&
              invitations &&
              invitations.length > 0 ? (
                invitations.map((invite, key) => (
                  <li className="d-flex row" key={key}>
                    <div className="col-5  col-md-5 d-flex align-items-center">
                      <img src={invite.logo} alt="" />
                      <div className="d-grid align-items-center justify-content-start details">
                        <span className="title">
                          {invite.channel}
                        </span>
                        <span className="subtitle">
                          @{invite.slug}
                        </span>
                      </div>
                    </div>
                    <div className="col-5 d-none d-sm-block ">
                      <div className="d-grid align-items-center justify-content-start details">
                        <span className="title">
                          You are invited by {invite.invited_by}
                        </span>
                        <span className="subtitle">
                          {invite.invited_at}
                        </span>
                      </div>
                    </div>
                    <div className="col-2 d-flex align-items-center justify-content-end actions">
                      <a
                        className="actions-buttons d-flex align-items-center"
                        href="#1"
                        onClick={() => this.toggleAction(invite.guid)}
                      >
                        <MoreHorizontal />
                      </a>
                      <ul
                        className={`dropdown-menu text-small shadow my-2 ${
                          this.state.dropdownAction === invite.guid &&
                          'show'
                        }`}
                        style={{ width: '40px' }}
                        aria-labelledby="invitations"
                      >
                        <li>
                          <a
                            className="dropdown-item d-flex align-items-center"
                            href="#1"
                            onClick={() =>
                              this.acceptInvite({ guid: invite.guid })
                            }
                          >
                            <CheckCircle className="mt-1 me-2" />
                            Accept
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item d-flex align-items-center"
                            href="#1"
                            onClick={() =>
                              this.rejectInvite({ guid: invite.guid })
                            }
                          >
                            <AlertTriangle className="mt-1 me-2" />
                            Reject
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                ))
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div
                      className="border rounded-circle p-2 mb-3"
                      style={{
                        backgroundColor: '#eaecf0',
                      }}
                    >
                      <div className="border rounded-circle p-3">
                        <UserPlus />
                      </div>
                    </div>

                    <p className="beepy-title-color fw-semibold fs-4">
                      Pending Invitations
                    </p>
                    <p className="color--beepy-dark-grey w-50 text-center">
                      There is no any pending invitations here. To
                      join a channel, you need to be invited by a
                      channel owner.
                    </p>
                  </div>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: RootState) => ({
  invitaitons: state.account.invitationList,
  inviteAction: state.account.invitationAction,
});

const mapDispatch = {
  getInvitations: () => accountActions.invitationListAction(),
  invitationAction: ({
    guid,
    action,
  }: accountTypes.IInviteActionsRequest) =>
    accountActions.inviteAction({ guid, action }),
  getUserProfile: () => accountActions.userProfileAction(),
};

const connector = connect(mapState, mapDispatch);
export default connector(Invitations);
